import { Component, OnInit, Input, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-form-error',
    templateUrl: './form-error.component.html',
    styleUrls: ['./form-error.component.scss']
})
export class FormErrorComponent implements OnInit {

    @Input() control;
    @Input() name;
    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.control = changes.control.currentValue;
    }

}
