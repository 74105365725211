<div class="discuss-card">
    <div class="discuss-card__content">
        <div class="discuss-card__tag">
            <span>{{ getProblemName(discuss.body_part) }}</span>
        </div>
        <div class="discuss-card__title text-truncate" [routerLink]="['/experience-share/forum/info', discuss.id]">{{ discuss.title }}</div>
        <div class="discuss-card__text text-truncate" [routerLink]="['/experience-share/forum/info', discuss.id]">{{ discuss.content }}</div>
        <div class="discuss-card__options">
            <div class="options__item" [routerLink]="['/experience-share/forum/info', discuss.id]"><img src="/assets/images/icon/comment.svg" alt="" /> &nbsp;{{ discuss.reply_count }}</div>
            <div class="options__item" [routerLink]="['/experience-share/forum/info', discuss.id]"><img src="/assets/images/icon/eye.svg" alt="" /> &nbsp;{{ discuss.viewed }}</div>
            <div class="options__item">
                <app-favorite-lite [isFavorite]="discuss.is_favorite" [id]="discuss.id" type="issues" text="收藏"></app-favorite-lite>
            </div>
        </div>
    </div>
    <div class="discuss-card__img" *ngIf="discuss.photos" [routerLink]="['/experience-share/forum/info', discuss.id]">
        <img alt="" [lazyLoad]="discuss.photos | webp:85" style="object-fit:cover" />
    </div>
</div>
