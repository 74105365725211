export const CityList = {

    // 縣市
    'counties': [
        '台北市', '基隆市', '新北市', '宜蘭縣', '桃園市', '新竹市', '新竹縣', '苗栗縣',
        '台中市', '彰化縣', '南投縣', '嘉義市', '嘉義縣', '雲林縣', '台南市', '高雄市',
        '澎湖縣', '金門縣', '屏東縣', '台東縣', '花蓮縣', '連江縣'
    ],


    // 區域
    'districts': [
        // 台北市
        [
            ['中正區', '大同區', '中山區', '松山區', '大安區', '萬華區', '信義區',
                '士林區', '北投區', '內湖區', '南港區', '文山區'],

            ['100', '103', '104', '105', '106', '108', '110', '111', '112', '114', '115', '116']
        ],
        // 基隆市
        [
            ['仁愛區', '信義區', '中正區', '中山區', '安樂區', '暖暖區', '七堵區'],

            ['200', '201', '202', '203', '204', '205', '206']
        ],
        // 新北市
        [
            ['萬里區', '金山區', '板橋區', '汐止區', '深坑區', '石碇區', '瑞芳區', '平溪區',
                '雙溪區', '貢寮區', '新店區', '坪林區', '烏來區', '永和區', '中和區', '土城區',
                '三峽區', '樹林區', '鶯歌區', '三重區', '新莊區', '泰山區', '林口區', '蘆洲區',
                '五股區', '八里區', '淡水區', '三芝區', '石門區'],

            ['207', '208', '220', '221', '222', '223', '224', '226', '227', '228',
                '231', '232', '233', '234', '235', '236', '237', '238', '239', '241',
                '242', '243', '244', '247', '248', '249', '251', '252', '253']
        ],
        // 宜蘭縣
        [
            ['宜蘭市', '頭城鎮', '礁溪鄉', '壯圍鄉', '員山鄉', '羅東鎮', '三星鄉', '大同鄉',
                '五結鄉', '冬山鄉', '蘇澳鎮', '南澳鄉', '釣魚台列嶼'],

            ['260', '261', '262', '263', '264', '265', '266', '267', '268', '269',
                '270', '272', '290']
        ],
        // 桃園市
        [
            ['中壢區', '平鎮區', '龍潭區', '楊梅區', '新屋區', '觀音區', '桃園區', '龜山區',
                '八德區', '大溪區', '復興區', '大園區', '蘆竹區'],

            ['320', '324', '325', '326', '327', '328', '330', '333', '334', '335',
                '336', '337', '338']
        ],
        // 新竹市
        [
            ['東區', '北區', '香山區'],

            ['300', '300', '300']
        ],
        // 新竹縣
        [
            ['竹北市', '湖口鄉', '新豐鄉', '新埔鎮', '關西鎮', '芎林鄉', '寶山鄉',
                '竹東鎮', '五峰鄉', '橫山鄉', '尖石鄉', '北埔鄉', '峨眉鄉'],

            ['302', '303', '304', '305', '306', '307', '308', '310', '311',
                '312', '313', '314', '315']
        ],
        // 苗栗縣
        [
            ['竹南鎮', '頭份市', '三灣鄉', '南庄鄉', '獅潭鄉', '後龍鎮', '通霄鎮', '苑裡鎮',
                '苗栗市', '造橋鄉', '頭屋鄉', '公館鄉', '大湖鄉', '泰安鄉',
                '銅鑼鄉', '三義鄉', '西湖鄉', '卓蘭鎮'],

            ['350', '351', '352', '353', '354', '356', '357', '358', '360', '361',
                '362', '363', '364', '365', '366', '367', '368', '369']
        ],
        // 台中市
        [
            ['中區', '東區', '南區', '西區', '北區', '北屯區', '西屯區', '南屯區', '太平區',
                '大里區', '霧峰區', '烏日區', '豐原區', '后里區', '石岡區', '東勢區', '和平區',
                '新社區', '潭子區', '大雅區', '神岡區', '大肚區', '沙鹿區', '龍井區', '梧棲區',
                '清水區', '大甲區', '外埔區', '大安區'],

            ['400', '401', '402', '403', '404', '406', '407', '408', '411', '412',
                '413', '414', '420', '421', '422', '423', '424', '426', '427', '428',
                '429', '432', '433', '434', '435', '436', '437', '438', '439']
        ],
        // 彰化縣
        [
            ['彰化市', '芬園鄉', '花壇鄉', '秀水鄉', '鹿港鎮', '福興鄉', '線西鄉', '和美鎮',
                '伸港鄉', '員林市', '社頭鄉', '永靖鄉', '埔心鄉', '溪湖鎮', '大村鄉', '埔鹽鄉',
                '田中鎮', '北斗鎮', '田尾鄉', '埤頭鄉', '溪州鄉', '竹塘鄉', '二林鎮', '大城鄉',
                '芳苑鄉', '二水鄉'],

            ['500', '502', '503', '504', '505', '506', '507', '508', '509', '510',
                '511', '512', '513', '514', '515', '516', '520', '521', '522', '523',
                '524', '525', '526', '527', '528', '530']
        ],
        // 南投縣
        [
            ['南投市', '中寮鄉', '草屯鎮', '國姓鄉', '埔里鎮', '仁愛鄉', '名間鄉', '集集鎮',
                '水里鄉', '魚池鄉', '信義鄉', '竹山鎮', '鹿谷鄉'],

            ['540', '541', '542', '544', '545', '546', '551', '552', '553', '555',
                '556', '557', '558']
        ],
        // 嘉義市
        [
            ['東區', '西區'],

            ['600', '600']
        ],
        // 嘉義縣
        [
            ['番路鄉', '梅山鄉', '竹崎鄉', '阿里山', '中埔鄉', '大埔鄉', '水上鄉',
                '鹿草鄉', '太保市', '朴子市', '東石鄉', '六腳鄉', '新港鄉', '民雄鄉', '大林鎮',
                '溪口鄉', '義竹鄉', '布袋鎮'],

            ['602', '603', '604', '605', '606', '607', '608', '611', '612',
                '613', '614', '615', '616', '621', '622', '623', '624', '625']
        ],
        // 雲林縣
        [
            ['斗南鎮', '大埤鄉', '虎尾鎮', '土庫鎮', '褒忠鄉', '東勢鄉', '台西鄉', '崙背鄉',
                '麥寮鄉', '斗六市', '林內鄉', '古坑鄉', '莿桐鄉', '西螺鎮', '二崙鄉', '北港鎮',
                '水林鄉', '口湖鄉', '四湖鄉', '元長鄉'],

            ['630', '631', '632', '633', '634', '635', '636', '637', '638', '640', '643',
                '646', '647', '648', '649', '651', '652', '653', '654', '655']
        ],
        // 台南市
        [
            ['中西區', '東區', '南區', '北區', '安平區', '安南區', '永康區', '歸仁區', '新化區',
                '左鎮區', '玉井區', '楠西區', '南化區', '仁德區', '關廟區', '龍崎區', '官田區',
                '麻豆區', '佳里區', '西港區', '七股區', '將軍區', '學甲區', '北門區', '新營區',
                '後壁區', '白河區', '東山區', '六甲區', '下營區', '柳營區', '鹽水區', '善化區',
                '大內區', '山上區', '新市區', '安定區'],

            ['700', '701', '702', '704', '708', '709', '710', '711', '712', '713', '714',
                '715', '716', '717', '718', '719', '720', '721', '722', '723', '724', '725',
                '726', '727', '730', '731', '732', '733', '734', '735', '736', '737', '741',
                '742', '743', '744', '745']
        ],
        // 高雄市
        [
            ['新興區', '前金區', '苓雅區', '鹽埕區', '鼓山區', '旗津區', '前鎮區', '三民區',
                '楠梓區', '小港區', '左營區', '仁武區', '大社區', '東沙群島', '南沙群島', '岡山區',
                '路竹區', '阿蓮區', '田寮區', '燕巢區', '橋頭區', '梓官區', '彌陀區', '永安區',
                '湖內區', '鳳山區', '大寮區', '林園區', '鳥松區', '大樹區', '旗山區', '美濃區',
                '六龜區', '內門區', '杉林區', '甲仙區', '桃源區', '那瑪夏區', '茂林區', '茄萣區'],

            ['800', '801', '802', '803', '804', '805', '806', '807', '811', '812', '813',
                '814', '815', '817', '819', '820', '821', '822', '823', '824', '825', '826', '827', '828',
                '829', '830', '831', '832', '833', '840', '842', '843', '844', '845', '846',
                '847', '848', '849', '851', '852']
        ],
        // 澎湖縣
        [
            ['馬公市', '西嶼鄉', '望安鄉', '七美鄉', '白沙鄉', '湖西鄉'],

            ['880', '881', '882', '883', '884', '885']
        ],
        // 金門縣
        [
            ['金沙鎮', '金湖鎮', '金寧鄉', '金城鎮', '烈嶼鄉', '烏坵鄉'],

            ['890', '891', '892', '893', '894', '896']
        ],
        // 屏東縣
        [
            ['屏東市', '三地門鄉', '霧台鄉', '瑪家鄉', '九如鄉', '里港鄉', '高樹鄉', '鹽埔鄉',
                '長治鄉', '麟洛鄉', '竹田鄉', '內埔鄉', '萬丹鄉', '潮州鎮', '泰武鄉', '來義鄉',
                '萬巒鄉', '崁頂鄉', '新埤鄉', '南州鄉', '林邊鄉', '東港鎮', '琉球鄉', '佳冬鄉',
                '新園鄉', '枋寮鄉', '枋山鄉', '春日鄉', '獅子鄉', '車城鄉', '牡丹鄉', '恆春鎮',
                '滿州鄉'],

            ['900', '901', '902', '903', '904', '905', '906', '907', '908', '909', '911',
                '912', '913', '920', '921', '922', '923', '924', '925', '926', '927', '928',
                '929', '931', '932', '940', '941', '942', '943', '944', '945', '946', '947']
        ],
        // 台東縣
        [
            ['台東市', '綠島鄉', '蘭嶼鄉', '延平鄉', '卑南鄉', '鹿野鄉', '關山鎮', '海端鄉',
                '池上鄉', '東河鄉', '成功鎮', '長濱鄉', '太麻里', '金峰鄉', '大武鄉', '達仁鄉'],

            ['950', '951', '952', '953', '954', '955', '956', '957', '958', '959', '961',
                '962', '963', '964', '965', '966']
        ],
        // 花蓮縣
        [
            ['花蓮市', '新城鄉', '秀林鄉', '吉安鄉', '壽豐鄉', '鳳林鎮', '光復鄉', '豐濱鄉',
                '瑞穗鄉', '萬榮鄉', '玉里鎮', '卓溪鄉', '富里鄉'],

            ['970', '971', '972', '973', '974', '975', '976', '977', '978', '979', '981',
                '982', '983']
        ],
        // 連江縣
        [
            ['南竿鄉', '北竿鄉', '莒光鄉', '東引鄉'],

            ['209', '210', '211', '212']
        ]
    ]
};




export const CityAndDistrictsList = {

    // 縣市
    counties: [
        { id: 'taipei', name: '台北市' },
        { id: 'keelung', name: '基隆市' },
        { id: 'new_taipei', name: '新北市' },
        { id: 'yilan', name: '宜蘭縣' },
        { id: 'taoyuan', name: '桃園市' },
        { id: 'hsinchu_city', name: '新竹市' },
        { id: 'hsinchu_county', name: '新竹縣' },
        { id: 'miaoli', name: '苗栗縣' },
        { id: 'taichung', name: '台中市' },
        { id: 'changhua', name: '彰化縣' },
        { id: 'nantou', name: '南投縣' },
        { id: 'chiayi_city', name: '嘉義市' },
        { id: 'chiayi_county', name: '嘉義縣' },
        { id: 'yunlin', name: '雲林縣' },
        { id: 'tainan', name: '台南市' },
        { id: 'kaohsiung', name: '高雄市' },
        { id: 'penghu', name: '澎湖縣' },
        { id: 'kinmen', name: '金門縣' },
        { id: 'pingtung', name: '屏東縣' },
        { id: 'taitung', name: '台東縣' },
        { id: 'hualien', name: '花蓮縣' },
        { id: 'lienchiang', name: '連江縣' }
    ],


    // 區域
    districts: {
        // 台北市
        taipei: [
            { id: '100', name: '中正區' },
            { id: '103', name: '大同區' },
            { id: '104', name: '中山區' },
            { id: '105', name: '松山區' },
            { id: '106', name: '大安區' },
            { id: '108', name: '萬華區' },
            { id: '110', name: '信義區' },
            { id: '111', name: '士林區' },
            { id: '112', name: '北投區' },
            { id: '114', name: '內湖區' },
            { id: '115', name: '南港區' },
            { id: '116', name: '文山區' }
        ],
        // 基隆市
        keelung: [
            { id: '200', name: '仁愛區' },
            { id: '201', name: '信義區' },
            { id: '202', name: '中正區' },
            { id: '203', name: '中山區' },
            { id: '204', name: '安樂區' },
            { id: '205', name: '暖暖區' },
            { id: '206', name: '七堵區' }
        ],
        // 新北市
        new_taipei: [
            { id: '207', name: '萬里區' },
            { id: '208', name: '金山區' },
            { id: '220', name: '板橋區' },
            { id: '221', name: '汐止區' },
            { id: '222', name: '深坑區' },
            { id: '223', name: '石碇區' },
            { id: '224', name: '瑞芳區' },
            { id: '226', name: '平溪區' },
            { id: '227', name: '雙溪區' },
            { id: '228', name: '貢寮區' },
            { id: '231', name: '新店區' },
            { id: '232', name: '坪林區' },
            { id: '233', name: '烏來區' },
            { id: '234', name: '永和區' },
            { id: '235', name: '中和區' },
            { id: '236', name: '土城區' },
            { id: '237', name: '三峽區' },
            { id: '238', name: '樹林區' },
            { id: '239', name: '鶯歌區' },
            { id: '241', name: '三重區' },
            { id: '242', name: '新莊區' },
            { id: '243', name: '泰山區' },
            { id: '244', name: '林口區' },
            { id: '247', name: '蘆洲區' },
            { id: '248', name: '五股區' },
            { id: '249', name: '八里區' },
            { id: '251', name: '淡水區' },
            { id: '252', name: '三芝區' },
            { id: '253', name: '石門區' }
        ],
        // 宜蘭縣
        yilan: [
            { id: '260', name: '宜蘭市' },
            { id: '261', name: '頭城鎮' },
            { id: '262', name: '礁溪鄉' },
            { id: '263', name: '壯圍鄉' },
            { id: '264', name: '員山鄉' },
            { id: '265', name: '羅東鎮' },
            { id: '266', name: '三星鄉' },
            { id: '267', name: '大同鄉' },
            { id: '268', name: '五結鄉' },
            { id: '269', name: '冬山鄉' },
            { id: '270', name: '蘇澳鎮' },
            { id: '272', name: '南澳鄉' },
            { id: '290', name: '釣魚台列嶼' }
        ],
        // 桃園市
        taoyuan: [
            { id: '320', name: '中壢區' },
            { id: '324', name: '平鎮區' },
            { id: '325', name: '龍潭區' },
            { id: '326', name: '楊梅區' },
            { id: '327', name: '新屋區' },
            { id: '328', name: '觀音區' },
            { id: '330', name: '桃園區' },
            { id: '333', name: '龜山區' },
            { id: '334', name: '八德區' },
            { id: '335', name: '大溪區' },
            { id: '336', name: '復興區' },
            { id: '337', name: '大園區' },
            { id: '338', name: '蘆竹區' }
        ],
        // 新竹市
        hsinchu_city: [
            { id: '300', name: '東區' },
            { id: '300', name: '北區' },
            { id: '300', name: '香山區' }
        ],
        // 新竹縣
        hsinchu_county: [
            { id: '302', name: '竹北市' },
            { id: '303', name: '湖口鄉' },
            { id: '304', name: '新豐鄉' },
            { id: '305', name: '新埔鎮' },
            { id: '306', name: '關西鎮' },
            { id: '307', name: '芎林鄉' },
            { id: '308', name: '寶山鄉' },
            { id: '310', name: '竹東鎮' },
            { id: '311', name: '五峰鄉' },
            { id: '312', name: '橫山鄉' },
            { id: '313', name: '尖石鄉' },
            { id: '314', name: '北埔鄉' },
            { id: '315', name: '峨眉鄉' }
        ],
        // 苗栗縣
        miaoli: [
            { id: '350', name: '竹南鎮' },
            { id: '351', name: '頭份市' },
            { id: '352', name: '三灣鄉' },
            { id: '353', name: '南庄鄉' },
            { id: '354', name: '獅潭鄉' },
            { id: '356', name: '後龍鎮' },
            { id: '357', name: '通霄鎮' },
            { id: '358', name: '苑裡鎮' },
            { id: '360', name: '苗栗市' },
            { id: '361', name: '造橋鄉' },
            { id: '362', name: '頭屋鄉' },
            { id: '363', name: '公館鄉' },
            { id: '364', name: '大湖鄉' },
            { id: '365', name: '泰安鄉' },
            { id: '366', name: '銅鑼鄉' },
            { id: '367', name: '三義鄉' },
            { id: '368', name: '西湖鄉' },
            { id: '369', name: '卓蘭鎮' }
        ],
        // 台中市
        taichung: [
            { id: '400', name: '中區' },
            { id: '401', name: '東區' },
            { id: '402', name: '南區' },
            { id: '403', name: '西區' },
            { id: '404', name: '北區' },
            { id: '406', name: '北屯區' },
            { id: '407', name: '西屯區' },
            { id: '408', name: '南屯區' },
            { id: '411', name: '太平區' },
            { id: '412', name: '大里區' },
            { id: '413', name: '霧峰區' },
            { id: '414', name: '烏日區' },
            { id: '420', name: '豐原區' },
            { id: '421', name: '后里區' },
            { id: '422', name: '石岡區' },
            { id: '423', name: '東勢區' },
            { id: '424', name: '和平區' },
            { id: '426', name: '新社區' },
            { id: '427', name: '潭子區' },
            { id: '428', name: '大雅區' },
            { id: '429', name: '神岡區' },
            { id: '432', name: '大肚區' },
            { id: '433', name: '沙鹿區' },
            { id: '434', name: '龍井區' },
            { id: '435', name: '梧棲區' },
            { id: '436', name: '清水區' },
            { id: '437', name: '大甲區' },
            { id: '438', name: '外埔區' }
        ],
        // 彰化縣
        changhua: [
            { id: '500', name: '彰化市' },
            { id: '502', name: '芬園鄉' },
            { id: '503', name: '花壇鄉' },
            { id: '504', name: '秀水鄉' },
            { id: '505', name: '鹿港鎮' },
            { id: '506', name: '福興鄉' },
            { id: '507', name: '線西鄉' },
            { id: '508', name: '和美鎮' },
            { id: '509', name: '伸港鄉' },
            { id: '510', name: '員林市' },
            { id: '511', name: '社頭鄉' },
            { id: '512', name: '永靖鄉' },
            { id: '513', name: '埔心鄉' },
            { id: '514', name: '溪湖鎮' },
            { id: '515', name: '大村鄉' },
            { id: '516', name: '埔鹽鄉' },
            { id: '520', name: '田中鎮' },
            { id: '521', name: '北斗鎮' },
            { id: '522', name: '田尾鄉' },
            { id: '523', name: '埤頭鄉' },
            { id: '524', name: '溪州鄉' },
            { id: '525', name: '竹塘鄉' },
            { id: '526', name: '二林鎮' },
            { id: '527', name: '大城鄉' },
            { id: '528', name: '芳苑鄉' },
            { id: '530', name: '二水鄉' }
        ],
        // 南投縣
        nantou: [
            { id: '540', name: '南投市' },
            { id: '541', name: '中寮鄉' },
            { id: '542', name: '草屯鎮' },
            { id: '544', name: '國姓鄉' },
            { id: '545', name: '埔里鎮' },
            { id: '546', name: '仁愛鄉' },
            { id: '551', name: '名間鄉' },
            { id: '552', name: '集集鎮' },
            { id: '553', name: '水里鄉' },
            { id: '555', name: '魚池鄉' },
            { id: '556', name: '信義鄉' },
            { id: '557', name: '竹山鎮' },
            { id: '558', name: '鹿谷鄉' }
        ],
        // 嘉義市
        chiayi_city: [
            { id: '600', name: '東區' },
            { id: '600', name: '西區' }
        ],
        // 嘉義縣
        chiayi_county: [
            { id: '602', name: '番路鄉' },
            { id: '603', name: '梅山鄉' },
            { id: '604', name: '竹崎鄉' },
            { id: '605', name: '阿里山' },
            { id: '606', name: '中埔鄉' },
            { id: '607', name: '大埔鄉' },
            { id: '608', name: '水上鄉' },
            { id: '611', name: '鹿草鄉' },
            { id: '612', name: '太保市' },
            { id: '613', name: '朴子市' },
            { id: '614', name: '東石鄉' },
            { id: '615', name: '六腳鄉' },
            { id: '616', name: '新港鄉' },
            { id: '621', name: '民雄鄉' },
            { id: '622', name: '大林鎮' },
            { id: '623', name: '溪口鄉' },
            { id: '624', name: '義竹鄉' },
            { id: '625', name: '布袋鎮' }
        ],
        // 雲林縣
        yunlin: [
            { id: '630', name: '斗南鎮' },
            { id: '631', name: '大埤鄉' },
            { id: '632', name: '虎尾鎮' },
            { id: '633', name: '土庫鎮' },
            { id: '634', name: '褒忠鄉' },
            { id: '635', name: '東勢鄉' },
            { id: '636', name: '台西鄉' },
            { id: '637', name: '崙背鄉' },
            { id: '638', name: '麥寮鄉' },
            { id: '640', name: '斗六市' },
            { id: '643', name: '林內鄉' },
            { id: '646', name: '古坑鄉' },
            { id: '647', name: '莿桐鄉' },
            { id: '648', name: '西螺鎮' },
            { id: '649', name: '二崙鄉' },
            { id: '651', name: '北港鎮' },
            { id: '652', name: '水林鄉' },
            { id: '653', name: '口湖鄉' },
            { id: '654', name: '四湖鄉' },
            { id: '655', name: '元長鄉' }
        ],
        // 台南市
        tainan: [
            { id: '700', name: '中西區' },
            { id: '701', name: '東區' },
            { id: '702', name: '南區' },
            { id: '704', name: '北區' },
            { id: '708', name: '安平區' },
            { id: '709', name: '安南區' },
            { id: '710', name: '永康區' },
            { id: '711', name: '歸仁區' },
            { id: '712', name: '新化區' },
            { id: '713', name: '左鎮區' },
            { id: '714', name: '玉井區' },
            { id: '715', name: '楠西區' },
            { id: '716', name: '南化區' },
            { id: '717', name: '仁德區' },
            { id: '718', name: '關廟區' },
            { id: '719', name: '龍崎區' },
            { id: '720', name: '官田區' },
            { id: '721', name: '麻豆區' },
            { id: '722', name: '佳里區' },
            { id: '723', name: '西港區' },
            { id: '724', name: '七股區' },
            { id: '725', name: '將軍區' },
            { id: '726', name: '學甲區' },
            { id: '727', name: '北門區' },
            { id: '730', name: '新營區' },
            { id: '731', name: '後壁區' },
            { id: '732', name: '白河區' },
            { id: '733', name: '東山區' },
            { id: '734', name: '六甲區' },
            { id: '735', name: '下營區' },
            { id: '736', name: '柳營區' },
            { id: '737', name: '鹽水區' },
            { id: '741', name: '善化區' },
            { id: '742', name: '大內區' },
            { id: '743', name: '山上區' },
            { id: '744', name: '新市區' },
            { id: '745', name: '安定區' }
        ],
        // 高雄市
        kaohsiung: [
            { id: '800', name: '新興區' },
            { id: '801', name: '前金區' },
            { id: '802', name: '苓雅區' },
            { id: '803', name: '鹽埕區' },
            { id: '804', name: '鼓山區' },
            { id: '805', name: '旗津區' },
            { id: '806', name: '前鎮區' },
            { id: '807', name: '三民區' },
            { id: '811', name: '楠梓區' },
            { id: '812', name: '小港區' },
            { id: '813', name: '左營區' },
            { id: '814', name: '仁武區' },
            { id: '815', name: '大社區' },
            { id: '817', name: '東沙群島' },
            { id: '819', name: '南沙群島' },
            { id: '820', name: '岡山區' },
            { id: '821', name: '路竹區' },
            { id: '822', name: '阿蓮區' },
            { id: '823', name: '田寮區' },
            { id: '824', name: '燕巢區' },
            { id: '825', name: '橋頭區' },
            { id: '826', name: '梓官區' },
            { id: '827', name: '彌陀區' },
            { id: '828', name: '永安區' },
            { id: '829', name: '湖內區' },
            { id: '830', name: '鳳山區' },
            { id: '831', name: '大寮區' },
            { id: '832', name: '林園區' },
            { id: '833', name: '鳥松區' },
            { id: '840', name: '大樹區' },
            { id: '842', name: '旗山區' },
            { id: '843', name: '美濃區' },
            { id: '844', name: '六龜區' },
            { id: '845', name: '內門區' },
            { id: '846', name: '杉林區' },
            { id: '847', name: '甲仙區' },
            { id: '848', name: '桃源區' },
            { id: '849', name: '那瑪夏區' },
            { id: '851', name: '茂林區' },
            { id: '852', name: '茄萣區' }
        ],
        // 澎湖縣
        penghu: [
            { id: '880', name: '馬公市' },
            { id: '881', name: '西嶼鄉' },
            { id: '882', name: '望安鄉' },
            { id: '883', name: '七美鄉' },
            { id: '884', name: '白沙鄉' },
            { id: '885', name: '湖西鄉' }
        ],
        // 金門縣
        kinmen: [
            { id: '890', name: '金沙鎮' },
            { id: '891', name: '金湖鎮' },
            { id: '892', name: '金寧鄉' },
            { id: '893', name: '金城鎮' },
            { id: '894', name: '烈嶼鄉' },
            { id: '896', name: '烏坵鄉' }
        ],
        // 屏東縣
        pingtung: [
            { id: '900', name: '屏東市' },
            { id: '901', name: '三地門鄉' },
            { id: '902', name: '霧台鄉' },
            { id: '903', name: '瑪家鄉' },
            { id: '904', name: '九如鄉' },
            { id: '905', name: '里港鄉' },
            { id: '906', name: '高樹鄉' },
            { id: '907', name: '鹽埔鄉' },
            { id: '908', name: '長治鄉' },
            { id: '909', name: '麟洛鄉' },
            { id: '911', name: '竹田鄉' },
            { id: '912', name: '內埔鄉' },
            { id: '913', name: '萬丹鄉' },
            { id: '920', name: '潮州鎮' },
            { id: '921', name: '泰武鄉' },
            { id: '922', name: '來義鄉' },
            { id: '923', name: '萬巒鄉' },
            { id: '924', name: '崁頂鄉' },
            { id: '925', name: '新埤鄉' },
            { id: '926', name: '南州鄉' },
            { id: '927', name: '林邊鄉' },
            { id: '928', name: '東港鎮' },
            { id: '929', name: '琉球鄉' },
            { id: '931', name: '佳冬鄉' },
            { id: '932', name: '新園鄉' },
            { id: '940', name: '枋寮鄉' },
            { id: '941', name: '枋山鄉' },
            { id: '942', name: '春日鄉' },
            { id: '943', name: '獅子鄉' },
            { id: '944', name: '車城鄉' },
            { id: '945', name: '牡丹鄉' },
            { id: '946', name: '恆春鎮' },
            { id: '947', name: '滿州鄉' }
        ],
        // 台東縣
        taitung: [
            { id: '950', name: '台東市' },
            { id: '951', name: '綠島鄉' },
            { id: '952', name: '蘭嶼鄉' },
            { id: '953', name: '延平鄉' },
            { id: '954', name: '卑南鄉' },
            { id: '955', name: '鹿野鄉' },
            { id: '956', name: '關山鎮' },
            { id: '957', name: '海端鄉' },
            { id: '958', name: '池上鄉' },
            { id: '959', name: '東河鄉' },
            { id: '961', name: '成功鎮' },
            { id: '962', name: '長濱鄉' },
            { id: '963', name: '太麻里' },
            { id: '964', name: '金峰鄉' },
            { id: '965', name: '大武鄉' },
            { id: '966', name: '達仁鄉' }
        ],
        // 花蓮縣
        hualien: [
            { id: '970', name: '花蓮市' },
            { id: '971', name: '新城鄉' },
            { id: '972', name: '秀林鄉' },
            { id: '973', name: '吉安鄉' },
            { id: '974', name: '壽豐鄉' },
            { id: '975', name: '鳳林鎮' },
            { id: '976', name: '光復鄉' },
            { id: '977', name: '豐濱鄉' },
            { id: '978', name: '瑞穗鄉' },
            { id: '979', name: '萬榮鄉' },
            { id: '981', name: '玉里鎮' },
            { id: '982', name: '卓溪鄉' },
            { id: '983', name: '富里鄉' }
        ],
        // 連江縣
        lienchiang: [
            { id: '209', name: '南竿鄉' },
            { id: '210', name: '北竿鄉' },
            { id: '211', name: '莒光鄉' },
            { id: '212', name: '東引鄉' }
        ]
    }
    
};