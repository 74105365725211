import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
} from '@angular/common/http'
import { Observable } from 'rxjs';
import { HttpService } from '../services/http.service';
import { environment } from '../../environments/environment';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class RequestInterceptor implements HttpInterceptor {

    constructor(
        public httpService: HttpService,
        private spinner: NgxSpinnerService,
    ) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        //開始loading動畫
        if (this.httpService.loadingOpen) {
            this.spinner.show();
        }
        this.httpService.requestCount++;

        //從cookie取得token
        //const token = this.cookieService.getCookie('Authorization');
        const token = localStorage.getItem('token');

        //判斷是否為內部系統API
        let reg = RegExp(environment.APIUrlReg);

        //非內部系統API 直接傳送request
        if (!reg.test(req.url)) {
            return next.handle(req);

            //內部系統API request加入token再傳送
        } else {
            const newRequest = req.clone({ setHeaders: { 'Authorization': `Bearer ${token}` } });
            //const newRequest = req.clone({ setHeaders: {'Authorization': `Bearer ${token}`, 'Language': this.translate.currentLang}});
            return next.handle(newRequest);
        }
    }

}
