<div class="container-fluid home-content" id="homeContent">
    <div class="home-content-slot">
<!-- Banner (兩欄) -->
        <app-index-banner [list]="homeDataService.banners" *ngIf="layoutService.showAside && layoutService.show.banner && homeDataService.banners?.length"></app-index-banner>
<!-- 內容區塊 -->
        <ng-content></ng-content>
<!-- 警語 -->
        <div style="margin-top:20px;color:#6B6969;font-size:12px">
            任何手術及療程皆會受到個人因素影響，而導致治療效果有所差異，並可能產生不同程度的副作用，本站所描述之療程相關內容為療程衛教資訊分享。本網站相關療程實際案例皆取得病人同意，治療前後比較影像僅供診療說明、衛教及醫療知識使用之輔助圖片，非醫療廣告；任何醫療過程皆有風險，效果亦因個人而異，治療前需由醫師自看診並告知可能風險（包含但不限於療程禁忌症、可能產生之併發症及後遺症與療程優缺點），以保障病患權益。
        </div>
<!-- 不能錯過 (單欄) -->
        <section class="section !md-p-0 mt-3" *ngIf="isBrowser && !layoutService.showAside && homeDataService.recommend.length">
            <div class="container px-0">
                <div class="heading">
                    <span class="h3 title">
                        不能錯過
                        <app-tip-btn [cloumn]="'tip2'"></app-tip-btn>
                    </span>
                </div>
                <app-banner [list]="homeDataService.recommend" class="sidebar-banner"></app-banner>
            </div>
        </section>
<!-- 駐站醫師 -->
        <div class="section !mobile-p-0 doctors mt-3" *ngIf="isBrowser">
            <div class="container px-0">
                <div class="heading">
                    <h3 class="title">
                        駐站醫師
                        <app-tip-btn [cloumn]="'tip2'"></app-tip-btn>
                    </h3>
                </div>
                <owl-carousel-o [options]="doctorsBannerOptions" class="banner-nav-inside">
                    <ng-template carouselSlide *ngFor="let item of doctors">
                        <app-list-item [item]="item" type="doctor2" class="doctor-list-item"></app-list-item>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </div>
<!-- 好文推薦 (兩欄) -->
        <section class="section !md-p-0 mt-3" *ngIf="layoutService.showAside">
            <div class="container p-0">
                <div class="heading">
                    <h3 class="title">好文推薦</h3>
                </div>
                <owl-carousel-o [options]="articleBannerOptions" class="banner-nav-inside">
                    <ng-template carouselSlide *ngFor="let item of articles">
                        <app-list-item [item]="item" type="article2" class="aritcle-list-item" style="width:300px" [articleType]="item.type"></app-list-item>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>
    </div>
    <div class="home-content-sidebar">
<!-- 網友熱搜療程 (兩欄) -->
        <section class="section !md-p-0 hot-therapy" *ngIf="isBrowser && layoutService.showAside && layoutService.show.hot && data">
            <div class="container">
                <div class="heading">
                    <h3 class="title">網友熱搜療程
                        <app-tip-btn [cloumn]="'tip2'"></app-tip-btn>
                    </h3>
                    <a routerLink="/therapy" class="heading-more">More</a>
                </div>
                <app-label-tip [cloumn]="'tip2'"></app-label-tip>
                <owl-carousel-o [options]="therapyBannerOptions">
                    <ng-template carouselSlide *ngFor="let therapies of (homeDataService.hotTherapies | chunk:4)">
                        <div class="row m-0">
                            <div class="col-6 therapy-wrapper" *ngFor="let item of therapies">
                                <app-list-item [item]="item" type="therapy2"></app-list-item>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>
<!-- 不能錯過 (兩欄) -->
        <section class="section !md-p-0 mt-3" *ngIf="isBrowser && layoutService.showAside && homeDataService.recommend.length">
            <div class="container">
                <div class="heading">
                    <span class="h3 title">
                        不能錯過
                        <app-tip-btn [cloumn]="'tip2'"></app-tip-btn>
                    </span>
                </div>
                <app-banner [list]="homeDataService.recommend" class="sidebar-banner"></app-banner>
            </div>
        </section>
<!-- 熱門討論 -->
        <section class="section !md-p-0 mt-3" *ngIf="isBrowser && data">
            <div class="container !min-then-xl-p-0">
                <div class="heading mb-0">
                    <h3 class="title">
                        熱門討論
                        <app-tip-btn [cloumn]="'tip1'"></app-tip-btn>
                    </h3>
                    <a [routerLink]="['/experience-share/forum']" class="heading-more">More</a>
                </div>
                <app-label-tip [cloumn]="'tip1'"></app-label-tip>
                <div>
                    <ng-container *ngIf="!layoutService.showAside">
                        <app-discuss-card [discuss]="item" *ngFor="let item of data.issue.items.slice(0,5)"></app-discuss-card>
                    </ng-container>
                    <ng-container *ngIf="layoutService.showAside">
                        <app-discuss-card [discuss]="item" *ngFor="let item of data.issue.items"></app-discuss-card>
                    </ng-container>
                </div>
            </div>
        </section>
<!-- 待解決問題 -->
        <section class="section !md-p-0 mt-3" *ngIf="isBrowser && data">
            <div class="container !min-then-xl-p-0">
                <div class="heading mb-0">
                    <h3 class="title">
                        待解決問題
                        <app-tip-btn [cloumn]="'tip1'"></app-tip-btn>
                    </h3>
                    <a [routerLink]="['/experience-share/forum']" class="heading-more">More</a>
                </div>
                <app-label-tip [cloumn]="'tip1'"></app-label-tip>
                <div>
                    <ng-container *ngIf="!layoutService.showAside">
                        <app-discuss-card [discuss]="item" *ngFor="let item of data.todo_issue.items.slice(0,3)"></app-discuss-card>
                    </ng-container>
                    <ng-container *ngIf="layoutService.showAside">
                        <app-discuss-card [discuss]="item" *ngFor="let item of data.todo_issue.items"></app-discuss-card>
                    </ng-container>
                </div>
            </div>
        </section>
<!-- 好文推薦 (單欄) -->
        <section class="section !mobile-p-0 mt-3" *ngIf="!layoutService.showAside">
            <div class="container p-0">
                <div class="heading">
                    <h3 class="title">好文推薦</h3>
                </div>
                <owl-carousel-o [options]="articleBannerOptions" class="banner-nav-inside">
                    <ng-template carouselSlide *ngFor="let item of articles">
                        <app-list-item [item]="item" type="article2" class="aritcle-list-item" style="margin:0 10px" [articleType]="item.type"></app-list-item>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>
    </div>
</div>
