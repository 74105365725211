import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-hashtag-create',
    templateUrl: './hashtag-create.component.html',
    styleUrls: ['./hashtag-create.component.scss']
})
export class HashtagCreateComponent implements OnInit {

    @Input() tags = [];
    @Output() tagChange = new EventEmitter<any>(); //提供Output裝飾器，讓該事件成為父親模板的事件

    tag;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.tags) {
            if (changes.tags.currentValue) {
                this.tags = changes.tags.currentValue;
            }
        }
    }

    enterUp(e) {
        e.preventDefault();
        e.stopPropagation();
        e.stop
        if (e.key == 'Enter') {
            this.addTag()
        }
    }

    //新增tag
    addTag() {
        if (!this.tags) {
            this.tags = [];
        }
        if (this.tag) {
            this.tags.push(this.tag);
            this.tag = '';
            this.tagChange.emit(this.tags);
        }
    }

    //刪除tag
    deleteTag(index) {
        this.tags.splice(index, 1);
        this.tagChange.emit(this.tags);
    }

}
