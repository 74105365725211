<ng-container *ngIf="authService.user_info; else noLogin">
    <span style="display: flex; align-items: center;" (click)="addFavorite($event)">
        <img *ngIf="!isFavorite" src="/assets/images/icon/heart.svg" [style.width]="iconSize" [style.height]="iconSize" alt="">
        <img *ngIf="isFavorite" src="/assets/images/icon/heart_fill.svg" [style.width]="iconSize" [style.height]="iconSize" alt="">
        <span *ngIf="text" style="flex-shrink: 0; padding-left: 4px;">{{text}}</span>
    </span>
</ng-container>
<ng-template #noLogin>
    <span style="display: flex; align-items: center;">
        <img src="/assets/images/icon/heart.svg" [style.width]="iconSize" [style.height]="iconSize" alt="" [swal]="loginSwal">
        <span *ngIf="text" style="flex-shrink: 0; padding-left: 4px;">{{text}}</span>
    </span>
</ng-template>
<swal #loginSwal imageUl='/assets/images/logo.svg' [showConfirmButton]=false [html]="t_webconfig.loginHtmlWithRedirect()"></swal>
