import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-hot-therapy',
    templateUrl: './hot-therapy.component.html',
    styleUrls: ['./hot-therapy.component.scss']
})
export class HotTherapyComponent {

    @Input() item;

}
