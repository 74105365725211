<div *ngIf="isShow" class="footer-bar">
    <div class="bar-content">
        <a routerLink="/">
            <div class="bar-icon bar-icon--logo"><img src="/assets/images/icon/logo_circle.svg" alt=""></div>
        </a>
        <a (click)="searchService.toggleSearchView(!searchService.isSearchFocus)">
            <div class="bar-icon" style="position:relative">
                <img src="/assets/images/icon/icon_search.svg" alt="">
            </div>
        </a>
        <span class="filter-trigger" [nbPopover]="items" nbButton nbPopoverPlacement="top" nbPopoverOffset="30" nbPopoverTrigger="click">
            <div class="bar-icon icon-plus"><img src="/assets/images/icon/plus.svg" alt=""></div>
            <div class="bar-icon icon-plus-mobile"><img src="/assets/images/icon/plus.svg" alt=""></div>
        </span>
        <a routerLink="member/notification" *ngIf="authService.user_info; else noLogin5">
            <div class="bar-icon" style="position:relative">
                <img src="/assets/images/icon/icon_bell.svg" alt="">
                <div class="red-point" style="position: absolute; right: -8px; top: 2px;" *ngIf="authService.user_info && haveUnreadCount$ | async"></div>
            </div>
        </a>
        <ng-template #noLogin5>
            <a [swal]="loginSwal">
                <div class="bar-icon" style="position:relative">
                    <img src="/assets/images/icon/icon_bell.svg" alt="">
                    <div class="red-point" style="position: absolute; right: -8px; top: 2px;" *ngIf="authService.user_info && haveUnreadCount$ | async"></div>
                </div>
            </a>
        </ng-template>
        <a routerLink="/member/info" *ngIf="authService.user_info; else noLogin4">
            <div class="bar-icon"><img alt="" [src]="authService.user_info.avatar | webp:50" /></div>
        </a>
        <ng-template #noLogin4>
            <a [swal]="loginSwal">
                <div class="bar-icon"><img src="/assets/images/icon/user.svg" alt=""></div>
            </a>
        </ng-template>
    </div>
</div>
<ng-template #items>
    <ul class="filter">
        <li class="filter__item" (click)="onPopoverItemClick()">
            <a class="filter__link" routerLink="/x/sign">簽到</a>
        </li>
        <li class="filter__item" (click)="onPopoverItemClick()">
            <ng-container *ngIf="authService.user_info; else noLogin1">
                <a class="filter__link" routerLink="/member/contact-us">預約諮詢</a>
            </ng-container>
            <ng-template #noLogin1>
                <a [swal]="loginSwal" class="filter__link">預約諮詢</a>
            </ng-template>
        </li>
        <li class="filter__item" (click)="onPopoverItemClick()">
            <ng-container *ngIf="authService.user_info; else noLogin2">
                <a class="filter__link" routerLink="/experience-share/member-create">發起療程經驗</a>
            </ng-container>
            <ng-template #noLogin2>
                <a [swal]="loginSwal" class="filter__link">發起療程經驗</a>
            </ng-template>
        </li>
        <li class="filter__item" (click)="onPopoverItemClick()">
            <ng-container *ngIf="authService.user_info; else noLogin3">
                <a class="filter__link" routerLink="/experience-share/forum-create">發起討論文章</a>
            </ng-container>
            <ng-template #noLogin3>
                <a [swal]="loginSwal" class="filter__link">發起討論文章</a>
            </ng-template>
        </li>
        <li class="filter__item" (click)="onPopoverItemClick()">
            <a class="filter__link" href="https://lin.ee/UbddlbZ">加入官方Line@</a>
        </li>
    </ul>
</ng-template>
<swal #loginSwal imageUrl='/assets/images/logo.svg' [showConfirmButton]=false [html]="t_webconfig.loginHtmlWithRedirect()"></swal>
