import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class LayoutService {

    sm;
    md;
    lg;
    xl;
    isBrowser;

    show;

    /** 小于576px */
    smallThanSm
    /** 小于768px */
    smallThanMd
    /** 小于1024px */
    smallThanLg
    /** 小于1400px */
    smallThanXl

    showAside = false;

    sidemenu_open = [false, false, false]; //側拉選單
    userinfo_open = false; //會員資訊

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        const initSize = () => {
            const width = document.body.clientWidth
            this.smallThanSm = width < 576
            this.smallThanMd = width < 768
            this.smallThanLg = width < 1024
            this.smallThanXl = width < 1400

            this.showAside = !this.smallThanXl

            this.sm = false;
            this.md = false;
            this.lg = false;
            this.xl = false;

            if (width > 1400) {
                this.xl = true;
            } else if (width > 1024) {
                this.lg = true;
            } else if (width > 768) {
                this.md = true;
            } else {
                this.sm = true;
            }
        }

        if (isPlatformBrowser(this.platformId)) {
            this.isBrowser = true;

            const observer = new ResizeObserver(initSize)
            observer.observe(document.body)
        }

        initSize()
    }

    init(route) {
        this.show = route.data?.show || {};
    }

    scrollAnchorPosition() {
        this.delegate(document, "click", "a", function (event) {
            // 連結中有#時, 滑動至id錨點位置
            if (this.hasAttribute('href') && this.getAttribute('href').indexOf('#') > 0) {
                event.preventDefault();
                let id = this.getAttribute('href').split("#")[1];
                let delay = 0;
                // 下滑展開qa摺疊選單時延遲100ms
                if (id.indexOf('qa') == 0) {
                    delay = 100;
                }
                setTimeout(() => {
                    window.scrollTo({
                        top: document.getElementById(id).offsetTop - 82,
                        left: 0,
                        // behavior: 'smooth'
                    });
                }, delay)
            }
        });
    }

    // 幫js產生的元素綁定事件
    delegate(el, evt, sel, handler) {
        el.addEventListener(evt, function (event) {
            var t = event.target;
            while (t && t !== this) {
                if (t.matches(sel)) {
                    handler.call(t, event);
                }
                t = t.parentNode;
            }
        });
    }

    // 當點擊body區域關閉搜尋bar,會員資訊
    onClickBody() {
        if (isPlatformBrowser(this.platformId)) {
            const navbar = document.getElementById('navbar');

            document.body.addEventListener('click', (event) => {
                // 點擊以下元素不會關閉選單
                const searchToggle = document.querySelector('.search-toggle img');
                const userInfoToggle = document.querySelector('.userinfo-toggle img');
                const searchInput = document.getElementById('search-input');
                const searchSelect = document.querySelector('.form-select');
                const logout = document.getElementById('logout');

                let target = event.target;

                if (target != searchToggle && target != userInfoToggle && target != searchInput && target != searchSelect && target != logout) {
                    this.userinfo_open = false;
                }

                navbar?.classList.remove("show");

            }, true);
        }
    }

    // 關閉主選單
    closeNavbar() {
        if (isPlatformBrowser(this.platformId)) {
            const navbar = document.getElementById('navbar');

            [document.querySelector('.navbar-brand'), document.querySelector('.navbar-func')].forEach(item => {
                item.addEventListener('click', event => {
                    navbar?.classList.remove("show");

                    this.closeSidemenu();
                })
            })
        }
    }

    // 開關側選單
    toggleSidemenu(index) {
        if (this.sidemenu_open[index] == true) {
            this.sidemenu_open[index] = false;
        } else {
            this.closeSidemenu();
            this.sidemenu_open[index] = true;
        }
    }

    closeSidemenu() {
        this.sidemenu_open = [false, false, false];
    }

    toggleUserInfo() {
        this.closeSidemenu();
        this.userinfo_open = !this.userinfo_open;
    }

    toggleSearch() {
        this.closeSidemenu();
        this.userinfo_open = false;
    }

}
