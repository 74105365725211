<div class="form-group mb-3 mb-md-4">
    <label class="form-label">#Hashtag
        <div class="label-tip">標籤文字前方不需加上＃，如需加入多則標籤，於標籤文字輸入後按下「＋新增」</div>
    </label>
    <div class="row gx-0">
        <div class="col">
            <input type="text" nbInput fullWidth [(ngModel)]="tag" (keyup.enter)="enterUp($event)">
        </div>
        <div class="col-auto">
            <button type="button" (click)="addTag()" class="btn"><nb-icon icon="plus" class="me-1"></nb-icon>新增</button>
        </div>
    </div>
    <div class="mt-3">
        <div class="row g-2 g-md-3">
            <div class="col-auto" *ngFor="let tag of tags; let index=index">
                <div class="btn btn-light d-flex" (click)="deleteTag(index)">
                    #{{ tag }}<nb-icon icon="close" class="ms-1" style="top: 2px;"></nb-icon>
                </div>
            </div>
        </div>
    </div>
</div>
