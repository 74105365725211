import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NbPopoverDirective } from '@nebular/theme';
import { Problem } from 'src/app/models/problem.model';
import { Specialty } from 'src/app/models/specialty.model';
import { Therapy } from 'src/app/models/therapy.model';
import { HttpService } from 'src/app/services/http.service';
import { LayoutService } from 'src/app/services/layout.service';
import { environment } from 'src/environments/environment';

const All_ORGAN = 0

@Component({
    selector: 'app-filter-menu-doctor',
    templateUrl: './filter-menu-doctor.component.html',
    styleUrls: ['./filter-menu-doctor.component.scss']
})
export class FilterMenuDoctorComponent implements OnInit {

    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
    @Output() change = new EventEmitter();

    constructor(
        private httpService: HttpService,
        private router: Router,
        private route: ActivatedRoute,
        private layoutService: LayoutService,
    ) { }

    allSpecialities = Specialty.filter(({ id }) => [11, 23].includes(id))

    curSelectedSpeciality = null

    selectSpeciality(id) {
        this.curSelectedSpeciality = id
    }

    allTherapipyCates = Therapy

    allGenders = [
        { id: 'M', name: '男' },
        { id: 'F', name: '女' },
        { id: 'O', name: '其他' }
    ]

    curSelectedGender = null

    selectGender(id) {
        this.curSelectedGender = id
    }

    isMobile = false

    getIsMobile() {
        if (window.screen?.width < 992) return true
        return false
    }

    ngOnInit(): void {
        this.getCategories()

        this.route.queryParams.subscribe(params => {
            params.division && this.selectSpeciality(params.division)
            params.gender && this.selectGender(params.gender)
            params.category_id && this.selectTherapy(params.category_id)
        })
        this.isMobile = this.getIsMobile()
    }

    tabLabel = this.getLabel()

    handleChangeTab(tab) {
    }

    curSelctedTherapy = null

    selectTherapy(id) {
        this.curSelctedTherapy = id
    }

    allOrgans = [{ id: All_ORGAN, name: "全選", image: "", noImage: true }, ...Problem]

    curSelectedOrgan = null

    organProblems = []

    curSelectedProblem = null

    selectOrgan(id) {
        this.curSelectedOrgan = id
        this.getProblems(id)
    }

    getCategories() {
        let url = `${environment.APIUrl}/api/content/categories`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.allTherapipyCates = res.items;
            }
        )
    }

    //問題清單
    getProblems(organId) {
        if (organId == All_ORGAN) {
            this.organProblems = []
            return
        }

        let url = `${environment.APIUrl}/api/content/problems?per_page=999&body_part=${organId}`;
        this.httpService.getHttp(url).subscribe(res => this.organProblems = res.problem.items)
    }

    selectOrganProblem(id) {
        this.curSelectedProblem = id
    }

    clearFilter() {
        this.curSelectedSpeciality = null
        this.curSelectedGender = null
        this.curSelctedTherapy = null
        this.curSelectedOrgan = null
        this.curSelectedProblem = null
    }

    submit() {
        this.router.navigate(['/content/doctor'], {
            queryParams: {
                division: this.curSelectedSpeciality === 'all' ? '' : this.curSelectedSpeciality,
                gender: this.curSelectedGender === 'all' ? '' : this.curSelectedGender,
                category_id: this.curSelctedTherapy,
                problem_id: this.curSelectedProblem,
                submit: true
            }
        })
        this.popover.hide()
    }

    getLabel() {
        return '醫師擅長解決問題'
    }

    handleTriggerClick() {
        setTimeout(() => {
            if (this.popover.isShown && this.layoutService.smallThanMd) {
                document.querySelector('.cdk-overlay-pane')?.classList.add('filter-menu-mobile')
            }
        });
    }

}
