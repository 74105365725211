<app-home-content>
    <div>
        <div style="margin:10px 0">
            <div class="flex justify-between">
                <div>
                    <app-filter-menu-experience (change)="onTherapyFilterChange($event)"></app-filter-menu-experience>
                    <app-filter-menu [menuModel]="filterRoleMenu" [curActiveId]="filterRole" class="ms-3" [showSortIconWhenNoId]="false" (change)="onFilterMenuChange($event)"></app-filter-menu>
                </div>
                <app-filter-menu [menuModel]="sortFieldMenu" [curActiveId]="sortField" (change)="onSortFieldChange($event)"></app-filter-menu>
            </div>
        </div>
        <app-filter-menu-tab [menuModel]="tabFilters" [curActiveId]="selectedTabFilter" (change)="onTabChange($event)"></app-filter-menu-tab>
        <div class="flex mt-3" style="gap:10px">
            <div class="flex-1" *ngFor="let itmes of reviewsColumn">
                <app-case-list-item *ngFor="let item of itmes" [data]="item" class="block mb-3"></app-case-list-item>
            </div>
        </div>
        <div *ngIf="hasMoreData">
            <div class="text-primary font-bold text-center" style="margin-top:20px;cursor:pointer" (click)="showMore()">查看更多<img src="/assets/images/icon/arrow_down.svg" /></div>
        </div>
    </div>
    <div [routerLink]="['/experience-share/member-create']" class="floating-box2">
        <div class="content flex align-items-center justify-content-between">
            <div class="d-grid">
                <label class="text-white">發起療</label>
                <label class="text-white">程經驗</label>
            </div>
        </div>
    </div>
</app-home-content>
