export const Common: any = {
	create: '新增',
	edit: '修改',
	confirm_status: {
		0:"已送審",
		1:"已上架",
		2:"不通過",
		3:"未送審"
	},
	show_status: {
		1:"顯示",
		0:"隱藏"
	},
	age: {
		1:"18歲以下",
		2:"18-25歲",
		3:"26-35歲",
		4:"36-45歲",
		5:"46-55歲",
		6:"56歲以上"
	},
	worth: {
		"1":"值得",
		"-1":"不值得",
		"0":"不確定",
	},
	epaper_subscription: {
		0: "未訂閱",
		1: "已訂閱"
	},
	role: {
		doctor: "醫師",
		member: "民眾",
		writer: "小編"
	},
	gender: {
		M: "男",
		F: "女",
		O: "不知道"
	}
};