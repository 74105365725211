import { CityList } from '../models/citylist.model';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-address',
    templateUrl: './address.component.html',
    styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit {

    @Input() address_object; //預設地址 格式與output相同
    @Input() readonly;

    cities = CityList;
    city_districts = this.cities.districts[0][0];

    address_city = 0;
    address_district = this.cities.districts[0][0][0];
    address_follow;

    @Output() childEvent = new EventEmitter<any>(); //提供Output裝飾器，讓該事件成為父親模板的事件

    constructor() { }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.address_object) {
            this.address_object = changes.address_object.currentValue;
            if (this.address_object) {
                if (this.address_object.city_code) {
                    this.address_city = this.address_object.city_code;
                } else if (this.address_object.city) {
                    this.address_city = this.cities.counties.indexOf(this.address_object.city);
                }
                this.districtChange(this.address_city);

                this.address_district = this.address_object.district;
                this.address_follow = this.address_object.address;
            }
        }
    }

    //選城市時 改變區
    districtChange(index) {
        this.city_districts = this.cities.districts[index][0];
        this.address_district = this.cities.districts[index][0][0];
    }

    outputAddress() {
        let city = CityList.counties[this.address_city];
        let address = {
            city_code: this.address_city,
            city: city,
            district: this.address_district,
            address: this.address_follow,
        }

        this.childEvent.emit(address);
    }

}
