import { HostListener, Component, OnInit, Input, Output, EventEmitter, SimpleChanges, ElementRef } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: 'app-loadmore',
    templateUrl: './loadmore.component.html',
    styleUrls: ['./loadmore.component.scss']
})
export class LoadmoreComponent implements OnInit {

    used;

    @Input() next;
    @Input() close;
    @Input() showMoreText;
    @Output() childEvent = new EventEmitter<any>(); //提供Output裝飾器，讓該事件成為父親模板的事件

    constructor(
        protected spinnerService: NgxSpinnerService,
        private el: ElementRef
    ) { }

    ngOnInit() {
        /*
        if(this.next)
        {
            this.myscroll(this);
        }
        */
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.next) {
            this.next = changes.next.currentValue;
            this.used = false;
        }
        if (changes.close)
            this.close = changes.close.currentValue;
    }

    more() {
        this.spinnerService.show('loadmore');
        this.childEvent.emit("load more");
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event: any) {
        if (this.next && !this.used) {
            if (window.innerHeight + window.pageYOffset > this.el.nativeElement.offsetTop) {
                this.used = true;
                this.more();
            }
        }
    }

    /*
    myscroll(ot){
        if (isPlatformBrowser(this.platformId)) {
             window.scroll(function(){
                 //判斷整體網頁的高度
                var $BodyHeight = $(document).height();
                 //判斷所見範圍的高度
                var $ViewportHeight=$(window).height();
                 //偵測目前捲軸頂點
                var $ScrollTop=$(this).scrollTop();

                if(Math.abs($BodyHeight-($ViewportHeight+$ScrollTop))<1 ){
                    if(!ot.used){
                           ot.more();
                           ot.used = true;
                    }
                 }
             })
        }
    }
    */

}
