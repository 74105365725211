import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router'; //接收route para
import { FileService } from '../../services/file.service';
import { HomeDataService } from 'src/app/services/home-data.service';
import { LayoutService } from 'src/app/services/layout.service';
import { MetaService } from '../../services/meta.service';

type ListType = 'hot' | 'therapy' | 'body_part' | 'problem_id'

@Component({
    selector: 'app-experience-home-new',
    templateUrl: './experience-home-new.component.html',
    styleUrls: ['./experience-home-new.component.scss']
})
export class ExperienceHomeNewComponent implements OnInit {

    filterRoleMenu = [
        { id: '', name: '全部都看' },
        { id: 'member', name: '只看民眾' },
        { id: 'doctor', name: '只看醫師' },
    ]

    filterRole = this.filterRoleMenu[0].id;

    onFilterMenuChange(id) {
        this.filterRole = id
        this.onParamsChange()
    }

    after_loading

    //問題部位
    categoryId = 1;

    //真實評價
    review_data = [];

    review_per_page = 16;
    review_cur_page = 1

    problemId;
    bodyPartId;

    tabFilters = []
    selectedTabFilter = 0

    sortFieldMenu = [
        // { name: "按發文時間", id: 'content_refresh_at' },
        { name: "按發文時間", id: 'created_at' },
        { name: "按照觀看數", id: 'viewed' },
    ]

    sortField = '';

    onSortFieldChange(value) {
        this.sortField = value
        this.onParamsChange()
    }

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        private route: ActivatedRoute,
        private httpService: HttpService,
        public fileService: FileService,
        private homeDataService: HomeDataService,
        private router: Router,
        private layoutService: LayoutService,
        protected metaService: MetaService,
    ) { }

    home = false;
    pad = false;

    ngOnInit(): void {
        this.home = this.route.snapshot.data?.home;
        this.pad = this.layoutService.smallThanXl && !this.layoutService.smallThanMd;

        this.getBanner();
        this.route.queryParams.subscribe(
            query => {
                const { curTab, sortField, categoryId, problemId, bodyPart, filterRole } = query
                this.tabIndex = curTab || 0
                this.sortField = sortField || ''
                this.categoryId = categoryId || 'hot'
                this.problemId = problemId || 'all'
                this.bodyPartId = bodyPart || ''
                this.filterRole = filterRole || this.filterRoleMenu[0].id
                this.loadData()

                if (this.tabIndex == 0 || this.tabIndex == 1) this.selectedTabFilter = this.categoryId
                else if (this.tabIndex == 2) this.selectedTabFilter = this.problemId

                this.metaService.setDefault();
            }
        )
    }

    onParamsChange() {
        const queryParams = {
            curTab: this.tabIndex,
            sortField: this.sortField,
            categoryId: this.categoryId,
            problemId: this.problemId,
            bodyPartId: this.bodyPartId,
            filterRole: this.filterRole,
        }
        this.router.navigate(['/experience-share/home'], { queryParams })
    }

    //取得首頁資訊
    getBanner() {
        this.httpService.getHttp(`${environment.APIUrl}/api/content/banners?type=${this.home ? 'home' : 'surgery-photo'}`).subscribe(
            res => {
                this.homeDataService.banners = this.home && this.pad ? [] : res.banners;
                this.homeDataService.recommend = res.leaflets;
                this.after_loading = true;
            }
        )
    }

    curShowedList = []

    reviewsColumn = []

    // review fall
    getReviewsColumn() {
        let colCount = 4
        if (this.layoutService.smallThanXl) colCount = 5
        if (this.layoutService.smallThanLg) colCount = 4
        if (this.layoutService.smallThanSm) colCount = 2

        const getReviewsListCol = (list, colNum) => list.filter((item, index) => index % colCount == colNum)
        this.reviewsColumn = Array.from({ length: colCount }).map((_, index) => getReviewsListCol(this.curShowedList, index))
    }

    hasMoreData = true

    lastListType: ListType = 'hot'

    //網友真實評價分頁資訊
    getReview(type: ListType, nextPage = false) {
        this.lastListType = type

        if (nextPage) this.review_cur_page++
        else this.review_cur_page = 1

        const params = {
            per_page: this.review_per_page,
            page: this.review_cur_page,
            if_page: 1,
            sort_field: this.sortField || 'random',
            role: this.filterRole,
            category_id: undefined,
            body_part: undefined,
            problem_id: undefined,
            if_leaflets: 1,
            exclude_ids: this.review_cur_page == 1 ? undefined : this.review_data.map(item => item.id).join(',')
        }
        if (params.exclude_ids?.length) params.page = 1

        if (type == 'hot') {
            params.sort_field = this.sortField || 'random'
        } else if (type == 'therapy') {
            params.category_id = this.categoryId
        } else if (type == 'body_part') {
            params.body_part = this.bodyPartId
        } else if (type == 'problem_id') {
            params.problem_id = this.problemId
        }

        let url = `${environment.APIUrl}/api/content/reviews?${Object.entries(params)
            .filter(([key, val]) => val !== undefined)
            .map(([key, val]) => `${key}=${val}`).join('&')}&`

        this.httpService.getHttp(url).subscribe(
            res => {
                if (!nextPage) this.review_data = res.review.items;
                else this.review_data = [...this.review_data, ...res.review.items]
                this.curShowedList = this.review_data
                this.getReviewsColumn()
                this.hasMoreData = res.review.pagination.if_has_more_page
            }
        )
    }

    showMore() {
        this.getReview(this.lastListType, true)
    }

    tabIndex = 0

    loadData() {
        if (this.tabIndex == 0 && this.categoryId as unknown == 'hot') this.getReview('hot')
        else if (this.tabIndex == 1 || this.tabIndex == 0) this.getReview('therapy')
        else if (this.tabIndex == 2) {
            if (this.problemId == 'all') this.getReview('body_part')
            else this.getReview('problem_id')
        }
    }

    onTabChange(value) {
        this.selectedTabFilter = value
        if (this.tabIndex == 1 || this.tabIndex == 0) this.categoryId = value
        else if (this.tabIndex == 2) this.problemId = value
        this.onParamsChange()
    }

    onTherapyFilterChange({ lastMenus }) {
        this.tabFilters = lastMenus
    }

}
