<div class="form-group mb-1">
    <label class="form-label form-label-first">
        部位
    </label>
    <nb-select fullWidth [(ngModel)]="category_item" (ngModelChange)="openSubMenu($event)" placeholder="重新選擇療程">
        <nb-option [value]=null disabled>請選擇</nb-option>
        <nb-option *ngFor="let category of categories" [value]="category">{{ category.name }}</nb-option>
    </nb-select>
</div>
<div class="form-group mt-2 mb-1" *ngIf="second_categories">
    <label class="form-label">
        療程主分類
    </label>
    <nb-select fullWidth [(ngModel)]="second_category_item" (ngModelChange)="getTherapy($event)">
        <nb-option [value]=null disabled>請選擇</nb-option>
        <nb-option *ngFor="let category of second_categories" [value]="category">{{ category.name }}</nb-option>
    </nb-select>
</div>
<div class="form-group mt-2 mb-1" *ngIf="last_categories">
    <label class="form-label">
        療程次分類
    </label>
    <nb-select fullWidth [(ngModel)]="last_category_item" (ngModelChange)="getTherapy($event)">
        <nb-option [value]=null disabled>請選擇</nb-option>
        <nb-option *ngFor="let category of last_categories" [value]="category">{{ category.name }}</nb-option>
    </nb-select>
</div>
<div class="form-group mt-2 mb-1" *ngIf="therapies">
    <label class="form-label">
        請選擇療程名稱
    </label>
    <nb-select fullWidth placeholder="請選擇" [(ngModel)]="therapy_id" (ngModelChange)="outputData(therapy_id)">
        <nb-option [value]=null disabled>請選擇</nb-option>
        <nb-option *ngFor="let therpy of therapies" [value]="therpy">{{ therpy.name }} {{ therpy.en_name }}</nb-option>
    </nb-select>
</div>
