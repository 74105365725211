export const environment = {
    production: false,
    debugger: true,
    default_shop_id: '1',
    default_shop_name: 'Labspace Shop',
    APIUrl: "https://admin.worthit.com.tw",
    APIUrlReg: "^https?:\/\/.*worthit.com.tw",
    FileUrl: "https://admin.worthit.com.tw/storage",
    ImageUrl: "https://admin.worthit.com.tw/_/img",
    paymentUrl: "https://shop-module.labspace.com.tw/api/lab-shop/front/go-pay",
    assetsUrl: "https://worthit.muds.me",
    FBappId: '574404340564554',
    GoogleAPIKey: '425291134638-qveuck5ga9lhuidroomml3qjns67ankm.apps.googleusercontent.com', //for google login GoogleClientID
    GoogleSearchAPIKey: 'AIzaSyAUcRzC81DDAi0rFKhVMbDHzH-kqrZxH5E',
    GoogleSearchEngineID: '02016e5a354f11b06',
    //WebSocketUrl: "http://localhost/websocket/public",
    WebSocketHost: "210.65.139.164:6001",
    webTitle: '',
    GA: {
        trackingID: 'UA-123182296-24',
        page_view: false,
        addImpression: false,
        productClick: false,
        productDetail: false,
        addCart: false,
        removeCart: false,
        cartSteps: false,
        cartFinish: false
    },
    //加入,修改,刪除購物車時 讀取購物車是否重新計算折扣
    cartDiscountReload: {
        write: 1,
        read: 0
    },
    lazyLoad_defaultImg: '/assets/lazyload.png',
    recaptchSiteKey: '6LfeoHgUAAAAAHAf7STNiJyGliWQStxlOZTgq-YL',
    smsTimeout: 60
};
