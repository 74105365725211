export const Breadcrumb: any = {
	home: '醫美圈圈',
	therapyCategory: '療程分類',
	problem: '我想改善的問題',
	experienceShare: '經驗分享與討論',
	beforeAfter: '術前術後照片',
	memberShare: '療程經驗分享',
	news: '圈圈新聞',
	doctors: '圈圈醫生',
	forum: '討論區'
};