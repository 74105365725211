export const Shareconfig: any = {
    shareHtml: `分享 - 醫美圈圈<br>
    我們的使命是透過廣大網友的真實療程經驗分享，協助消費者少走冤枉路並選擇正確的療程<br>
    <div class="text-center mt-3">
        <input nbInput type="text" id="search-input" fieldSize="medium"
        [fullWidth]="true" class="form-control mb-1"
        [value]="'window.location.pathname'" disabled>
        </input>
        <a href="javascript:;" class="btn btn-primary mb-1 w-100 position-relative d-flex align-items-center justify-content-center">
            <img class="position-absolute start-0" src="/assets/images/icon/login_facebook.svg">
            <span>Facebook 分享</span>
        </a>
        <a href="https://social-plugins.line.me/lineit/share?url=https://www.worthit.com.tw/experience-share/home" class="btn btn-primary mb-1 w-100 position-relative d-flex align-items-center justify-content-center">
            <img class="position-absolute start-0" src="/assets/images/icon/login_facebook.svg">
            <span>Line 分享</span>
        </a>
    </div>`,
    shareHtmlWithRedirect() {
        let redirect = window.location.href;

        return `分享 - 醫美圈圈<br>
        我們的使命是透過廣大網友的真實療程經驗分享，協助消費者少走冤枉路並選擇正確的療程<br>
        <div class="text-center mt-3">
            <input nbInput type="text" id="search-input" fieldSize="medium"
            [fullWidth]="true" class="form-control mb-1"
            value="${redirect}" disabled>
            </input>
            <a href="https://www.facebook.com/sharer.php?u=${redirect}" class="btn btn-primary mb-1 w-100 position-relative d-flex align-items-center justify-content-center">
                <span>Facebook 分享</span>
            </a>
            <a href="https://social-plugins.line.me/lineit/share?url=${redirect}" class="btn btn-primary mb-1 w-100 position-relative d-flex align-items-center justify-content-center">
                <span>Line 分享</span>
            </a>
        </div>`
    },
};
