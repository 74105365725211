import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginGuard } from './guards/login.guard';

import { HomeComponent } from './layout/home/home.component';
import { ExperienceHomeNewComponent } from './experience-share/experience-home-new/experience-home-new.component';
import { SearchHomeMobileComponent } from './search/search-home-mobile/search-home-mobile.component';

const routes: Routes = [

    //驗證登入註冊頁面
    {
        path: 'auth',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },

    //系統首頁
    {
        path: '',
        component: HomeComponent,
        children: [

            //首頁
            {
                path: '',
                component: ExperienceHomeNewComponent,
                data: { home: true, show: {banner: true, hot: true} }
            },

            //療程
            {
                path: 'therapy',
                loadChildren: () => import('./therapy/therapy.module').then(m => m.TherapyModule)
            },

            //我想改善的問題
            {
                path: 'problem',
                loadChildren: () => import('./problem/problem.module').then(m => m.ProblemModule)
            },

            //經驗分享-療程經驗分享&術前術後
            {
                path: 'experience-share',
                loadChildren: () => import('./experience-share/experience-share.module').then(m => m.ExperienceShareModule)
            },

            //會員中心
            {
                path: 'member',
                canActivate: [LoginGuard],
                //canActivateChild: [LoginGuard],
                loadChildren: () => import('./member/member.module').then(m => m.MemberModule)
            },

            //圈圈新聞與醫生
            {
                path: 'content',
                loadChildren: () => import('./content/content.module').then(m => m.ContentModule)
            },

            //站內搜尋
            {
                path: 'search',
                loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
                data: { haha: 'hehe' }
            },

            //空白頁面
            {
                path: 'x',
                loadChildren: () => import('./white-page/white-page.module').then(m => m.WhitePageModule)
            },

            //錯誤頁面
            {
                path: 'error',
                loadChildren: () => import('./error/error.module').then(m => m.ErrorModule)

            },

        ]
    },

    //站內搜尋
    {
        path: 'search-mobile',
        component: SearchHomeMobileComponent,
    },

    //快速登入註冊頁面（豆寶）
    {
        path: 'quick',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    },

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        initialNavigation: 'enabled',
        onSameUrlNavigation: 'reload',
        relativeLinkResolution: 'legacy'
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
