<div class="form-row">
    <div class="col-6">
        <select class="custom-select" [(ngModel)]="address_city" (change)="districtChange($event.target.value)" (change)="outputAddress()" [disabled]="readonly">
            <option *ngFor="let city of this.cities.counties; let i = index" value="{{ i }}">{{ city }}</option>
        </select>
    </div>
    <div class="col-6">
        <select class="custom-select" [(ngModel)]="address_district" (change)="outputAddress()" [disabled]="readonly">
            <option *ngFor="let district of this.city_districts" [value]="district">{{ district }}</option>
        </select>
    </div>
    <div class="col-12 col-address">
        <input class="input-address" [(ngModel)]="address_follow" type="text" nbInput fullWidth (change)="outputAddress()" placeholder="地址" [readonly]="readonly">
    </div>
</div>
