import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-index-banner',
    templateUrl: './index-banner.component.html',
    styleUrls: ['./index-banner.component.scss']
})
export class IndexBannerComponent {

    @Input() list;

    customOptions = {
        loop: true,
        autoplay: true,
        dots: false,
        navText: ['<img src="/assets/images/icon/chevron-left.svg">', '<img src="/assets/images/icon/chevron-right.svg">'],
        items: 1,
        nav: true
    };

}
