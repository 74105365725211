<nb-layout class="search-home-mobile">
    <nb-layout-header class="search-header">
        <div class="search-logo flex items-center">
            <a [routerLink]="['/']" class="block">
                <img src="/assets/images/logo.svg">
            </a>
            <div class="search-bar flex items-center w-full">
                <div class="search-bar-input flex-1">
                    <input type="text">
                </div>
                <div class="font-bold">取消</div>
            </div>
        </div>
    </nb-layout-header>
    <nb-layout-column>
        <div class="search-content">
            <div class="search-cate-menu">
            </div>
        </div>
    </nb-layout-column>
    <nb-layout-footer>
        <div class="footer-bar">
            <app-footer-bar></app-footer-bar>
        </div>
    </nb-layout-footer>
</nb-layout>
