import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router'; //接收route para
//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material'; //popup dialog
//import { LoginComponent } from '../auth/login/login.component';

@Injectable({
    providedIn: 'root'
})
export class LoginGuard implements CanActivate {

    constructor(
        private tokenService: TokenService,
        private router: Router,
        //private dialog:MatDialog
    ) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.check(next, state);
    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.check(next, state);
    }

    //進行權限檢查
    check(next, state) {
        return this.tokenService.infoState().pipe(
            map(code => {
                switch (code) {
                    case "SUCCESS":
                        return true;

                    default:
                        //登入後回去上一頁
                        this.router.navigate(['/auth/login'], { queryParams: { url: state.url } });
                        return false;
                }
            })
        );
    }

}
