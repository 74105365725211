<div class="list-item-therapy">
    <a [routerLink]="['/therapy/info', item.id]" class="therapy">
        <div class="therapy__img">
            <img alt="" [src]="item.image | webp:230" style="height:100%;width:100%" />
        </div>
        <div class="therapy__body">
            <div class="therapy__name">
                <div>{{ item.name }}</div>
                <div style="font-size:14px;margin-top:2px">{{ item.en_name }}</div>
            </div>
            <table class="therapy__info">
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/thumb.svg" alt="" />
                    </td>
                    <!-- 評分 -->
                    <td class="info-item__content" colspan="2">
                        <app-rating [showScore]="true" [ratingRatio]="item.worth_ratio"></app-rating>
                    </td>
                </tr>
                <!-- 價錢 -->
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/dollar.svg" alt="" />
                    </td>
                    <td class="info-item__content" colspan="2">{{ item.avg_cost }}元~{{ item.max_cost }}元</td>
                </tr>
                <!-- 評價數量 -->
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/stamp.svg" alt="" />
                    </td>
                    <td class="info-item__content">{{ item.member_experience_count }} 則</td>
                    <td class="info-item__content">(療程評價)</td>
                </tr>
                <!-- 醫師數量 -->
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/hand.svg" style="width:14px;height:14px" alt="" />
                    </td>
                    <td class="info-item__content">{{ item.doctor_count }} 位醫師</td>
                    <td class="info-item__content">(提供本療程)</td>
                </tr>
                <!-- 認證醫師 -->
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/verify.svg" alt="" />
                    </td>
                    <td class="info-item__content">{{ item.revise_doctor_name }}醫師</td>
                    <td class="info-item__content">(校閱)</td>
                </tr>
            </table>
        </div>
    </a>
</div>
