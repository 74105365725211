<nb-layout style="overflow:visible">
    <nb-layout-header>
        <div class="navbar navbar-header navbar-expand-lg pt-0">
            <div class="container">
<!-- Logo -->
                <a [routerLink]="['/']" class="navbar-brand">
                    <img src="./assets/images/logo.svg" [width]="layoutService.xl ? 115 : 70">
                </a>
<!-- 搜尋列 -->
                <div class="search">
                    <div class="search-bar">
                        <form (ngSubmit)="keywordSearch()">
                            <nb-form-field>
                                <nb-icon *ngIf="!layoutService.xl" nbPrefix icon="search" [attr.aria-label]="'搜尋'" style="color:#A68B81"></nb-icon>
                                <button *ngIf="layoutService.xl" nbSuffix nbButton ghost (click)="keywordSearch()" class="search-input-icon">
                                    <nb-icon icon="search" [attr.aria-label]="'搜尋'" style="color:#A68B81"></nb-icon>
                                </button>
                                <input name="keyword" nbInput type="text" id="search-input" [fieldSize]="layoutService.xl ? 'medium' :'small'" [fullWidth]="true" class="form-control" [placeholder]="searchPlaceHolder" [(ngModel)]="searchService.keyword" (focus)="searchService.toggleSearchView(true)" (blur)="searchService.toggleSearchView(false)">
                            </nb-form-field>
                        </form>
                    </div>
                </div>
<!-- 搜尋紀錄 -->
                <div class="search-suggest" *ngIf="searchService.isSearchFocus">
                    <div class="container">
                        <div class="hot-keywords">
                            <div class="title">大家都在搜</div>
                            <ul>
                                <li *ngFor="let item of hotKeywords" (click)="handleSearchSuggestClick(item.keyword)">{{ item.keyword }}</li>
                            </ul>
                        </div>
                        <div class="search-history">
                            <div class="title">最近搜尋紀錄</div>
                            <ul *ngIf="authService.user_info">
                                <li *ngFor="let item of searchService.searchHistory" (click)="handleSearchSuggestClick(item)">{{ item }}</li>
                            </ul>
                            <div *ngIf="!authService.user_info" class="search-history-unlogined">
                                <p>登入後顯示近期搜尋紀錄</p>
                                <button type="button" class="btn btn-primary btn-block" [routerLink]="['/auth/login/member']" routerLinkActive="active">點擊登入</button>
                            </div>
                        </div>
                    </div>
                </div>
                <ul class="navbar-func navbar-nav ms-auto ms-lg-4 ord er-lg-1">
                    <li class="nav-item">
<!-- 登入前的選單 -->
                        <div class="btn flex items-center" *ngIf="!authService.user_info">
                            <a [routerLink]="['/auth/login/member']" class="link">登入</a>
                            <span class="text link">／</span>
                            <a [routerLink]="['/auth/register']" class="link">註冊</a>
                            <a class="ms-3" [swal]="loginSwal" *ngIf="layoutService.xl">
                                <img src="/assets/images/icon/person.svg" class="icon" width="25">
                            </a>
                        </div>
<!-- 登入後的選單 -->
                        <ng-container *ngIf="authService.user_info">
                            <button type="button" class="btn userinfo-toggle" (click)="layoutService.toggleUserInfo()">
                                <span class="name">
                                    {{ authService.user_info.nickname }}<span *ngIf="authService.user_info.role == 'doctor'"> 醫師</span>
                                </span>
                                <div class="avatar avatar-xs">
                                    <img alt="" [src]="authService.user_info.avatar | webp:50" *ngIf="authService.user_info.avatar" />
                                </div>
                                <div class="avatar-red-point-block" *ngIf="haveUnreadCount$ | async">
                                    <div class="red-point"></div>
                                </div>
                            </button>
                            <div *ngIf="layoutService.userinfo_open" class="user-dropdown">
                                <div class="box">
                                    <div class="row gx-2 my-1">
                                        <div class="col-auto me-1">
                                            <div class="avatar avatar-sm">
                                                <img alt="" [src]="authService.user_info.avatar | webp:50" *ngIf="authService.user_info.avatar" />
                                            </div>
                                        </div>
                                        <div class="col flex">
                                            <div class="my-auto">
                                                <h6 class="name">{{ authService.user_info.nickname }}</h6>
                                                <span *ngIf="authService.user_info.role == 'doctor'" class="auth">
                                                    <nb-icon icon="checkmark-circle-2" class="me-1"></nb-icon>認證醫師
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="box px-0 py-2">
                                    <ul class="nav flex-column">
                                        <ng-container *ngIf="authService.user_info.role == 'writer'">
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/writer/therapy']" routerLinkActive="active">療程管理</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/writer/problem']" routerLinkActive="active">我想改善的問題管理</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/writer-article']" routerLinkActive="active">醫美情報管理</a></li>
                                        </ng-container>
                                        <ng-container *ngIf="authService.user_info.role == 'doctor'">
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/doctor/info']" routerLinkActive="active">個人資訊</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/doctor/resume']" routerLinkActive="active">學經歷</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/case']" routerLinkActive="active">醫師案例分享</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/forum']" routerLinkActive="active">討論文章分享</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/doctor-article']" routerLinkActive="active">醫師專欄文章</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/doctor-calendar/calendar']" routerLinkActive="active">行事曆</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/mail']" routerLinkActive="active">站內信</a></li>
                                        </ng-container>
                                        <ng-container *ngIf="authService.user_info.role == 'doctor_unconfirm'">
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/doctor/unconfirm']" routerLinkActive="active">醫生認證資訊</a></li>
                                        </ng-container>
                                        <ng-container *ngIf="authService.user_info.role == 'member'">
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/info']" routerLinkActive="active">個人資訊</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/favorite']" routerLinkActive="active">我的收藏</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/case']" routerLinkActive="active">療程文章分享</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/forum']" routerLinkActive="active">討論文章分享</a></li>
                                            <li class="nav-item"><a class="nav-link" [routerLink]="['/member/mail']" routerLinkActive="active">站內信</a></li>
                                        </ng-container>
                                        <li class="nav-item">
                                            <a class="nav-link notification" [routerLink]="['/member/notification']" routerLinkActive="active">
                                                <nb-icon icon="bell-outline"></nb-icon><span class="red-point" *ngIf="haveUnreadCount$ | async"></span>通知
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                                <div class="box">
                                    <div class="d-grid">
                                        <button type="button" id="logout" class="btn btn-outline" (click)="authService.logout()">登出</button>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </li>
                </ul>
            </div>
        </div>
<!-- 導覽列 (兩欄) -->
        <div class="navbar navbar-expand-lg" *ngIf="showNav && layoutService.xl">
            <div class="container">
                <div class="navbar-collapse" id="navbar">
                    <ul class="navbar-nav navbar-nav-menu">
                        <li class="nav-item" *ngFor="let item of menu">
                            <div>
                                <a [routerLink]="[item.route]" class="nav-link flex flex-column justify-center pt-0" (click)="layoutService.closeSidemenu()" routerLinkActive="active-link" [ngClass]="{active: item.isActive(curRoute)}">
                                    <div class="nav-link-item flex justify-center items-center mb-2">
                                        <img [src]="item.icon + '.svg'" [height]="item.iconHeight" class="nav_icon" />
                                        <img [src]="item.icon + '_active.svg'" [height]="item.iconHeight" class="nav_icon--active" />
                                    </div>
                                    <div class="nav-item__name">
                                        {{ item.name }}
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </nb-layout-header>
    <nb-layout-column>
<!-- Banner (單欄) -->
        <app-index-banner [list]="homeDataService.banners" *ngIf="!layoutService.xl && homeDataService.banners.length"></app-index-banner>
<!-- 網友熱搜療程 (單欄) -->
        <section class="section !xl-p-0 hot-therapy" *ngIf="!layoutService.xl && homeDataService.hotTherapies.length">
            <div class="container">
                <div class="heading">
                    <h3 class="title">
                        網友熱搜療程
                        <app-tip-btn [cloumn]="'tip2'"></app-tip-btn>
                    </h3>
                    <a routerLink="/therapy" class="heading-more">More</a>
                </div>
                <app-label-tip [cloumn]="'tip2'"></app-label-tip>
                <owl-carousel-o [options]="therapyBannerOptions">
                    <ng-template carouselSlide *ngFor="let item of homeDataService.hotTherapies">
                        <app-hot-therapy [item]="item" style="margin:0 5px"></app-hot-therapy>
                    </ng-template>
                </owl-carousel-o>
            </div>
        </section>
<!-- 導覽列 (單欄) -->
        <div class="navbar navbar--mobile navbar-expand-lg" *ngIf="showNav && !layoutService.showAside">
            <div class="container">
                <div class="navbar-collapse" id="navbar">
                    <ul class="navbar-nav navbar-nav-menu">
                        <li class="nav-item" *ngFor="let item of menu">
                            <div>
                                <a [routerLink]="[item.route]" class="nav-link flex flex-column justify-center pt-0" (click)="layoutService.closeSidemenu()" routerLinkActive="active-link" [ngClass]="{active: item.isActive(curRoute)}">
                                    <div class="nav-link-item flex justify-center items-center mb-2">
                                        <img [src]="item.icon + '.svg'" [height]="item.iconHeight" class="nav_icon" />
                                        <img [src]="item.icon + '_active.svg'" [height]="item.iconHeight" class="nav_icon--active" />
                                    </div>
                                    <div class="nav-item__name">
                                        {{ item.name }}
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
<!-- 內容區塊 -->
        <router-outlet></router-outlet>
<!-- 廣告 -->
        <div class="popup-banner" *ngIf="pop_up_banner && isShowPopup">
            <div class="ad">
                <button type="button" class="close" (click)="isShowPopup = false">
                    <nb-icon icon="close"></nb-icon>
                </button>
                <ng-container *ngIf="pop_up_banner.target == 'new'; else elsePopup">
                    <a [href]="pop_up_banner.url" target="_blank">
                        <picture>
                            <source media="(max-width: 1399px)" [srcset]="pop_up_banner.m_filepath | webp">
                            <img alt="" [src]="pop_up_banner.filepath | webp" />
                        </picture>
                    </a>
                </ng-container>
                <ng-template #elsePopup>
                    <a [href]="pop_up_banner.url">
                        <picture>
                            <source media="(max-width: 767px)" [srcset]="pop_up_banner.m_filepath | webp">
                            <img alt="" [src]="pop_up_banner.filepath | webp">
                        </picture>
                    </a>
                </ng-template>
            </div>
        </div>
    </nb-layout-column>
<!-- 頁尾 -->
    <nb-layout-footer>
        <app-footer-bar></app-footer-bar>
        <section class="slogan">
            <div class="container text-center">
                <span class="h3 mb-0">醫美圈圈的使命是透過廣大網友的真實療程經驗分享<br>協助消費者少走冤枉路並選擇正確的療程</span>
            </div>
        </section>
        <section class="footer">
            <div class="container">
                <div class="row g-3 g-md-4 mb-4 site-link">
                    <div class="col-12 col-md-12 col-lg-1" *ngIf="layoutService.smallThanLg">
                        <ul class="social-list" *ngIf="data">
                            <li>
                                <a href="{{ data.footer_info.fb_url }}" target="_blank"><img src="/assets/images/social/facebook.svg" class="icon"></a>
                            </li>
                            <li>
                                <a href="{{ data.footer_info.ig_url }}" target="_blank"><img src="/assets/images/social/instagram.svg" class="icon"></a>
                            </li>
                            <li>
                                <a href="{{ data.footer_info.line_url }}" target="_blank"><img src="/assets/images/social/line.svg" class="icon"></a>
                            </li>
                            <li>
                                <a href="{{ data.footer_info.youtube_url }}" target="_blank"><img src="/assets/images/social/youtube.svg" class="icon"></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="brand">
                    <img src="/assets/images/logo.svg" height="44">
                </div>
                <div class="site-link flex" [ngStyle]="{'flex-direction': layoutService.smallThanLg ? 'column' : 'row'}">
                    <div style="flex:1">
                        <h6 class="title flex-1" data-bs-toggle="collapse" data-bs-target="#circlePage">
                            <span style="flex:1">醫美圈圈</span><img *ngIf="layoutService.smallThanLg" class="float-end" src="/assets/images/icon/arrow_down2.svg">
                        </h6>
                        <ul class="list collapse" *ngIf="data" id="circlePage">
                            <li *ngFor="let item of data.footer_circle_pages" class="col-md-12"><a [routerLink]="['/content/page', item.url_code]">-{{ item.title }}</a></li>
                        </ul>
                    </div>
                    <div style="flex:1">
                        <h6 class="title" data-bs-toggle="collapse" data-bs-target="#siteMap">
                            <span style="flex:1">網站地圖</span><img *ngIf="layoutService.smallThanLg" class="float-end" src="/assets/images/icon/arrow_down2.svg">
                        </h6>
                        <ul class="list collapse" id="siteMap">
                            <li class="col-md-12"><a [routerLink]="['/experience-share/home']">-看案例</a></li>
                            <li class="col-md-12"><a [routerLink]="['/experience-share/forum']">-聊醫美</a></li>
                            <li class="col-md-12"><a [routerLink]="['/therapy']">-查療程</a></li>
                            <li class="col-md-12"><a [routerLink]="['/content/clinic']">-找診所</a></li>
                            <li class="col-md-12"><a [routerLink]="['/content/doctor']">-問醫生</a></li>
                            <li class="col-md-12"><a [routerLink]="['/content/news/all']">-長知識</a></li>
                        </ul>
                    </div>
                    <div style="flex:2">
                        <h6 class="title" data-bs-toggle="collapse" data-bs-target="#hotTherapy">
                            <span style="flex:1">熱門療程</span><img *ngIf="layoutService.smallThanLg" class="float-end" src="/assets/images/icon/arrow_down2.svg">
                        </h6>
                        <ul class="list collapse" *ngIf="data" id="hotTherapy">
                            <li class="col-md-6" *ngFor="let item of data.footer_therapies"><a [routerLink]="['/therapy/info', item.id]">-{{ item.name }}</a></li>
                        </ul>
                    </div>
                    <div *ngIf="!layoutService.smallThanLg">
                        <ul class="social-list" *ngIf="data">
                            <li>
                                <a href="{{ data.footer_info.fb_url }}" target="_blank"><img src="/assets/images/social/facebook.svg" class="icon"></a>
                            </li>
                            <li>
                                <a href="{{ data.footer_info.ig_url }}" target="_blank"><img src="/assets/images/social/instagram.svg" class="icon"></a>
                            </li>
                            <li>
                                <a href="{{ data.footer_info.line_url }}" target="_blank"><img src="/assets/images/social/line.svg" class="icon"></a>
                            </li>
                            <li>
                                <a href="{{ data.footer_info.youtube_url }}" target="_blank"><img src="/assets/images/social/youtube.svg" class="icon"></a>
                            </li>
                        </ul>
                    </div>
                </div>
                <hr>
                <div class="bottom">
                    <div class="text">刊載於醫美圈圈內的資訊僅用於教育目的。我們不提供醫療諮詢、診斷或建議。如果遇到任何的醫療問題，請與相關醫療專業人員聯繫</div>
                    <div class="links" *ngIf="data">
                        | <button (click)="open(dialog, '版權宣告', data.footer_info.copyright)">版權宣告</button>
                        | <button (click)="open(dialog, '服務條款', data.footer_info.service_term)">服務條款</button>
                        | <button (click)="open(dialog, '隱私權條款', data.footer_info.privacy)">隱私權條款</button>
                        |
                    </div>
                    <div class="copy">Copyright © 2022 Worth it All rights reserved.</div>
                </div>
            </div>
        </section>
    </nb-layout-footer>
</nb-layout>
<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card class="nb-dialog nb-dialog-lg">
        <nb-card-header>{{ data.title }}</nb-card-header>
        <nb-card-body class="editor-content" [innerHtml]="data.content"></nb-card-body>
        <nb-card-footer class="text-center">
            <button class="btn btn-primary" (click)="ref.close()">關閉</button>
        </nb-card-footer>
    </nb-card>
</ng-template>
<swal #loginSwal imageUrl='/assets/images/logo.svg' [showConfirmButton]=false [html]="t_webconfig.loginHtml"></swal>
