import { Component, Input, TemplateRef, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../environments/environment';
import { FileService } from '../../services/file.service';
import { Common } from '../../models/common.model';
import { Specialty } from '../../models/specialty.model';
import { NbDialogService } from '@nebular/theme';
import { NotificationsService } from '../../services/notifications.service';
import { Router } from '@angular/router'; //接收route para
import getBodyPartName from 'src/utils/get_body_part_name'
import { LayoutService } from 'src/app/services/layout.service';

@Component({
    selector: 'app-list-item',
    templateUrl: './list-item.component.html',
    styleUrls: ['./list-item.component.scss']
})
export class ListItemComponent {

    @Input() edit = true;
    @Input() item;
    @Input() type;
    @Input() link;
    @Input() articleType;
    @Output() deleteClick = new EventEmitter<any>();

    filepath = environment.FileUrl;
    common_option = Common;
    specialties = Specialty;
    // @ViewChild('dialog')  dialogRef:ElementRef

    constructor(
        public fileService: FileService,
        private dialogService: NbDialogService,
        public router: Router,
        private notificationsService: NotificationsService,
        public layoutService: LayoutService,
    ) { }

    getSpecialty(id) {
        let data = this.specialties.find(item => Number(id) == item.id);
        if (data) {
            return data.name;
        } else {
            return '';
        }
    }

    //文章連結
    getArticleRoute(id) {
        if (this.articleType) {
            const routes = {
                'article': '/content/article',
                'writer-am': '/content/news/writer-am',
                'doctor-am': '/content/news/doctor-am',
                'doctor-schedule': '/content/news/doctor-am',
                'therapy-am': '/content/news/therapy-am',
            }

            return [routes[this.articleType], id]
        }

        if (this.link) return [this.link, id]
        else return ['./', id]
    }

    gotoDoctor(data, ref) {
        if (data.user.role == 'doctor') {
            //TODO 11111 关闭窗口
            ref.close()
            this.router.navigate(['/content/doctor/' + data.user.id + '/desc'])
        }
    }

    //術前術後popup
    openBaDialog(dialog: TemplateRef<any>) {
        this.dialogService.open(dialog, { context: this.item });
    }

    //觸發刪除
    deleteItem(id) {
        this.deleteClick.emit(id);
    }

    viewNotification(id) {
        this.notificationsService.viewNotification(id);
    }

    divisionShow(index) {
        return this.item.s_certificate?.[index].if_show_frontend !== 0;
    }

    getBodyPartName = getBodyPartName

}
