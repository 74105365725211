export const Menu = [
    {
        name: '看案例', route: '/experience-share/home', icon: '/assets/images/icon/nav1', iconHeight: '20', isActive: (curr) => {
            return ['/experience-share/ba/info/', '/experience-share/member/info/'].some((path) => curr.startsWith(path));
        }
    },
    {
        name: '聊醫美', route: '/experience-share/forum', icon: '/assets/images/icon/nav2', iconHeight: '25', isActive: (curr) => {
            return false;
        }
     },
    {
        name: '查療程', route: '/therapy', icon: '/assets/images/icon/nav3', iconHeight: '30', isActive: (curr) => {
            return ['/problem/info/'].some((path) => curr.startsWith(path));
        }
    },
    {
        name: '找診所', route: '/content/clinic', icon: '/assets/images/icon/nav4', iconHeight: '25', isActive: (curr) => {
            return false;
        }
    },
    {
        name: '問醫生', route: '/content/doctor', icon: '/assets/images/icon/nav5', iconHeight: '25', isActive: (curr) => {
            return false;
        }
    },
    {
        name: '長知識', route: '/content/news/all', icon: '/assets/images/icon/nav6', iconHeight: '25', isActive: (curr) => {
            return ['/content/news/'].some((path) => curr.startsWith(path));
        }
    },
];
