<div class="banner" id="indexBanner">
    <owl-carousel-o [options]="customOptions">
        <ng-container *ngFor="let slide of list; let index=index">
            <ng-template carouselSlide [id]="'slide_'+index">
                <div class="banner-item">
                    <a [href]="slide.url" target="_blank">
                        <picture>
                            <source media="(max-width: 767px)" [srcset]="slide.m_filepath | webp:1400">
                            <img alt="" [src]="slide.filepath | webp:1400" />
                        </picture>
                    </a>
                </div>
            </ng-template>
        </ng-container>
    </owl-carousel-o>
</div>
