import { Directive, HostListener, Input, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { NgForm } from '@angular/forms';

@Directive({
    selector: '[appFocus]',
    exportAs: 'appFocus',
})
export class FocusDirective {

    @Input() formGroup: NgForm;

    constructor(
        private el: ElementRef,
        @Inject(PLATFORM_ID) private platformId: any,
    ) { }

    @HostListener('submit', ['$event'])
    public onSubmit(event: any): void {
        if (isPlatformBrowser(this.platformId)) {
            if ('INVALID' === this.formGroup.status) {
                event.preventDefault();

                const formGroupInvalid = this.el.nativeElement.querySelectorAll('.ng-invalid');

                (<HTMLInputElement>formGroupInvalid[0]).focus();
            }
        }
    }

}
