<ng-container *ngIf="isLogin; else noLogin">
    <button class="btn btn-sm px-2" type="button" [swal]="loginSwal" *ngIf="isFavorite"><img class="fa-icon" src="/assets/images/heart.png">已收藏</button>
    <button class="btn btn-sm px-2" type="button" [swal]="loginSwal" *ngIf="!isFavorite"><img class="fa-icon" src="/assets/images/heart.png">收藏</button>
    <button class="btn btn-sm px-2" type="button" [swal]="shareSwal"><img class="fa-icon" src="/assets/images/ri_share-fill.png">分享</button>
</ng-container>
<ng-template #noLogin>
    <button class="btn btn-sm px-2" type="button" [swal]="loginSwal"><img class="fa-icon" src="/assets/images/heart.png">收藏</button>
    <button class="btn btn-sm px-2" type="button" [swal]="shareSwal"><img class="fa-icon" src="/assets/images/ri_share-fill.png">分享</button>
</ng-template>
<swal #loginSwal imageUrl='/assets/images/logo.svg' [showConfirmButton]=false [html]="t_webconfig.loginHtml"></swal>
<swal #shareSwal imageUrl='/assets/images/logo.svg' [showConfirmButton]=false [html]="t_shareconfig.shareHtmlWithRedirect()"></swal>
