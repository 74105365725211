export const RouteList =  { 
	news: '/content/news/writer-am',
	blog: '/content/article',
	review: '/content/review',
	product: '/products',
	service: '/content/service',
	qa: '/content/service/qa',
	member: '/member',
	order: '/member/order',
	mailList: '/member/mail',
	memberQa: '/member/service/qa',
	home: '/',
	memberInfo: '/member/info',
	about: '/content/about',
	activity: '/activity/main',
	therapyCategory: '/therapy',
	problem: '/problem',
	memberHome: '/experience-share/home',
	memberShare: '/experience-share/member',
	beforeAfter: '/experience-share/ba',
	forum: '/experience-share/forum',
	doctors: '/content/doctor',

}