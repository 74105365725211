import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-rating',
    templateUrl: './rating.component.html',
    styleUrls: ['./rating.component.scss']
})
export class RatingComponent implements OnInit {

    @Input() showScore = false

    @Input() starColor: 'yellow' | 'brown' = 'brown'

    @Input() ratingRatio = 100

    fullStarCount = 5

    halfStarCount = 0

    emptyStarCount = 0

    score = 5.0

    numberToArray = (num) => {
        num = parseInt(num);
        num = num >= 0 ? num : 0;
        return Array(num).fill(0).map((x, i) => i);
    }

    constructor() { }

    ngOnInit(): void {
        if (!this.ratingRatio) this.ratingRatio = 0

        this.score = +(this.ratingRatio / 20).toFixed(1)
        this.fullStarCount = Math.floor(this.score)
        this.halfStarCount = this.score % 1 > 0 ? 1 : 0
        this.emptyStarCount = 5 - this.fullStarCount - this.halfStarCount
    }

}
