import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Component({
    selector: 'app-clinic-list-item',
    templateUrl: './clinic-list-item.component.html',
    styleUrls: ['./clinic-list-item.component.scss']
})
export class ClinicListItemComponent implements OnInit {

    @Input() setData: ClinicModel;

    constructor(
        protected sanitizer: DomSanitizer,
    ) { }

    ngOnInit(): void {
    }

}

export class ClinicModel {
    constructor(
        public id: number,
        public name: string,
        public rating: string,
        public county: string,
        public district: string,
        public isCheck: boolean,
        public banner: BannerModal,
        public is_popular: number,
        public user_ratings_total: number,
        public rating_balance: number,
        public is_verify: number,
    ) {
        this.name = name;
        this.rating = rating;
        this.isCheck = true;
        this.banner = banner;
        this.is_popular = is_popular;
        this.county = county;
        this.district = district;
        this.user_ratings_total = user_ratings_total;
        this.rating_balance = rating_balance;
        this.is_verify = is_verify;
    }
}
export class BannerModal {
    constructor(public url: string) {
        this.url = url
    }
}
