import { TipstatusService } from './../../services/tipstatus.service';
import { Component, OnInit, Input } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-label-tip',
    templateUrl: './label-tip.component.html',
    styleUrls: ['./label-tip.component.scss']
})
export class LabelTipComponent implements OnInit {

    @Input() cloumn;

    text;

    constructor(
        public tipstatusService: TipstatusService,
        private httpService: HttpService,
    ) { }

    ngOnInit(): void {
        if (this.tipstatusService.showStatus[this.cloumn] == undefined) {
            this.tipstatusService.showStatus[this.cloumn] = true
        }

        this.getTip(this.cloumn)
    }

    //獲取提示信息
    getTip(column) {
        let url = `${environment.APIUrl}/api/content/parameter?name=brand_tips`;
        this.httpService.getHttp(url).subscribe(
            res => this.text = res.value[column]
        )
    }

}
