import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NbPopoverDirective } from '@nebular/theme';
import { Problem } from 'src/app/models/problem.model';
import { Therapy } from 'src/app/models/therapy.model';
import { HomeDataService } from 'src/app/services/home-data.service';
import { HttpService } from 'src/app/services/http.service';
import { LayoutService } from 'src/app/services/layout.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-filter-menu-experience',
    templateUrl: './filter-menu-experience.component.html',
    styleUrls: ['./filter-menu-experience.component.scss']
})
export class FilterMenuExperienceComponent implements OnInit {

    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
    @Output() change = new EventEmitter();

    constructor(
        private httpService: HttpService,
        private router: Router,
        private homeDataService: HomeDataService,
        private route: ActivatedRoute,
        private layoutService: LayoutService,
    ) { }

    modelValue

    /** 已确定选中的tab，与curtab区别是：curtab可能还未选中 */
    selectedTab = 0

    selectModelValue(val) {
        this.modelValue = val
        this.selectedTab = this.curTab
        this.handleChange({ value: val })
    }

    homeData = null

    allTherapipyCates = Therapy

    get flattenTherapies() {
        return this.allTherapipyCates.reduce((acc, cur) => {
            acc.push(...cur.children.map(item => ({ ...item, parent_id: cur.id })))
            return acc
        }, [])
    }

    defaultTherapy = { id: 'hot', name: '熱門療程' }

    // 热门疗程
    get hotTherapies() {
        return [
            this.defaultTherapy,
            ...(this.homeData?.hot_review_categories || [])
        ]
    }

    allOrgans = Problem

    organId = null

    setOrganId(val) {
        this.organId = val
        this.getProblems(val)
    }

    problems = []

    isMobile = false

    getIsMobile() {
        if (window.screen?.width < 992) return true
        return false
    }

    updateLastMenus() {
        let lastMenus = []
        if (this.selectedTab == 0) lastMenus = this.hotTherapies
        else if (this.selectedTab == 1) {
            const parentId = this.flattenTherapies.find(item => item.id == this.modelValue)?.parent_id
            lastMenus = this.flattenTherapies.filter((therapy) => therapy.parent_id == parentId)
        }
        else if (this.selectedTab == 2) lastMenus = this.problems

        // 最后一级菜单，给外层用
        this.change.emit({ lastMenus })
        this.tabLabel = this.getLabel()
    }

    ngOnInit(): void {
        this.getCategories()
        this.getHomeData()
        this.organId = this.allOrgans[0]

        this.route.queryParams.subscribe(
            queryParams => {
                this.selectedTab = queryParams.curTab || 0
                if (this.selectedTab == 0) this.modelValue = queryParams.categoryId || 'hot'
                else if (this.selectedTab == 1) this.modelValue = queryParams.categoryId
                else if (this.selectedTab == 2) {
                    this.modelValue = queryParams.problemId || 'all'
                    this.organId = queryParams.bodyPartId || this.organId
                    if (this.organId) this.getProblems(this.organId)
                }

                this.updateLastMenus()
            }
        )

        this.isMobile = this.getIsMobile()
    }

    getCategories() {
        let url = `${environment.APIUrl}/api/content/categories`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.allTherapipyCates = res.items;
                // this.flattenTherapies = this.allTherapipyCates.reduce((acc, cur) => {
                //   acc.push(...cur.children)
                //   return acc
                // }, [])

                this.updateLastMenus()
            }
        )
    }

    //問題清單
    getProblems(organId) {
        let url = `${environment.APIUrl}/api/content/problems?per_page=999&body_part=${organId}`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.problems = res.problem.items;
                this.updateLastMenus()
            }
        )
    }

    getHomeData() {
        this.homeDataService.getHomeData().then(
            res => {
                this.homeData = res;
                this.updateLastMenus()
            }
        )
    }

    curTab = 0

    tabLabel = this.getLabel()

    handleChange({
        tabIndex = undefined,
        value,
        closePopover = true
    }) {
        if (tabIndex == undefined) tabIndex = this.curTab
        this.curTab = tabIndex
        this.modelValue = value

        let lastMenus = this.updateLastMenus()

        this.change.emit({
            // 最后一级菜单，给外层用
            lastMenus
        })

        if (value && closePopover) this.popover?.hide()

        const queryParams = { curTab: this.selectedTab } as any

        if (this.curTab == 0) queryParams.categoryId = value
        else if (this.curTab == 1) queryParams.categoryId = value
        else if (this.curTab == 2) {
            if (value == 'all') queryParams.problemId = ''
            else {
                queryParams.bodyPartId = this.organId
                queryParams.problemId = value
            }
        }

        this.router.navigate(['/experience-share/home'], { queryParams })
    }

    handleChangeTab(event) {
        this.curTab = event.tabId
    }

    getLabel() {
        if (this.selectedTab == 0) {
            return this.hotTherapies.find(item => item.id == this.modelValue)?.name
        } else if (this.selectedTab == 1) {
            return this.flattenTherapies.find(item => item.id == this.modelValue)?.name
        } else if (this.selectedTab == 2) {
            if (this.modelValue == 'all') return '全部'
            return this.problems.find(item => item.id == this.modelValue)?.name
        }
    }

    handleTriggerClick() {
        setTimeout(() => {
            if (this.popover.isShown && this.layoutService.smallThanMd) {
                document.querySelector('.cdk-overlay-pane')?.classList.add('filter-menu-mobile')
            }
        });
    }

}
