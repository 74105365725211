<a [routerLink]="['/content/clinic/info', setData.id]">
    <div class="review-card">
        <!-- TODO: 圖片-->
        <div *ngIf="!setData.banner" class="image-placeholder"></div>
        <img *ngIf="setData.banner" [src]="setData.banner.url" style="width: 100px; height: 100px; border-radius: 8px;" alt="Location">
        <div class="review-content">
            <div class="review-header">
                <div class="header-top">
                    <h2 class="place-name">{{setData.name}}</h2>
                    <!-- TODO: <div class="verified-icon" *ngIf=""> -->
                    <div *ngIf="setData.is_verify" class="verified-icon">
                        <img style="margin-right: 3px;" src="/assets/images/icon/vector-check.png" width="10px" alt="" />
                        <span style="font-size: 12px; margin: auto;">已核實</span>
                    </div>
                    <div class="header-icons">
                        <app-favorite-lite [isFavorite]="true" [id]="" [iconSize]="'20px'" type="issues" text=""></app-favorite-lite>
                        <div *ngIf="setData.is_popular == 1" class="hot-tag"></div>
                    </div>
                </div>
                <div class="header-bottom">
                    <span class="location">{{setData.county}} {{setData.district}}</span>
                    <img src="/assets/images/icon/map-pin.png" alt="Location">
                </div>
            </div>
            <div class="review-rating">
                <span class="rating-container">
                    <app-rating [showScore]="true" [starColor]="'yellow'" [ratingRatio]="setData.rating_balance"></app-rating>
                    <div class="review-count">({{setData.user_ratings_total}} Google 評價)</div>
                </span>
            </div>
            <div class="review-actions">
                <div class="hashtag mt-0">
                    <div class="row gx-2">
                        <div class="col-4 tags">
                            <!-- TODO: <a *ngFor="" [routerLink]="['','','']" > {{ XXXX }}</a> -->
                            <a style="font-size: 12px;color: #6B6969; padding-top: 0px; padding-bottom: 0px;" [routerLink]="['/search/hashtag','123','therapies']">肉毒</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</a>
