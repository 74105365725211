import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { Router, ActivatedRoute } from '@angular/router'; //接收route para
import { MetaService } from '../../services/meta.service';

@Component({
    selector: 'app-page',
    templateUrl: './page.component.html',
    styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

    data;
    code;
    metaCode = ['about-us', 'join-us', 'cooperation', 'contact-us'];

    constructor(
        @Inject(PLATFORM_ID) protected platformId: Object,
        protected httpService: HttpService,
        protected router: Router,
        protected route: ActivatedRoute,
        protected metaService: MetaService,
    ) { }

    ngOnInit(): void {
        this.route.params.subscribe(
            params => {
                if (params.code) {
                    this.code = params.code;
                    this.getPage();
                }
            }
        )
    }

    getPage() {
        let url = `${environment.APIUrl}/api/page-data?url_code=${this.code}`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.data = res.page;

                if (this.metaCode.includes(this.code)) {
                    this.metaService.setByPageData(this.data);
                }
            }
        )
    }

}
