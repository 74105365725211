import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { TokenService } from './token.service';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import { Router } from '@angular/router'; //接收route para
import { SocialAuthService as SocialService } from "angularx-social-login";
import swal from 'sweetalert2';
import { FacebookLoginProvider, GoogleLoginProvider } from "angularx-social-login";
import { isPlatformBrowser } from '@angular/common';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    user_info;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private tokenService: TokenService,
        public httpService: HttpService,
        private router: Router,
        private socialService: SocialService,
    ) { }

    //已登入導向指定頁面
    loginRedirct(path) {
        this.tokenService.infoState()
            .subscribe(res => {
                if (res == 'SUCCESS') {
                    let user_info = localStorage.getItem('user_info');
                    if (user_info) {
                        this.user_info = JSON.parse(user_info);
                    }
                    this.router.navigate([path]);
                }
            });
    }

    //進行登入 儲存資料
    login(data) {
        localStorage.removeItem('guest_id');
        localStorage.setItem('token', data.token);
        localStorage.setItem('user_info', JSON.stringify(data.user_info));

        let user_info = localStorage.getItem('user_info');
        if (user_info) {
            this.user_info = JSON.parse(user_info);
        }
    }

    //取得目前登入的使用者資料
    getUserInfo() {
        if (isPlatformBrowser(this.platformId)) {
            this.tokenService.infoState()
                .subscribe(res => {
                    if (res == 'SUCCESS') {
                        localStorage.removeItem('guest_id');
                        //localStorage.removeItem('user_role');

                        let user_info = localStorage.getItem('user_info');
                        if (user_info) {
                            this.user_info = JSON.parse(user_info);
                        }
                    } else {
                        localStorage.removeItem('user_info');
                    }
                });
        }
    }

    //登出
    logout(path = '/auth/login/member') {
        this.httpService.postApi('/api/lab-auth/logout').subscribe(
            () => {
                localStorage.clear();
                this.user_info = null;
                this.router.navigate([path]);
            }
        );
    }

    //忘記密碼
    forgetPW(username) {
        let url = `${environment.APIUrl}/api/lab-auth/forget-password`;
        let params = new FormData();
        params.append('username', username);

        this.httpService.postHttp(url, params).subscribe(
            res => {
                swal.fire({
                    icon: 'success',
                    text: '請至信箱收取新密碼信件'
                })
            }
        );
    }

    //Google登入
    signInWithGoogle(redirect_url = '/', role = 'member', register_url = '/auth/register'): void {
        if (isPlatformBrowser(this.platformId)) {
            this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID, { scope: 'profile,email' }).then(
                (userData) => {
                    this.socialLogin(userData, 'google', redirect_url, register_url);
                }
            );
        }
    }

    //Google登入
    signInWithGoogleJS(response: any) {
        //Do what you wish with the received idToken

        // This next is for decoding the idToken to an object if you want to see the details.
        let base64Url = response.credential.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        let data = JSON.parse(jsonPayload);

        let userData = {
            id: data.sub,
            email: data.email,
            name: data.name,
            photoUrl: data.picture
        }

        this.socialLogin(userData, 'google');
    }

    //FB社群登入
    signInWithFB(redirect_url = '/', role = 'member', register_url = '/auth/register') {
        if (isPlatformBrowser(this.platformId)) {
            this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then(
                (userData) => {
                    this.socialLogin(userData, 'fb', redirect_url, register_url);
                }
            );
        }
    }

    //社群登入with labspace API
    socialLogin(user, provider, redirect_url = '/', register_url = '/auth/register') {
        //必須確認有id
        if (!user.id) {
            swal.fire({
                icon: 'error',
                text: '無法連結到你的社群網站, 請改用帳號密碼登入'
            });
            return;
        }

        //login system api
        let params: FormData = new FormData();
        params.append('social_id', user.id);
        params.append('provider', provider);
        params.append('email', user.email);

        const url = `${environment.APIUrl}/api/lab-auth/social-login`;

        this.httpService.postHttp(url, params).subscribe(
            res => {
                //login 失敗
                if (res.success_code) {
                    switch (res.success_code) {
                        //需要註冊
                        case "PLEASE_REGISTER":
                            this.router.navigate([register_url], { queryParams: user })
                                .then(() => {
                                    window.location.reload();
                                });
                            break;
                    }

                    //login 成功
                } else {
                    this.login(res);
                    swal.fire({
                        title: '登入成功',
                        icon: 'success'
                    }).then((result) => {
                        //this.router.navigate([redirect_url]);
                        location.href = redirect_url;
                    });
                }
            }
        );
    }

}
