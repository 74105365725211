export const Specialty: any = [
	{ id:1 , name:"家庭醫學科" },
	{ id:2 , name:"內科" },
	{ id:3 , name:"外科" },
	{ id:4 , name:"兒科" },
	{ id:5 , name:"婦產科" },
	{ id:6 , name:"骨科" },
	{ id:7 , name:"神經外科" },
	{ id:8 , name:"泌尿科" },
	{ id:9 , name:"耳鼻喉科" },
	{ id:10, name:"眼科" },
	{ id:11, name:"皮膚專科" },
	{ id:12, name:"神經科" },
	{ id:13, name:"精神科" },
	{ id:14, name:"復健科" },
	{ id:15, name:"麻醉科" },
	{ id:16, name:"放射診斷科" },
	{ id:17, name:"放射腫瘤科" },
	{ id:18, name:"解剖病理科" },
	{ id:19, name:"臨床病理科" },
	{ id:20, name:"核子醫學科" },
	{ id:21, name:"急診醫學科" },
	{ id:22, name:"職業醫學科" },
	{ id:23, name:"整形外科專科" }
	
];