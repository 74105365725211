import { Pipe } from '@angular/core';

@Pipe({
    name: "chunk"
})
export class ChunkPipe {

    transform(array: any[], size: number) {
        const chunks = [];

        for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
        }

        return chunks;
    }

}
