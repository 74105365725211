import { Component, OnInit, Inject, PLATFORM_ID, TemplateRef } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ViewEncapsulation } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { LayoutService } from '../../services/layout.service';
import { Router, NavigationEnd } from '@angular/router'; //接收route para
import swal from 'sweetalert2';
import { Menu } from '../../models/menu.model';
import { NbDialogService } from '@nebular/theme';
import { NotificationsService } from '../../services/notifications.service';
import { filter } from 'rxjs/operators';
import { Webconfig } from './../../models/webconfig.model';
import { HomeDataService } from 'src/app/services/home-data.service';
import { SearchService } from 'src/app/services/search.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class HomeComponent implements OnInit {

    data;
    menu = Menu;

    selectedItem = '1';

    t_webconfig = Webconfig;

    adPopupDate = new Date(JSON.parse(localStorage.getItem('adPopupDate')));
    isShowPopup = false;
    pop_up_banner;
    haveUnreadCount$ = this.notificationsService.getHaveUnreadCount();

    therapyBannerOptions = {
        loop: true,
        dots: false,
        autoplay: true,
        responsive: {
            0: { items: 2.3 },
            736: { items: 4 },
            992: { items: 5 },
        },
    };

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        public authService: AuthService,
        private httpService: HttpService,
        public layoutService: LayoutService,
        private router: Router,
        private dialogService: NbDialogService,
        private notificationsService: NotificationsService,
        public homeDataService: HomeDataService,
        public searchService: SearchService,
    ) { }

    hotKeywords = []

    showNav = true

    curRoute = ''

    ngOnInit(): void {
        const isShowNav = (url = '') => {
            if (url.includes('/search/hashtag')) return false
            return true
        }

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((res: any) => {
                this.curRoute = res.url.split('?')[0]
                this.showNav = isShowNav(res.url)
            });

        //this.authService.getCartCount();
        this.getData();
        this.authService.getUserInfo();
        //this.cartService.updateCartCount();

        // 當點擊body區域時
        this.layoutService.onClickBody();
        // 關閉主選單
        this.layoutService.closeNavbar();

        this.showAdPopup();
        this.getAdPopup();
        this.notificationsService.isAllRead$.subscribe(() => this.haveUnreadCount$ = this.notificationsService.getHaveUnreadCount());

        this.homeDataService.getHomeData().then(res => this.hotKeywords = res.hot_keywords)

        if (isPlatformBrowser(this.platformId)) {
            this.intervalChangeSearchPlaceholder()
        }
    }

    //取得header footer資料
    getData() {
        let url = `${environment.APIUrl}/api/common-data`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.data = res;
            }
        )
    }

    //站內關鍵字搜尋
    keywordSearch() {
        if (!this.searchService.keyword && this.searchPlaceHolder !== "搜尋") {
            this.searchService.keyword = this.searchPlaceHolder
        }

        if (!this.searchService.keyword) {
            swal.fire({
                icon: 'error',
                text: '請輸入關鍵字',
            })
            return;
        }

        //hashtag search
        if (this.selectedItem == "1") {
            this.router.navigate(['/search/hashtag', this.searchService.keyword, 'hot']);
            this.searchService.setSearchHistory(this.searchService.keyword)
            this.searchService.toggleSearchView(false)
            // blur
            document.getElementById('search-input').blur()
            //站內搜尋
        } else {
            this.router.navigate(['/search/google', this.searchService.keyword]);
        }
    }

    open(dialog: TemplateRef<any>, title, data) {
        this.dialogService.open(dialog, { context: { title: title, content: data } });
    }

    showAdPopup() {
        const today = new Date();
        if (this.adPopupDate.getFullYear() == today.getFullYear() &&
            this.adPopupDate.getMonth() == today.getMonth() &&
            this.adPopupDate.getDate() == today.getDate()) {
            this.isShowPopup = false;
        } else {
            this.isShowPopup = true;
        }
    }

    getAdPopup() {
        if (this.isShowPopup) {
            let url = `${environment.APIUrl}/api/content/parameter/popup-banner`;
            this.httpService.getHttp(url).subscribe(res => {
                this.pop_up_banner = res.pop_up_banner;
                localStorage.setItem('adPopupDate', JSON.stringify(new Date()));
            })
        }
    }

    searchPlaceHolder = "搜尋"

    intervalChangeSearchPlaceholder() {
        let index = 0
        const interval = setInterval(() => {
            this.searchPlaceHolder = this.hotKeywords[index]?.keyword ?? "搜尋"
            index++
            if (index >= this.hotKeywords.length) index = 0
        }, 6000)
    }

    handleSearchSuggestClick(keyword) {
        this.searchService.keyword = keyword
        this.keywordSearch()
    }

}
