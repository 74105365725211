import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HttpService {

    //開啟或關閉loading 動畫
    loadingOpen = true;
    requestCount = 0;

    constructor(private http: HttpClient) { }

    getApi(url: string): Observable<any> {
        return this.getHttp(environment.APIUrl + url);
    }

    getHttp(url: string): Observable<any> {
        let options = {
            responseType: 'text' as 'text'
        };
        return this.http.get<any>(url)
            .pipe(
                //retry(3),
                //catchError(this.handleError('getHttp', ['HttpError'])
                catchError(this.handleErrorStop)
            );
    }

    getParamFormat(params): string {
        let return_param;

        if (Object.keys(params).length == 1) {
            let key = Object.keys(params)[0];
            return_param = `${key}=${params[key]}`;
        } else if (Object.keys(params).length > 1) {
            return_param = Object.keys(params).reduce((result, key, index) => {
                if (index == 1) {
                    return `${result}=${params[result]}&${key}=${params[key]}&`;
                } else {
                    return `${result}${key}=${params[key]}&`;
                }
            });
        }

        return return_param;
    }

    postApi(url: string, formData = null, headers = {}): Observable<any> {
        return this.postHttp(environment.APIUrl + url, formData, headers);
    }

    postHttp(url: string, formData, headers = {}): Observable<any> {
        return this.http.post<any>(url, formData, headers)
            .pipe(
                //catchError(this.handleError('postHttp', ['HttpError'])
                catchError(this.handleErrorStop)
            );
    }

    deleteHttp(url: string): Observable<any> {
        return this.http.delete<any>(url)
            .pipe(
                //atchError(this.handleError('deleteHttp', ['HttpError'])
                catchError(this.handleErrorStop)
            );
    }

    putHttp(url: string, putData, headers = {}): Observable<any> {
        return this.http.put<any>(url, putData, headers)
            .pipe(
                //catchError(this.handleError('putHttp', ['HttpError'])
                catchError(this.handleErrorStop)
            );
    }

    private handleErrorStop(error: Response | any) {
        // In a real world app, we might use a remote logging infrastructure
        return Observable.create(observer => observer.error(error));;
    }

    handleError<T>(operation = 'operation', result?: T) {
        //return Observable.throw("123");

        return (error: any): Observable<boolean> => {
            // TODO: send the error to remote logging infrastructure
            // TODO: better job of transforming error for user consumption

            /*
            swal({
              title: 'Error!',
              text: '系統出現錯誤，請通知系統管理員',
              type: 'error',
              confirmButtonText: '確定'
            });
            */

            // Let the app keep running by returning an empty result.
            return of(false);
            //return of(result as T);
        };
    }

}
