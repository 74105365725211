<div *ngIf="control.invalid">
    <small *ngIf="control.errors.required" class="form-text text-danger">
        {{ name | translate }}{{ 'form-error.required' | translate }}
    </small>
    <small *ngIf="control.errors.email" class="form-text text-danger">
        {{ name | translate }}{{ 'form-error.email' | translate }}
    </small>
    <small *ngIf="control.errors.minlength" class="form-text text-danger">
        {{ name | translate }}{{ 'form-error.min-length' | translate }}{{ control.errors.minlength.requiredLength }}
    </small>
    <small *ngIf="control.errors.min" class="form-text text-danger">
        {{ name | translate }}{{ 'form-error.min-value' | translate }}{{ control.errors.min.min }}
    </small>
    <small *ngIf="control.errors.max" class="form-text text-danger">
        {{ name | translate }}{{ 'form-error.max-value' | translate }}{{ control.errors.max.max }}
    </small>
    <small *ngIf="control.errors.pattern" class="form-text text-danger">
        {{ name | translate }}{{ 'form-error.pattern' | translate }}
    </small>
</div>
