import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout-admin-list',
    templateUrl: './layout-admin-list.component.html',
    styleUrls: ['./layout-admin-list.component.scss']
})
export class LayoutAdminListComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
