import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    constructor() { }

    getThumbnail(path, size) {
        if (!path) {
            return path;
        }

        let arr = path.split('/');

        let filename = arr[arr.length - 1];

        let new_path = '';

        arr.forEach((item, key) => {
            if (key == arr.length - 1) {
                if (size == 'xs') {
                    new_path += `thumbs/${filename}`;
                } else {
                    new_path += `thumbs_${size}/${filename}`;
                }

            } else {
                new_path += `${item}/`;
            }
        })

        return new_path;
    }

    //檢查檔案格式
    checkFileType(file, type) {
        let allowType = ['image/jpeg', 'image/png'];  //允許的圖片副檔名
        if (type == 'image' && allowType.indexOf(file.type) == -1) {

            swal.fire({
                title: '檔案格式不符',
                text: '只允許jpg,png',
                icon: 'error'
            });
            return false;
        }

        let allowType_video = ['video/mp4'];  //允許的影片副檔名
        if (type == 'video' && allowType_video.indexOf(file.type) == -1) {
            swal.fire({
                title: '檔案格式不符',
                text: '只允許mp4',
                icon: 'error'
            });
            return false;
        }

        return true;
    }

}
