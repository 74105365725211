import { ShareModule } from './share/share.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TransferHttpCacheModule } from '@nguniversal/common';

import {
    NbThemeModule,
    NbLayoutModule,
    NbSidebarModule,
    NbMenuModule,
    NbIconModule,
    NbDialogModule,
    NbTooltipModule,
    NbDatepickerModule,
    NbBadgeModule,
    NbActionsModule,
    NbSelectModule,
    NbAccordionModule,
    NbCardModule,
    NbPopoverModule,
    NbFormFieldModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';

//google recaptch
import { RecaptchaModule } from 'ng-recaptcha';

//http client & interceptor
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//多語系
import { HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageService } from './services/language.service';

//loading
import { NgxSpinnerModule } from "ngx-spinner";

//Interceptor
import { RequestInterceptor } from '../app/interceptors/request.interceptor';
import { ResponseInterceptor } from '../app/interceptors/response.interceptor';
import { ErrorNotifierInterceptor } from '../app/interceptors/errorNotifier.interceptor';

//sweetalert2
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

//gobal var
import { environment } from '../environments/environment';
import { FileLibModule } from '@fresh08man/file-lib';

//form
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//slider
import { CarouselModule } from 'ngx-owl-carousel-o';

//component
import { HomeComponent } from './layout/home/home.component';

//social login
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { FacebookLoginProvider } from "angularx-social-login";

//Http interceptor providers in outside-in order
export const httpInterceptorProviders = [
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ResponseInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorNotifierInterceptor, multi: true },
];

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
    ],
    imports: [
        NbAccordionModule,
        NbSelectModule,
        NbCardModule,
        NbPopoverModule,
        CarouselModule,
        NbActionsModule,
        NbBadgeModule,
        FileLibModule.forRoot(environment),
        FormsModule,
        ReactiveFormsModule,
        BrowserModule.withServerTransition({ appId: 'serverApp' }),
        AppRoutingModule,
        BrowserAnimationsModule,
        NbThemeModule.forRoot({ name: 'corporate' }),
        NbLayoutModule,
        NbEvaIconsModule,
        NbIconModule,
        NbSidebarModule.forRoot(),
        NbMenuModule.forRoot(),
        NbDialogModule.forRoot(),
        NbDatepickerModule.forRoot(),
        NbTooltipModule,
        NbFormFieldModule,
        HttpClientModule,
        NgxSpinnerModule,
        SweetAlert2Module.forRoot(),
        TransferHttpCacheModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (http) => new TranslateHttpLoader(http, `${environment.assetsUrl}/assets/i18n/`, '.json'),
                deps: [HttpClient]
            }
        }),
        RecaptchaModule,
        SocialLoginModule,
        ShareModule,
    ],
    exports: [],
    providers: [
        httpInterceptorProviders,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: FacebookLoginProvider.PROVIDER_ID,
                        provider: new FacebookLoginProvider(environment.FBappId)
                    }
                ]
            } as SocialAuthServiceConfig,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(private languageService: LanguageService) {
        this.languageService.setInitState();
    }
}
