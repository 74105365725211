import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NbPopoverDirective } from "@nebular/theme";
import { HttpService } from "../../services/http.service";
import { ActivatedRoute, Router } from "@angular/router";
import { HomeDataService } from "../../services/home-data.service";
import { LayoutService } from "../../services/layout.service";
import { Therapy } from "../../models/therapy.model";
import { environment } from "../../../environments/environment";
import { CityAndDistrictsList } from "../models/citylist.model";
import { LocationService } from "../location/location.service";

@Component({
    selector: 'app-filter-menu-clinic',
    templateUrl: './filter-menu-clinic.component.html',
    styleUrls: ['./filter-menu-clinic.component.scss']
})
export class FilterMenuClinicComponent implements OnInit {

    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
    @Output() change = new EventEmitter();

    constructor(
        private httpService: HttpService,
        private router: Router,
        private homeDataService: HomeDataService,
        private route: ActivatedRoute,
        private layoutService: LayoutService,
        private locationService: LocationService
    ) { }

    modelValue: string | null = null

    // 選擇的分類 0: 熱搜診所 1: 地區查找 2: 療程查找 3:我的附近
    selectedTab = 0

    /**
     * 選擇結果事件
     * @param val
     * @param closePopover
     * @param toRouter
     */
    selectModelValue(val, closePopover = true, toRouter = true) {
        this.modelValue = val
        this.selectedTab = this.curTab
        this.handleChange({ value: val, closePopover: closePopover }, toRouter)
    }

    homeData = null

    // 所有療程分類
    allTherapyCategory = Therapy

    get flattenTherapies() {
        return this.allTherapyCategory.reduce((acc, cur) => {
            acc.push(...cur.children.map(item => ({ ...item, parent_id: cur.id })))
            return acc
        }, [])
    }

    // 熱搜診所
    defaultClinic = { id: 'hot', name: '熱搜診所' }

    get hotClinic() {
        return [
            this.defaultClinic, ...(this.homeData?.hot_review_categories || [])
        ]
    }

    // 縣市所有列表
    allCityAndDistrictsList = CityAndDistrictsList;
    // 縣市列表
    countiesList: any[] = this.allCityAndDistrictsList.counties;
    // 地區列表
    districtsList: { id: string; name: string; }[] = [];
    // 選擇的縣市
    selectedCountyId: string | null = null;
    selectedCountyName: string | null = null;
    // 選擇的地區
    selectedDistrict: string | null = null;
    selectedDistrictName: string | null = null;

    /**
     * 選擇縣市
     * @param countyId
     */
    selectCounty(countyId: string, countyName: string) {
        this.selectedCountyId = countyId;
        // 重設選擇的地區清單
        this.districtsList = this.allCityAndDistrictsList.districts[countyId] || [];
        this.selectedDistrict = null;

        this.selectedCountyName = countyName;

        this.selectModelValue(countyId, false, false);
    }

    /**
     * 選擇地區
     * @param districtId
     */
    selectDistrict(districtId: string, districtName: string) {
        this.selectedDistrict = districtId;
        this.modelValue = districtId;

        this.selectedDistrictName = districtName;

        this.selectModelValue(districtId);
    }

    // 療程查找
    // problems = []

    // 是否行動裝置
    isMobile = false

    /**
     * 是否行動裝置
     */
    getIsMobile() {
        return window.screen?.width < 992 ? true : false;
    }

    /**
     * 更新最後選單
     */
    updateLastMenus() {
        let lastMenus = []

        // 0: 熱搜診所 1: 地區查找 2: 療程查找 3:我的附近
        if (this.selectedTab == 0) {
            lastMenus = this.hotClinic;
        } else if (this.selectedTab == 1) {
            // TODO
            // lastMenus = ['縣市地區'];
            // this.modelValue = this.selectedCountyName + this.selectedDistrictName;
        } else if (this.selectedTab == 2) {
            const parentId = this.flattenTherapies.find(item => item.id == this.modelValue)?.parent_id
            lastMenus = this.flattenTherapies.filter((therapy) => therapy.parent_id == parentId)
        } else if (this.selectedTab == 3) {
            // TODO
        }

        // 通知外層
        this.change.emit({ lastMenus })
        this.tabLabel = this.getLabel()
    }

    ngOnInit(): void {
        // 預設縣市
        this.selectedCountyId = this.countiesList[0];

        this.getCategories()
        this.getHomeData()

        this.route.queryParams.subscribe(
            queryParams => {
                // 選擇的分類 0: 熱搜診所 1: 地區查找 2: 療程查找 3:我的附近
                this.selectedTab = queryParams.curTab || 0

                if (this.selectedTab == 0) {
                    this.modelValue = queryParams.categoryId || 'hot'
                } else if (this.selectedTab == 1) {

                } else if (this.selectedTab == 2) {
                    this.modelValue = queryParams.categoryId;
                } else if (this.selectedTab == 3) {

                }

                this.updateLastMenus()
            }
        )

        this.isMobile = this.getIsMobile()
    }

    getCategories() {
        let url = `${environment.APIUrl}/api/content/categories`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.allTherapyCategory = res.items;
                this.updateLastMenus()
            }
        )
    }

    getHomeData() {
        this.homeDataService.getHomeData().then((res: any) => {
            this.homeData = res;
            this.updateLastMenus()
        }
        )
    }

    // 選擇的分頁
    curTab = 0

    // 選擇的標籤
    tabLabel = this.getLabel()

    /**
     * 選擇像幕後相關事件
     * @param tabIndex
     * @param value
     * @param closePopover
     * @param toRouter
     */
    handleChange({ tabIndex = undefined, value, closePopover = true }, toRouter = true) {
        if (tabIndex == undefined) {
            tabIndex = this.curTab;
        }
        this.curTab = tabIndex
        this.modelValue = value

        // 更新最後選單
        let lastMenus = this.updateLastMenus();

        // 通知外層
        this.change.emit({ lastMenus });

        // 關閉彈窗
        if (value && closePopover) this.popover?.hide()

        const queryParams = { curTab: this.selectedTab } as any

        // 選擇的分類 0: 熱搜診所 1: 地區查找 2: 療程查找 3:我的附近
        if (this.curTab == 0) {
            // TODO
        } else if (this.curTab == 1) {
            // TODO
            queryParams.selectedCountyName = this.selectedCountyName;
            queryParams.selectedDistrictName = this.selectedDistrictName;
        } else if (this.curTab == 2) {
            queryParams.categoryId = value;
        } else if (this.curTab == 3) {
            // TODO
        }

        // Router 導覽 組上去params
        if (toRouter) {
            this.router.navigate(['/content/clinic'], { queryParams })
        }
    }

    /**
     * 切換標籤
     * @param event
     */
    handleChangeTab(event) {
        this.curTab = event.tabId
    }

    /**
     * 取得標籤
     */
    getLabel() {
        // 選擇的分類 0: 熱搜診所 1: 地區查找 2: 療程查找 3:我的附近
        if (this.selectedTab == 0) {
            // TODO
            return this.hotClinic.find(item => item.id == this.modelValue)?.name
        } else if (this.selectedTab == 1) {
            // TODO
        } else if (this.selectedTab == 2) {
            return this.flattenTherapies.find(item => item.id == this.modelValue)?.name
        } else if (this.selectedTab == 3) {
            // TODO
        }
    }

    /**
     * 點擊觸發
     */
    handleTriggerClick() {
        setTimeout(() => {
            if (this.popover.isShown && this.layoutService.smallThanMd) {
                document.querySelector('.cdk-overlay-pane')?.classList.add('filter-menu-mobile')
            }
        });
    }

    // 緯度
    latitude: any;
    // 經度
    longitude: any;

    /**
     * 取得位置權限與GPS經緯度值
     */
    getLocation(): void {
        this.locationService.getPosition().then(
            (position) => {
                this.longitude = position.coords.longitude;
                this.latitude = position.coords.latitude;
                alert(`緯度: ${position.coords.latitude}, 經度: ${position.coords.longitude}`);
            },
            (error) => {
                // alert(`錯誤: ${error.message}`);
            }
        );
    }

}
