import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { Webconfig } from './../../models/webconfig.model';
import { Shareconfig } from './../..//models/shareconfig.model';

@Component({
    selector: 'app-favorite',
    templateUrl: './favorite.component.html',
    styleUrls: ['./favorite.component.scss']
})
export class FavoriteComponent implements OnInit {

    @Input() isFavorite;
    @Input() id;
    @Input() type;
    @Input() isLogin;
    @Input() displayType: 'button' | 'icon' = 'button'

    @Output() favoriteChange = new EventEmitter<any>(); //提供Output裝飾器，讓該事件成為父親模板的事件
    t_webconfig = Webconfig;
    t_shareconfig = Shareconfig;

    constructor(
        private httpService: HttpService
    ) { }

    ngOnInit(): void {
    }

    //會員加入收藏
    addFavorite() {
        /*
        let action = '1';
        if(this.isFavorite){
            action = '0';
        }*/

        let params = new FormData();
        params.append('type', this.type);
        params.append('_method', 'PUT');
        //params.append('action', action);

        let url = `${environment.APIUrl}/api/member-center/favorites/${this.id}`;
        this.httpService.postHttp(url, params).subscribe(
            res => {
                this.isFavorite = res.is_favorite;
                this.favoriteChange.emit(this.isFavorite);
            }
        )
    }

}
