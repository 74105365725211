import { Component, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router'; //接收route para
import { RouteList } from '../models/route.model';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { LayoutService } from '../../services/layout.service';
import { Breadcrumb } from '../../models/breadcrumb.model';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnChanges {

    // Json-LD
    // jsonLdData: string;

    @Input() category_id;
    @Input() name;
    @Input() page_route;

    list = [];
    routes = RouteList;
    datas;
    breadcrumb = Breadcrumb;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private httpService: HttpService,
        public layoutService: LayoutService,
    ) {
        let currentUrl = this.router.url;
        if (currentUrl !== '/auth/login/member' && currentUrl != '/auth/login/doctor' && currentUrl != '/auth/register?type=member' && currentUrl != '/auth/register?type=doctor') {
            localStorage.setItem('redirect_url', currentUrl);
        }
    }

    //如果input進來的參數改變, 重新取得
    ngOnChanges(changes: SimpleChanges) {
        if (changes.category_id) {
            this.list = [];
            this.category_id = changes.category_id.currentValue;

            if (this.datas)
                this.ngOnInit();
        }

        if (changes.name) {
            this.name = changes.name.currentValue;
            if (this.datas) {
                this.createList();
            }
        }

        // 在列表變化後更新 JSON-LD 數據
        this.createJsonLd();
    }

    createList() {
        this.list = [];

        this.datas.forEach(item => {
            switch (item) {
                //單一頁面換成標題名稱
                case "newsPage":
                case "therapyInfo":
                case "problemInfo":
                    item = {
                        title: this.name,
                        route: null,
                        model: false
                    };
                    this.list.push(item);
                    break;
                default:
                    let item_route = this.routes[item];

                    if (item_route) {
                        item = {
                            title: item,
                            route: item_route,
                            model: true
                        }
                    } else {
                        item = {
                            title: item,
                            route: null,
                            model: true
                        }
                    }

                    this.list.push(item);
                    break;
            }
        });
    }

    ngOnInit(): void {
        this.route.data.subscribe(data => {
            switch (this.page_route) {
                case '/content/service/contact/create':
                    data.permission = 'home-service-qaCreate';
                    break;

                case '/member/service/create':
                    data.permission = 'home-member-memberService-memberQa-qaCreate';
                    break;
            }

            if (!data.permission) {
                return;
            }

            this.datas = data.permission.split("-");
            this.createList();
        });

        // 初始化時建立 JSON-LD 數據
        this.createJsonLd();
    }

    //尋找商品類別階層
    productCategoryLevel() {
        if (!this.category_id) {
            return false;
        }

        let url = `${environment.APIUrl}/api/lab-shop/front/categories/${this.category_id}/parents`;
        this.httpService.getHttp(url).subscribe(
            res => {
                Object.keys(res.items).forEach(
                    key => {
                        this.list.push({
                            title: res.items[key].name,
                            route: '/products/' + res.items[key].code
                        })
                    }
                )
            }
        )
    }

    //尋找文章類別階層
    articleCategoryLevel(listType) {
        if (!this.category_id) {
            return false;
        }

        let url = `${environment.APIUrl}/api/lab-web-content/front/articles/categories/${this.category_id}/parents`;
        this.httpService.getHttp(url).subscribe(
            res => {
                Object.keys(res.items).forEach(
                    key => {
                        this.list.push({
                            title: res.items[key].name,
                            route: '/content/' + listType + '/' + res.items[key].id
                        })
                    }
                )
            }
        )
    }

    //尋找影音購物的類別
    videoCategory() {
        if (!this.category_id) {
            return false;
        }
        let url = `${environment.APIUrl}/api/lab-web-content/front/r-groups/${this.category_id}`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.list.push({
                    title: res.title,
                    route: null
                })
            }
        )
    }

    /**
     * 建立JsonLd的格式
     */
    createJsonLd() {
        const script = document.createElement('script');
        script.type = 'application/ld+json';

        let baseurl = 'https://worthit.com.tw';
        const jsonLdObject = {
            "@context": 'https://schema.org',
            "@type": "BreadcrumbList",
            "itemListElement": this.list.map((item, index) => {
                let itemUrl = item.route ? baseurl + (item.route === '/' ? '' : `/${item.route}`) : baseurl;
                return {
                    "@type": "ListItem",
                    "position": index + 1,
                    "name": item.title,
                    "item": itemUrl
                };
            })
        };
        script.innerHTML = JSON.stringify(jsonLdObject);
        document.head.appendChild(script);
    }

}
