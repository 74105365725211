import { Injectable } from '@angular/core';
import { Router } from '@angular/router'; //接收route para
import {
    HttpInterceptor,
    HttpHandler,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http'
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import swal from 'sweetalert2';
//import { TokenService } from '../services/token.service';
//import { CookieService } from '../services/cookie.service';
//import { HttpService } from '../services/http.service';
//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material'; //popup dialog
//import { LoginComponent } from '../auth/login/login.component';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";

@Injectable()
export class ResponseInterceptor implements HttpInterceptor {

    constructor(
        //private dialog:MatDialog,
        private router: Router,
        //private tokenService: TokenService,
        //private cookieService:CookieService,
        //private httpService:HttpService,
        private translate: TranslateService,
        private spinner: NgxSpinnerService,
    ) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        return next.handle(req).pipe(
            //進行response data處理
            //成功處理
            map(
                (event) => {
                    //如果event為http response class
                    if (event instanceof HttpResponse) {
                        //如果有回傳新token 進行token更新
                        if (event.headers.get('Authorization')) {
                            let new_token = event.headers.get('Authorization').split(" ");
                            localStorage.setItem('token', new_token[1]);
                        }

                        //有回傳資料的情況
                        if (event.body) {
                            let reg = RegExp(environment.APIUrlReg);

                            //1. 非系統API回傳格式 不處理 直接回傳結果
                            if (!reg.test(event.url)) {
                                //this.spinner.hide();
                                return event;
                            }

                            //2. 系統API respone 成功
                            if (event.body.status == true) {
                                //如果sucess code = success 只截取data內容 直接進行回傳結果前處理
                                if (event.body.data && event.body.success_code == 'SUCCESS') {
                                    let detail = event.body.data;
                                    event = event.clone({ body: detail });
                                }

                                //this.spinner.hide();
                                return event;

                                //3. 系統API respone 失敗
                            } else if (event.body.status == false) {
                                let err_info = event.body;
                                //錯誤處理
                                switch (err_info.err_code) {
                                    //直接導入登入頁
                                    case "TOKEN_INVALID":
                                        localStorage.removeItem('user_info');
                                        this.router.navigate(['/auth/login'], { queryParams: { url: this.router.url } });
                                        break;

                                    //跳錯誤且導入登入頁
                                    case "NO_LOGIN_PERMISSION":
                                        swal.fire({
                                            title: this.translate.instant('error.' + err_info.err_code),
                                            text: `error code: ${err_info.err_code}`,
                                            icon: 'error',
                                            confirmButtonText: 'YES'
                                        });
                                        localStorage.removeItem('user_info');
                                        this.router.navigate(['/auth/login']);
                                        break;

                                    //404導入404頁面或首頁
                                    case "DATA_NOT_FOUND":
                                        this.router.navigate(['/error/404']);
                                        break;

                                    //跳錯誤
                                    default:
                                        /*
                                            let error_msg = this.translate.instant('error.SERVER_ERROR') ;
                                            if (err_info.err_msg) {
                                                error_msg = this.translate.instant('error.'+err_info.err_code);
                                            }
                                        */
                                        swal.fire({
                                            title: err_info.err_msg,
                                            //text: `error code: ${err_info.err_code}`,
                                            icon: 'error',
                                            confirmButtonText: 'YES'
                                        });
                                        break;
                                }

                                // 以错误的形式结束本次请求
                                this.spinner.hide();
                                return Observable.create(observer => observer.error(event));
                            }
                        }
                    }
                    return event;
                })
        );
    }

}
