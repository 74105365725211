import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { map, switchMap, take, tap } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    isAllRead$ = new BehaviorSubject<number>(0);

    constructor(
        private httpService: HttpService,
    ) { }

    getHaveUnreadCount() {
        const url = `${environment.APIUrl}/api/member-center/event-notifications/unread-count`;
        return this.httpService.getHttp(url).pipe(
            map((res) => {
                const count = parseInt(res.count);
                if (this.isAllRead$.getValue() !== count) {
                    this.isAllRead$.next(count)
                }
                return count > 0;
            }),
            take(1)
        );
    }

    viewNotification(id) {
        const url = `${environment.APIUrl}/api/member-center/event-notifications/${id}`;
        this.httpService.putHttp(url, {}).pipe(
            switchMap(() => this.getHaveUnreadCount()),
            take(1)
        ).subscribe();
    }

    allRead() {
        const url = `${environment.APIUrl}/api/member-center/event-notifications`;
        this.httpService.putHttp(url, {}).pipe(
            tap(() => this.isAllRead$.next(0)),
            take(1)
        ).subscribe();
    }

}
