<span class="filter-trigger" [nbPopover]="tabs" nbButton nbPopoverPlacement="bottom" nbPopoverOffset="10" [nbPopoverAdjustment]="isMobile ? 'clockwise': 'clockwise'" nbPopoverTrigger="click" (click)="handleTriggerClick()">
    {{ tabLabel }}
    <img src="/assets/images/icon/arrow_up.svg" alt="" srcset="" [ngClass]="{'arrow-down': !!(popover && popover.isShown)}">
</span>
<ng-template #tabs>
    <nb-card class="filter">
        <nb-tabset (changeTab)="handleChangeTab($event)">
            <nb-tab tabTitle="熱搜診所" [tabId]="0">
                <ul *ngIf="hotClinic" class="filter__list mt-3">
                    <li class="filter__item" *ngFor="let item of hotClinic" (click)="selectModelValue(item.id)" [ngClass]="{'active': selectedTab == 0 && modelValue == item.id}">
                        {{ item.name }}
                    </li>
                </ul>
            </nb-tab>
            <nb-tab tabTitle="地區查找" [tabId]="1">
                <div>
                    <div class="filter__title">
                        <div>請選擇縣市</div>
                    </div>
                    <div>
                        <ul class="filter__list">
                            <li class="filter__item" *ngFor="let county of countiesList" [value]="county.id" (click)="selectCounty(county.id, county.name)" [ngClass]="{'active': selectedTab == 1 && modelValue == county.id}">
                                {{ county.name }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div>
                    <div class="filter__title">
                        <div>請選擇地區</div>
                    </div>
                    <div *ngIf="selectedCountyId">
                        <ul class="filter__list">
                            <li class="filter__item" *ngFor="let district of districtsList" [value]="district.id" (click)="selectDistrict(district.id, district.name)" [ngClass]="{'active': selectedTab == 1 && modelValue == district.id}">
                                {{ district.name }}
                            </li>
                        </ul>
                    </div>
                </div>
            </nb-tab>
            <nb-tab tabTitle="療程查找" [tabId]="2">
                <div>
                    <nb-accordion multi>
                        <nb-accordion-item *ngFor="let cate of allTherapyCategory">
                            <nb-accordion-item-header>
                                {{ cate.name }}
                            </nb-accordion-item-header>
                            <nb-accordion-item-body>
                                <div>
                                    <ul class="filter__list">
                                        <li class="filter__item" *ngFor="let item of cate.children" (click)="selectModelValue(item.id)" [ngClass]="{'active': selectedTab == 2 && modelValue == item.id}">
                                            {{ item.name }}
                                        </li>
                                    </ul>
                                </div>
                            </nb-accordion-item-body>
                        </nb-accordion-item>
                    </nb-accordion>
                </div>
            </nb-tab>
            <nb-tab tabTitle="我的附近" [tabId]="3">
                <div>
                    <div class="filter__title">
                        <div></div>
                    </div>
                    <ul class="filter__listGps">
                        <li class="filter__itemGps" id="getLocationBtn" (click)="getLocation()">
                            開啟定位以獲得更準確的診所位置
                        </li>
                    </ul>
                </div>
            </nb-tab>
        </nb-tabset>
    </nb-card>
</ng-template>
