export const Problem: any = [
	{ id:1, name:"眉眼", image:""},
	{ id:2, name:"鼻子", image:"" },
	{ id:3, name:"嘴", image:"" },
	{ id:4, name:"下巴", image:"" },
	{ id:5, name:"頸部", image:"" },
	{ id:6, name:"臉", image:"" },
	{ id:7, name:"毛髮", image:"" },
	{ id:8, name:"胸", image:"" },
	{ id:9, name:"腰腹部", image:"" },
	{ id:10, name:"臀", image:""},
	{ id:11, name:"腿", image:""},
	{ id:12, name:"手", image:""},
	{ id:13, name:"背部", image:""},
	{ id:14, name:"私密處", image:""},
	{ id:15, name:"其他", image:"", noImage: true},
];