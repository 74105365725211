import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Therapy } from '../../models/therapy.model';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';

@Component({
    selector: 'app-therapy-select',
    templateUrl: './therapy-select.component.html',
    styleUrls: ['./therapy-select.component.scss']
})
export class TherapySelectComponent implements OnInit {

    @Output() therapySelect = new EventEmitter<any>();

    outputData(data) {
        this.therapySelect.emit(data);
    }

    categories = Therapy;
    category_item;

    second_category_item;
    second_categories;

    last_categories;
    last_category_item;

    therapies;
    therapy_id;

    constructor(
        private httpService: HttpService
    ) { }

    ngOnInit(): void {
    }

    //寫死類別尋找下一層類別
    openSubMenu(root_category) {
        //第一層手術 找寫死第二層
        if (root_category.children) {
            this.second_categories = root_category.children;
            this.last_categories = null;

            //其他第一層 找自定義的最後一層
        } else {
            this.second_categories = null;
            this.second_category_item = null;
            this.getTherapyCategory(root_category);
        }

        //取消療程項目
        this.therapies = null;
    }

    //找自定義的最後一層
    getTherapyCategory(category) {
        let url = `${environment.APIUrl}/api/content/categories?parent_id=${category.id}`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.last_categories = res.items;
                //取消療程項目
                this.last_category_item = null;
                this.therapies = null;
            }
        )
    }

    //找療程
    getTherapy(category) {
        let url = `${environment.APIUrl}/api/content/therapies?category_id=${category.id}&per_page=999`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.therapies = res.therapy.items;
                //取消先前選擇的療程
                this.therapy_id = null;
            }
        )
    }

}
