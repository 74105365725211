<!-- 療程 -->
<div class="card-list" *ngIf="type=='therapy'">
    <a [routerLink]="['/therapy/info', item.id]">
        <h5 class="title">{{ item.en_name }} {{ item.name }}</h5>
        <p><b>值得指數</b><br><span *ngIf="item.worth_ratio">{{ item.worth_ratio }}%</span></p>
        <p><b>療程分享數</b><br>{{ item.member_experience_count }}則</p>
        <p><b>平均消費金額</b><br>{{ item.avg_cost }}元</p>
    </a>
</div>

<div class="list-item-therapy" *ngIf="type=='therapy2'">
    <a [routerLink]="['/therapy/info', item.id]" class="therapy">
        <div class="therapy__img">
            <img alt="" [src]="item.image | webp:230" style="height:100%;width:100%" />
        </div>
        <div class="therapy__body">
            <div class="therapy__name">
                <div>{{ item.name }}</div>
                <div style="font-size: 14px; margin-top: 2px;">{{ item.en_name }}</div>
            </div>
            <table class="therapy__info">
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/thumb.svg" alt="" srcset="">
                    </td>
                    <!-- 評分 -->
                    <td class="info-item__content" colspan="2">
                        <app-rating [showScore]="true" [ratingRatio]="item.worth_ratio"></app-rating>
                    </td>
                </tr>
                <!-- 價錢 -->
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/dollar.svg" alt="" srcset="">
                    </td>
                    <td class="info-item__content" colspan="2">{{item.avg_cost}}元~{{item.max_cost}}元</td>
                </tr>
                <!-- 評價數量 -->
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/stamp.svg" alt="" srcset="">
                    </td>
                    <td class="info-item__content">{{item.member_experience_count}} 則</td>
                    <td class="info-item__content">(療程評價)</td>
                </tr>
                <!-- 醫師數量 -->
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/hand.svg" style="width: 14px; height: 14px;" alt="" srcset="">
                    </td>
                    <td class="info-item__content">{{item.doctor_count}} 位醫師</td>
                    <td class="info-item__content">(提供本療程)</td>
                </tr>
                <!-- 認證醫師 -->
                <tr class="info-item">
                    <td class="info-item__icon">
                        <img src="/assets/images/icon/verify.svg" alt="" srcset="">
                    </td>
                    <td class="info-item__content">{{item.revise_doctor_name}}醫師</td>
                    <td class="info-item__content">(校閱)</td>
                </tr>
            </table>
        </div>
    </a>
</div>

<!-- 問題 -->
<div class="card-list" *ngIf="type=='problem'">
    <a [routerLink]="['/problem/info', item.id]">
        <h5 class="title">{{ item.name }} {{ item.en_name }}</h5>
        <p><b>解決方案數</b><br>{{ item.therapy_count }}種</p>
        <p><b>滿意度</b><br><span *ngIf="item.satisfaction">{{ item.satisfaction }}%</span></p>
    </a>
</div>

<!-- 療程: 疗程页列表样式 -->
<div class="list-item-therapy2" *ngIf="type=='problem2'">
    <a [routerLink]="['/problem/info', item.id]" class="therapy">
        <div class="therapy__img">
            <img alt="" [src]="item.image | webp:250" style="height:100%;width:100%">
        </div>
        <div class="therapy__body">
            <div class="therapy__name">
                <div>{{ item.name }}</div>
                <div *ngIf="item.en_name">{{ item.en_name }}</div>
            </div>
            <div class="therapy__info">
                <!-- 評價數量 -->
                <div class="info-item">
                    <div class="info-item__icon">
                        <img src="/assets/images/icon/hand.svg" alt="" srcset="">
                    </div>
                    <div class="info-item__content">{{item.therapy_count}} 種(解決方案)</div>
                </div>
                <!-- 認證醫師 -->
                <div class="info-item" style="margin-bottom: 10px;">
                    <div class="info-item__icon">
                        <img src="/assets/images/icon/verify.svg" alt="" srcset="">
                    </div>
                    <div class="info-item__content">{{item.doctor_name}}醫師(校閱)</div>
                </div>
            </div>
        </div>
    </a>
</div>

<!-- 療程: 疗程页列表样式 -->
<div class="list-item-therapy2" *ngIf="type=='therapy3'">
    <a [routerLink]="['/therapy/info', item.id]" class="therapy">
        <div class="therapy__img">
            <img alt="" [src]="item.image | webp:250" style="height:100%;width:100%">
        </div>
        <div class="therapy__body">
            <div class="therapy__name">
                <div>{{ item.name }}</div>
                <div *ngIf="item.en_name">{{ item.en_name }}</div>
            </div>
            <div class="therapy__info">
                <div class="info-item">
                    <div class="info-item__icon">
                        <img src="/assets/images/icon/thumb.svg" alt="" srcset="">
                    </div>
                    <!-- 評分 -->
                    <div class="info-item__content">
                        <app-rating [showScore]="true" [ratingRatio]="item.worth_ratio"></app-rating>
                    </div>
                </div>
                <!-- 價錢 -->
                <div class="info-item">
                    <div class="info-item__icon">
                        <img src="/assets/images/icon/dollar.svg" alt="" srcset="">
                    </div>
                    <div class="info-item__content">{{item.avg_cost}}元~{{item.max_cost}}元</div>
                </div>
                <!-- 評價數量 -->
                <div class="info-item">
                    <div class="info-item__icon">
                        <img src="/assets/images/icon/stamp.svg" alt="" srcset="">
                    </div>
                    <div class="info-item__content">{{item.member_experience_count}} 則(療程分享)</div>
                </div>
                <!-- 評價數量 -->
                <div class="info-item" *ngIf="!layoutService.smallThanMd">
                    <div class="info-item__icon">
                        <img src="/assets/images/icon/hand.svg" alt="" srcset="">
                    </div>
                    <div class="info-item__content">{{item.doctor_count}} 位醫師(提供本療程)</div>
                </div>
            </div>
        </div>
    </a>
</div>

<!-- 療程經驗與分享 -->
<div class="card-es" *ngIf="type=='es' || type=='member_es'">
    <div class="header">
        <!-- 療程經驗分享才有的欄位 -->
        <ng-container *ngIf="item.user">
            <ng-container *ngIf="item.user.role!='doctor'">
                <div *ngIf="item.worth==1" class="badge badge-danger">值得</div>
                <div *ngIf="item.worth==-1" class="badge badge-dark">不值得</div>
                <div *ngIf="item.worth==0" class="badge badge-border">不確定</div>
            </ng-container>
            <div *ngIf="item.user.role=='doctor'" class="badge badge-border">醫師分享</div>
        </ng-container>
        <!-- 會員中心才有的修改刪除按鈕 -->
        <div *ngIf="type=='member_es'" class="ms-auto">
            <a class="btn btn-sm py-0" [routerLink]="['./edit', item.id]"><nb-icon icon="edit" style="top: 0;"></nb-icon></a>
            <button class="btn btn-sm py-0 pe-0" [swal]="deleteSwal" (confirm)="deleteItem(item.id)"><nb-icon icon="trash"></nb-icon></button>
        </div>
    </div>
    <div class="photo">
        <img *ngIf="item.main_after_photo" [src]="filepath+fileService.getThumbnail(item.main_after_photo,'xs')">
    </div>
    <!-- 發文者資訊 -->
    <div class="user">
        <div class="row gx-3">
            <div class="col-auto">
                <div class="avatar avatar-md">
                    <img *ngIf="item.user.avatar" [src]="filepath+fileService.getThumbnail(item.user.avatar,'xs')">
                </div>
            </div>
            <div class="col d-flex">
                <div class="info">
                    <b class="name">{{ item.user.name }}</b>
                    <span class="auth" *ngIf="item.user.sub_role && item.user.sub_role!='民眾' && item.user.sub_role!='未認證醫師'">
                        <ng-container *ngIf="item.user.sub_role == '認證診所'; else other">
                            <img class="clinic-icon" src="/assets/images/certified_clinic.png" alt="">
                        </ng-container>
                        <ng-template #other>
                            <nb-icon icon="checkmark-circle-2" class="me-1"></nb-icon>
                        </ng-template>
                        {{ item.user.sub_role }}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- 發文內容 -->
    <h5>{{ item.title }}</h5>
    <p class="content" [innerHtml]="item.content"></p>
    <p class="ago">{{ item.ago }}</p>
    <div class="stats">
        <span class="icon">
            <nb-icon icon="eye"></nb-icon>{{ item.viewed }}
        </span>
        <span class="icon">
            <nb-icon icon="message-square-outline"></nb-icon>{{ item.reply_count }}
        </span>
    </div>
    <a *ngIf="item.type=='doctor-case'" [routerLink]="['/experience-share/ba/info',item.id]"></a>
    <a *ngIf="item.type!='doctor-case'" [routerLink]="['/experience-share/member/info',item.id]"></a>
</div>

<!-- 術前術後 -->
<div class="card-before-after" *ngIf="type=='ba'">
    <a (click)="openBaDialog(dialog)">
        <span class="cover">
            <img *ngIf="item.before_photo" [src]="filepath+fileService.getThumbnail(item.before_photo,'xs')">
        </span>
        <span class="cover">
            <img *ngIf="item.main_after_photo" [src]="filepath+fileService.getThumbnail(item.main_after_photo,'xs')">
        </span>
    </a>
</div>

<!-- 討論區 -->
<div class="list-item-forum" *ngIf="type=='forum' || type=='forum_top' || type=='member_forum'">
    <a [routerLink]="['/experience-share/forum/info',item.id]">
        <div class="flex items-center">
            <div class="d-flex flex-column flex-1 overflow-hidden">
                <div class="header">
                    <div>
                        <span class="body-part-tag">
                            {{getBodyPartName(item.body_part)}}
                        </span>
                        <span class="h5 title mt-2 mb-3">{{ item.title }}</span>
                    </div>
                    <!-- 會員中心才有的按鈕 -->
                    <div *ngIf=" type=='member_forum'" class="edit">
                        <button class="btn btn-sm py-0" (click)="router.navigate(['/member/forum_module/forum-edit', item.id])"><nb-icon icon="edit" style="top: 0;"></nb-icon></button>
                        <button class="btn btn-sm py-0 pe-0" [swal]="deleteSwal" (confirm)="deleteItem(item.id)"><nb-icon icon="trash"></nb-icon></button>
                    </div>
                </div>
                <div class="body">
                    <p class="content" [innerHtml]="item.content">content</p>
                </div>
                <div class="top" *ngIf="type=='forum_top'">
                    [置頂]
                </div>
                <div class="footer">
                    <div class="icon">
                        <img src="/assets/images/icon/comment.svg" alt="">{{ item.reply_count }}
                    </div>
                    <div class="icon">
                        <img src="/assets/images/icon/eye.svg" alt="">{{ item.viewed }}
                    </div>
                    <!-- 蒐藏按鈕 -->
                    <div class="icon">
                        <app-favorite-lite [isFavorite]="item.is_favorite" [id]="item.id" type="issues" text="收藏"></app-favorite-lite>
                    </div>
                </div>
            </div>
            <div class="photo" *ngIf="item.photos">
                <img alt="" [src]="item.photos | webp:86" />
            </div>
        </div>
    </a>
</div>

<!-- 醫生 -->
<div class="card-doctor" *ngIf="type=='doctor'">
    <a [routerLink]="['./',item.id,'desc']">
        <div class="title">
            <span class="text-light"> | </span> {{ item.name }} 醫師 <span class="text-light"> | </span>
            <ng-container *ngIf="item.if_show_dc == 1">
                <ng-container *ngFor="let division of item.division;let index = index">
                    <span *ngIf="divisionShow(index)">
                        {{ getSpecialty(division) }}<span *ngIf="getSpecialty(division)">醫師</span>
                        <span *ngIf="getSpecialty(division)" class="text-light"> | </span>
                    </span>
                </ng-container>
            </ng-container>
        </div>
        <div class="row gx-3">
            <div class="col-5">
                <div class="image">
                    <img *ngIf="item.avatar" [src]="filepath+fileService.getThumbnail(item.avatar,'xs')">
                </div>
            </div>
            <div class="col-7">
                <ul class="icon-list">
                    <li>
                        <img src="/assets/images/icon/person.svg" alt="">
                        分享案例數 {{ item.case_count }}則
                    </li>
                    <li>
                        <img src="/assets/images/icon/article_black_24dp.svg" alt="">
                        分享文章數 {{ item.article_count }}則
                    </li>
                    <li>
                        <img src="/assets/images/icon/favorite-24px.svg" alt="">
                        追蹤人數 {{ item.favorite_count }}人
                    </li>
                    <li>
                        <img src="/assets/images/icon/question_answer_black_24dp.svg" alt="">
                        回覆文章數 {{ item.answer_count }}則
                    </li>
                </ul>
            </div>
        </div>
        <div class="footer">
            <div class="text">擅長療程</div>
            <div class="desc mb-2">
                <span *ngFor="let therapy of item.therapies; last as isLast">{{ therapy.name }} {{ therapy.en_name
                    }}<ng-container *ngIf="!isLast">、</ng-container></span>
            </div>
            <div class="text">擅長解決問題</div>
            <div class="desc">
                <span *ngFor="let problem of item.problems; last as isLast">{{ problem.name }} {{ problem.en_name
                    }}<ng-container *ngIf="!isLast">、</ng-container></span>
            </div>
        </div>
    </a>
</div>

<div class="card-doctor2" *ngIf="type=='doctor2'">
    <a [routerLink]="['/content/doctor/',item.id,'desc']">
        <div class="card-header">
            <div class="profile">
                <div class="doctor-avatar" style="position: relative;">
                    <img alt="" [lazyLoad]="item.avatar | webp:100" *ngIf="item.avatar" />
                    <span class="auth-icon">
                        <nb-icon style="color: #56A9DD" icon="checkmark-circle-2" class="me-1"></nb-icon>
                    </span>
                </div>
                <div class="doctor-info">
                    <span class="display-flex">
                        <div class="doctor-name  text-truncate">{{item.name}} 醫師</div>
                        <div class="doctor-clinic text-truncate">{{item.service?.[0]?.company || ''}}</div>
                    </span>
                    <div class="doctor-title"><i class="doctor-verify-icon"></i><span class="text-truncate">
                            <ng-container *ngIf="item.if_show_dc == 1">
                                <ng-container *ngFor="let division of item.division;let index = index">
                                    <span *ngIf="divisionShow(index)">
                                        {{ getSpecialty(division) }}<span *ngIf="getSpecialty(division)">醫師 </span>
                                    </span>
                                </ng-container>
                            </ng-container>
                        </span></div>
                    <div class="doctor-stat">
                        <div class="doctor-stat__item">
                            <div>{{item.favorite_count}}</div>
                            <div>追蹤</div>
                        </div>
                        <div class="doctor-stat__item">
                            <div>{{item.case_count}}</div>
                            <div>案例</div>
                        </div>
                        <div class="doctor-stat__item">
                            <div>{{item.article_count}}</div>
                            <div>文章</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="consult">
                <span class="text-truncate">查看更多</span>
            </div>
        </div>
        <div class="card-content">
            <div class="content-item">
                <div class="content-title">擅長療程</div>
                <div class="content-tags">
                    <div class="content-tag" *ngFor="let therapy of item.therapies">
                        {{ therapy.name }}
                    </div>
                </div>
            </div>
            <div class="content-item">
                <div class="content-title">擅長解決問題</div>
                <div class="content-tags">
                    <div class="content-tag" *ngFor="let problem of item.problems">
                        {{ problem.name }}
                    </div>
                </div>
            </div>
        </div>
    </a>
</div>

<!-- 找医生主页列表项 -->
<div class="card-doctor3" *ngIf="type=='doctor3'">
    <a [routerLink]="['/content/doctor/',item.id,'desc']">
        <div class="doctor-avatar">
            <img alt="" [src]="item.avatar | webp:98" *ngIf="item.avatar" />
        </div>
        <div class="doctor-info">
            <div class="flex items-center justify-between">
                <span class="flex items-center">
                    <div class="doctor-name">{{item.name}} 醫師</div>
                    <img src="/assets/images/icon/checked_fill.svg" width="15" alt="" srcset="" style="margin-left: 10px; width: 15px !important; height: 15px !important;">
                </span>
                <app-favorite-lite [isFavorite]="item.is_favorite" [id]="item.id" type="doctors" style="margin-right: 4px;"></app-favorite-lite>
            </div>
            <div class="doctor-title">
                <span class="text-truncate">{{item.service?.[0]?.company || ''}}</span>
                <div class="doctor-title__divider" *ngIf="item.if_show_dc == 1 && item.division.length"></div>
                <ng-container *ngIf="item.if_show_dc == 1">
                    <ng-container *ngFor="let division of item.division;let index = index">
                        <span *ngIf="divisionShow(index)" class="text-truncate">
                            {{ getSpecialty(division) }}<span *ngIf="getSpecialty(division)">醫師</span>
                        </span>
                        <div *ngIf="getSpecialty(division)" class="doctor-title__divider"></div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="doctor-stat">
                <div class="doctor-stat__item">
                    <div style="margin-right: 4px;">{{item.favorite_count}}</div>
                    <div>追蹤</div>
                </div>
                <div class="doctor-stat__item">
                    <div style="margin-right: 4px;">{{item.case_count}}</div>
                    <div>案例</div>
                </div>
                <div class="doctor-stat__item">
                    <div style="margin-right: 4px;">{{item.article_count}}</div>
                    <div>文章</div>
                </div>
                <div class="doctor-stat__item">
                    <div style="margin-right: 4px;">{{item.answer_count}}</div>
                    <div>回覆</div>
                </div>
            </div>
            <div class="doctor-tags">
                <div class="doctor-tag" *ngFor="let therapy of item.therapies">
                    {{ therapy.name }}
                </div>
            </div>
        </div>
    </a>
</div>

<div class="card-doctor3 mt-0" style="padding-top: 10px;" *ngIf="type=='doctor4'">
    <a [routerLink]="['/content/doctor/',item.id,'desc']">
        <div class="doctor-avatar" style="position:relative">
            <img alt="" [src]="item.avatar | webp:100" *ngIf="item.avatar" />
            <img alt="" src="/assets/images/icon/good.svg" style="position:absolute;bottom:5px;right:7px;width:19px;height:19px" *ngIf="item.good" />
        </div>
        <div class="doctor-info">
            <div class="flex items-center justify-between">
                <span class="flex items-center">
                    <div class="doctor-name">{{item.name}} 醫師</div>
                    <img src="/assets/images/icon/checked_fill.svg" width="15" alt="" srcset="" style="margin-left: 10px; width: 15px !important; height: 15px !important;">
                </span>
                <app-favorite-lite [isFavorite]="item.is_favorite" [id]="item.id" type="doctors" style="margin-right: 4px;"></app-favorite-lite>
            </div>
            <div class="doctor-title">
                <span class="text-truncate">{{item.service?.[0]?.company || ''}}</span>
                <div class="doctor-title__divider" *ngIf="item.if_show_dc == 1 && item.division.length"></div>
                <ng-container *ngIf="item.if_show_dc == 1">
                    <ng-container *ngFor="let division of item.division;let index = index">
                        <span *ngIf="divisionShow(index)" class="text-truncate">
                            {{ getSpecialty(division) }}<span *ngIf="getSpecialty(division)">醫師</span>
                        </span>
                        <div *ngIf="getSpecialty(division)" class="doctor-title__divider"></div>
                    </ng-container>
                </ng-container>
            </div>
            <div class="doctor-stat">
                <div class="doctor-stat__item">
                    <div style="margin-right: 4px;">{{item.favorite_count}}</div>
                    <div>追蹤</div>
                </div>
                <div class="doctor-stat__item">
                    <div style="margin-right: 4px;">{{item.case_count}}</div>
                    <div>案例</div>
                </div>
                <div class="doctor-stat__item">
                    <div style="margin-right: 4px;">{{item.article_count}}</div>
                    <div>文章</div>
                </div>
                <div class="doctor-stat__item">
                    <div style="margin-right: 4px;">{{item.answer_count}}</div>
                    <div>回覆</div>
                </div>
            </div>
            <div class="doctor-tags">
                <div class="doctor-tag" *ngFor="let therapy of item.therapies">
                    {{ therapy.name }}
                </div>
            </div>
        </div>
    </a>
</div>

<!-- 醫師案例分享 -->
<div class="card-case" *ngIf="type=='doctor-case' || type=='member_doctor_case' || type=='member_doctor_article_case'">
    <!-- 會員中心才有 -->
    <div class="edit" *ngIf="type=='member_doctor_case' || type=='member_doctor_article_case'">
        <button class="btn btn-sm py-0" [routerLink]="['./edit', item.id]"><nb-icon icon="edit"></nb-icon></button>
        <button class="btn btn-sm py-0 pe-0" [swal]="deleteSwal" (confirm)="deleteItem(item.id)"><nb-icon icon="trash"></nb-icon></button>
    </div>
    <a [routerLink]="[type=='member_doctor_article_case' ? '/content/news/doctor-am' : '/experience-share/ba/info', item.id]">
        <div class="header">
            <h5 class="title">{{ item.title }}</h5>
        </div>
        <div class="body">
            <div class="info">
                <div class="photo">
                    <div class="card-before-after d-flex">
                        <div class="cover" *ngIf="item.before_photo">
                            <img [src]="filepath+fileService.getThumbnail(item.before_photo,'xs')">
                        </div>
                        <div class="cover" *ngIf="item.main_after_photo">
                            <img [src]="filepath+fileService.getThumbnail(item.main_after_photo,'xs')">
                        </div>
                        <div class="cover" *ngIf="item.image">
                            <img [src]="filepath+fileService.getThumbnail(item.image,'xs')">
                        </div>
                    </div>
                </div>
                <div class="stats">
                    <ng-container *ngIf="type!='member_doctor_article_case'">
                        <div class="label">療程名稱</div>
                        <p *ngIf="item.main_therapy">{{ item.main_therapy.name }} {{ item.main_therapy.en_name }}</p>
                        <div class="label">留言數</div>
                        <p>{{ item.reply_count }}則</p>
                    </ng-container>
                    <div class="label">觀看數</div>
                    <p>{{ item.viewed }}</p>
                    <div class="label">蒐藏數</div>
                    <p class="mb-0">{{ item.favorite_count }}</p>
                </div>
            </div>
            <p class="content" [innerHTML]="item.content"></p>
        </div>
    </a>
</div>

<!-- 文章
    news:一般最新消息
    writer-am:小編管理的醫美情報
    doctor-am:醫生管理的醫美情報
    therapy-am:療程內的最新消息
-->
<div class="article-item" *ngIf="type=='article'">
    <a [routerLink]="getArticleRoute(item.id)">
        <div class="row">
            <div class="col-md-5 col-image pb-3 py-md-4 pe-md-5">
                <div class="image">
                    <img *ngIf="item.image" [src]="filepath+fileService.getThumbnail(item.image,'xs')">
                </div>
            </div>
            <div class="col-md-7 col-content ps-md-5">
                <div class="w-100">
                    <b class="date">{{ item.post_date }}</b>
                    <h4 class="mt-1 mb-2 my-md-3">{{ item.title }}</h4>
                    <h6 class="desc mb-2 mb-md-3">{{ item.content }}</h6>
                    <h6 class="text-end text-md-start mb-0">進入<nb-icon icon="arrow-forward"></nb-icon></h6>
                </div>
            </div>
        </div>
    </a>
</div>

<div class="article" style="padding: 0px 5px;" *ngIf="type=='article2'">
    <a [routerLink]="getArticleRoute(item.id)">
        <div class="image">
            <img alt="" [lazyLoad]="item.image | webp:380" />
            <div class="article__tag" *ngIf="item.type === 'writer-am'">診所新訊</div>
            <div class="article__tag" *ngIf="item.type === 'therapy-am'">療程新訊</div>
            <div class="article__tag" *ngIf="item.type === 'doctor-am'">醫師專欄</div>
        </div>
        <span class="h4 article__title">{{ item.title }}</span>
        <p class="article__content">{{item.content}}</p>
        <p class="article__author">
            {{item.writer?.name || item.writer_nickname || item.writer_name || "醫美圈圈"}}
            {{ item.type === 'doctor-am' || item.writer?.role === 'doctor' ? '醫師' : '' }}
        </p>
        <div class="article__footer">
            <div class="flex items-center">{{item.post_date.split(' ')[0]}}</div>
            <div class="flex items-center">
                <div class="flex items-center"><img src="/assets/images/icon/eye.svg" alt="" style="margin-right: 4px;">{{item.viewed || 0}}</div>
                <div class="flex items-center" style=" margin-left: 5px;">
                    <app-favorite-lite [isFavorite]="item.is_favorite" [id]="item.id" type="articles" style="margin-right: 4px;" text="收藏"></app-favorite-lite>
                </div>
            </div>
        </div>
    </a>
</div>

<div class="article" *ngIf="type=='article3'">
    <a [routerLink]="getArticleRoute(item.id)">
        <div style="display: flex;">
            <div style="width: 50%;">
                <div class="image">
                    <img alt="" [lazyLoad]="item.image | webp:218" />
                    <div class="article__tag" *ngIf="item.type === 'writer-am'">診所新訊</div>
                    <div class="article__tag" *ngIf="item.type === 'therapy-am'">療程新訊</div>
                    <div class="article__tag" *ngIf="item.type === 'doctor-am'">醫師專欄</div>
                </div>
            </div>
            <div style="width: 50%; padding-left: 10px;">
                <span class="h4 article__title">{{ item.title }}</span>
                <p class="article__content">{{item.content}}</p>
                <p class="article__author">
                    {{item.writer?.name || item.writer_nickname || item.writer_name || "醫美圈圈"}}
                    {{ item.type === 'doctor-am' || item.writer?.role === 'doctor' ? '醫師' : '' }}
                </p>
            </div>
        </div>
        <div class="article__footer">
            <div class="flex items-center">{{item.post_date.split(' ')[0]}}</div>
            <div class="flex items-center">
                <div class="flex items-center"><img src="/assets/images/icon/eye.svg" alt="" style="margin-right: 4px;">{{item.viewed || 0}}</div>
                <div class="flex items-center" style=" margin-left: 5px;">
                    <app-favorite-lite [isFavorite]="item.is_favorite" [id]="item.id" type="articles" style="margin-right: 4px;" text="收藏"></app-favorite-lite>
                </div>
            </div>
        </div>
    </a>
</div>

<!-- doctor-schedule:學術行事曆 -->
<div class="card-forum" *ngIf="type=='schedule'">
    <a [routerLink]="['./', item.id]">
        <div class="header">
            <h5 class="title">{{ item.title }}</h5>
            <span class="date">{{ item.post_date }}</span>
        </div>
        <div class="body">
            <p class="content">{{ item.content }}</p>
        </div>
    </a>
</div>

<!-- 術前術後dialog -->
<ng-template #dialog let-data let-ref="dialogRef">
    <nb-card class="nb-dialog nb-dialog-lg">
        <nb-card-header>
            <div class="row">
                <div class="col">
                    <h5>{{ data.title }}</h5>
                </div>
                <div class="col-auto">
                    <button class="close" (click)="ref.close()"><nb-icon icon="close"></nb-icon></button>
                </div>
            </div>
        </nb-card-header>
        <nb-card-body>
            <div class="user-info mb-2 mb-md-3" [ngClass]="{'pointer' : data.user.role=='doctor'}" (click)="gotoDoctor(data,ref)">
                <div class="row gx-3">
                    <div class="col-auto">
                        <div class="avatar avatar-md">
                            <img *ngIf="data.user.avatar" [src]="filepath+fileService.getThumbnail(data.user.avatar,'xs')">
                        </div>
                    </div>
                    <div class="col info">
                        <!-- 作者 -->
                        {{ data.user.name }}<br>
                        <span class="auth" *ngIf="item.user.sub_role && item.user.sub_role!='民眾' && item.user.sub_role!='未認證醫師'">
                            <ng-container *ngIf="item.user.sub_role == '認證診所'; else other">
                                <img class="clinic-icon" src="/assets/images/certified_clinic.png" alt="">
                            </ng-container>
                            <ng-template #other>
                                <nb-icon icon="checkmark-circle-2" class="me-1"></nb-icon>
                            </ng-template>
                            {{ item.user.sub_role }}
                        </span>
                        <!-- 發文時間 -->
                        分享時間 {{ data.created_at }}<br>
                    </div>
                </div>
            </div>
            <!-- 部分內文 -->
            <p class="content" [innerHtml]="data.content"></p>
            <div class="card-before-after">
                <a *ngIf="item.type!='doctor-case'" [routerLink]="['/experience-share/ba/info',item.id]" (click)="ref.close()">
                    <span class="cover">
                        <img *ngIf="item.before_photo" [src]="filepath+fileService.getThumbnail(item.before_photo,'xs')">
                    </span>
                    <span class="cover">
                        <img *ngIf="item.main_after_photo" [src]="filepath+fileService.getThumbnail(item.main_after_photo,'xs')">
                    </span>
                </a>
                <a *ngIf="item.type=='doctor-case'" [routerLink]="['/experience-share/ba/info',item.id]" (click)="ref.close()">
                    <span class="cover">
                        <img *ngIf="item.before_photo" [src]="filepath+fileService.getThumbnail(item.before_photo,'xs')">
                    </span>
                    <span class="cover">
                        <img *ngIf="item.main_after_photo" [src]="filepath+fileService.getThumbnail(item.main_after_photo,'xs')">
                    </span>
                </a>
            </div>
        </nb-card-body>
        <nb-card-footer>
            <app-label-tip [cloumn]="'tip21'"></app-label-tip>
            <div class="stats">
                <span class="icon">
                    <nb-icon icon="eye"></nb-icon>{{ data.viewed }}
                </span>
                <span class="icon">
                    <nb-icon icon="message-square-outline"></nb-icon>{{ data.reply_count }}
                </span>
            </div>
        </nb-card-footer>
    </nb-card>
</ng-template>

<!-- 通知 -->
<div class="card-forum" *ngIf="type === 'notification'">
    <ng-container [ngSwitch]="item.code">
        <a *ngSwitchCase="'review-new-reply'" [routerLink]="'/experience-share/member/info/' + item.model_id" (click)="viewNotification(item.id)">
            <div class="header">
                <h5 class="title" *ngIf="item.user_id == item.author_id; else noAuthor1">您分享的「療程案例文章」有新的留言</h5>
                <h5 class="title" #noAuthor1>您回覆的「療程案例文章」有新的留言</h5>
            </div>
            <div class="body">
                <p class="content">文章主題：{{ item.title }} </p>
                <p class="content">{{ item.created_at }}</p>
            </div>
        </a>
        <a *ngSwitchCase="'issue-new-reply'" [routerLink]="'/experience-share/forum/info/' + item.model_id" (click)="viewNotification(item.id)">
            <div class="header">
                <h5 class="title" *ngIf="item.user_id == item.author_id; else noAuthor2">您分享的「討論文章」有新的留言</h5>
                <h5 class="title" #noAuthor2>您回覆的「討論文章」有新的留言</h5>
            </div>
            <div class="body">
                <p class="content">文章主題：{{ item.title }} </p>
                <p class="content">{{ item.created_at }}</p>
            </div>
        </a>
        <a *ngSwitchCase="'inbox-notify'" [routerLink]="'/member/mail/' + item.model_id" (click)="viewNotification(item.id)">
            <div class="header">
                <h5 class="title">您有一則新的「站內信」</h5>
            </div>
            <div class="body">
                <p class="content">{{ item.title }} </p>
                <p class="content">{{ item.created_at }}</p>
            </div>
        </a>
        <a *ngSwitchCase="'schedule-notify'" [routerLink]="'/member/doctor-calendar/calendar/' + item.model_id" (click)="viewNotification(item.id)">
            <div class="header">
                <h5 class="title">您有一則新的「行事曆」</h5>
            </div>
            <div class="body">
                <p class="content">文章主題：{{ item.title }} </p>
                <p class="content">{{ item.created_at }}</p>
            </div>
        </a>
    </ng-container>
</div>

<!-- 刪除確認alert -->
<swal #deleteSwal title="確認要刪除此資料??" icon="question" [showCancelButton]="true" [focusCancel]="true"></swal>
