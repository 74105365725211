import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { environment } from '../../../environments/environment';
import { Webconfig } from './../../models/webconfig.model';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'app-favorite-lite',
    templateUrl: './favorite-lite.component.html',
    styleUrls: ['./favorite-lite.component.scss']
})
export class FavoriteLiteComponent implements OnInit {

    @Input() isFavorite;
    @Input() id;
    @Input() type;
    @Input() text = ''
    @Input() iconSize: string = '15px';

    @Output() favoriteChange = new EventEmitter<any>(); //提供Output裝飾器，讓該事件成為父親模板的事件
    t_webconfig = Webconfig;

    constructor(
        private httpService: HttpService,
        public authService: AuthService
    ) { }

    ngOnInit(): void {
    }

    //會員加入收藏
    addFavorite(event) {
        event.stopPropagation();
        event.preventDefault();

        let action = '1';
        if (this.isFavorite) {
            action = '0';
        }

        let params = new FormData();
        params.append('type', this.type);
        params.append('_method', 'PUT');
        params.append('action', action);

        let url = `${environment.APIUrl}/api/member-center/favorites/${this.id}`;
        this.httpService.postHttp(url, params).subscribe(
            res => {
                this.isFavorite = res.is_favorite;
                this.favoriteChange.emit(this.isFavorite);
            }
        )
    }

}
