import { Pipe } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
    name: "webp"
})
export class WebpPipe {

    transform(path: string, width: number = 0, height: number = 0) {
        return `${environment.ImageUrl}${path}.${width}.${height}.webp`;
    }

}
