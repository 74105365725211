import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpService } from "./http.service";

@Injectable({
    providedIn: "root"
})
export class HomeDataService {

    banners = []; //Banner
    hotTherapies = []; //網友熱搜療程
    recommend = []; //不能錯過
    doctors = []; //駐站醫師
    articles = []; //好文推薦
    hots = []; //熱門討論
    todos = []; //待解決問題

    setData(data) {
        this.banners = data.banners || [];
        this.hotTherapies = data.hotTherapies || [];
        this.recommend = data.recommend || [];
        this.doctors = data.doctors || [];
        this.articles = data.articles || [];
        this.hots = data.hots || [];
        this.todos = data.todos || [];
    }

    homeData

    loadHomeDataPromise

    constructor(
        private httpService: HttpService,
    ) { }

    //取得首頁資訊
    getHomeData() {
        if (this.loadHomeDataPromise) return this.loadHomeDataPromise;

        let url = `${environment.APIUrl}/api/home-data`;

        this.loadHomeDataPromise = new Promise((resolve, reject) => {
            this.httpService.getHttp(url).subscribe(
                res => {
                    this.homeData = res;
                    resolve(res);
                }
            )
        })

        return this.loadHomeDataPromise;
    }

}
