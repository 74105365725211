import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

type MenuModel = { id: number, name: string }[]

@Component({
    selector: 'app-filter-menu-tab',
    templateUrl: './filter-menu-tab.component.html',
    styleUrls: ['./filter-menu-tab.component.scss']
})
export class FilterMenuTabComponent implements OnInit {

    constructor() { }

    @Input() curActiveId;

    @Input() menuModel: MenuModel = []

    @Output() change = new EventEmitter();

    onSelectChange(id) {
        this.curActiveId = id
        this.change.emit(this.curActiveId)
    }

    ngOnInit(): void {
    }

}
