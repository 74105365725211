export const Therapy: any = [
  {
    id: 1, name: "手術", parent_id: null,
    children: [
      { id: 7, name: "眉眼", parent_id: 1 },
      { id: 8, name: "鼻子", parent_id: 1 },
      { id: 9, name: "嘴", parent_id: 1 },
      { id: 10, name: "下巴", parent_id: 1 },
      { id: 11, name: "頸部", parent_id: 1 },
      { id: 12, name: "臉", parent_id: 1 },
      { id: 13, name: "毛髮", parent_id: 1 },
      { id: 14, name: "胸", parent_id: 1 },
      { id: 15, name: "腰腹部", parent_id: 1 },
      { id: 16, name: "臀", parent_id: 1 },
      { id: 17, name: "腿", parent_id: 1 },
      { id: 18, name: "手", parent_id: 1 },
      { id: 19, name: "背部", parent_id: 1 },
      { id: 20, name: "私密處", parent_id: 1 },
      { id: 21, name: "其他", parent_id: 1 }
    ]
  },
  {
    id: 3, name: "微整形針劑、埋線", parent_id: null,
    children:
      [
        {
          "id": 41,
          "name": "肉毒桿菌",
          "parent_id": 3
        },
        {
          "id": 42,
          "name": "玻尿酸",
          "parent_id": 3
        },
        {
          "id": 43,
          "name": "膠原蛋白增生",
          "parent_id": 3
        },
        {
          "id": 68,
          "name": "埋線拉提",
          "parent_id": 3
        },
        {
          "id": 77,
          "name": "消脂、減重",
          "parent_id": 3
        },
        {
          "id": 78,
          "name": "其他",
          "parent_id": 3
        }
      ]
  },
  {
    id: 2, name: "雷射光療、電音波", parent_id: null,
    children:
      [
        {
          "id": 39,
          "name": "皮秒雷射",
          "parent_id": 2
        },
        {
          "id": 35,
          "name": "電波拉皮",
          "parent_id": 2
        },
        {
          "id": 36,
          "name": "音波拉皮",
          "parent_id": 2
        },
        {
          "id": 38,
          "name": "脈衝光",
          "parent_id": 2
        },
        {
          "id": 71,
          "name": "淨膚雷射",
          "parent_id": 2
        },
        {
          "id": 72,
          "name": "染料/血管雷射",
          "parent_id": 2
        },
        {
          "id": 73,
          "name": "飛梭雷射",
          "parent_id": 2
        },
        {
          "id": 81,
          "name": "除毛雷射",
          "parent_id": 2
        },
        {
          "id": 82,
          "name": "私密處雷射",
          "parent_id": 2
        },
        {
          "id": 83,
          "name": "紅寶石雷射",
          "parent_id": 2
        },
        {
          "id": 40,
          "name": "其他儀器療程",
          "parent_id": 2
        }
      ]
  },
  {
    id: 4, name: "非侵入式體雕", parent_id: null,
    children: [
      {
        "id": 46,
        "name": "冷凍溶脂",
        "parent_id": 4
      },
      {
        "id": 47,
        "name": "超音波溶脂",
        "parent_id": 4
      },
      {
        "id": 48,
        "name": "電波溶脂",
        "parent_id": 4
      },
      {
        "id": 49,
        "name": "雷射溶脂",
        "parent_id": 4
      },
      {
        "id": 50,
        "name": "電磁波增肌減脂",
        "parent_id": 4
      },
      {
        "id": 79,
        "name": "其他非侵入式體雕",
        "parent_id": 4
      }
    ]
  },
  {
    id: 5, name: "清潔美容保養", parent_id: null,
    children:
      [
        {
          "id": 51,
          "name": "臉部保養",
          "parent_id": 5
        },
        {
          "id": 52,
          "name": "身體保養",
          "parent_id": 5
        }
      ]
  },
  {
    id: 6, name: "其他 (植髮、紋繡...)", parent_id: null,
    children: [
      {
        "id": 80,
        "name": "照相系統",
        "parent_id": 6
      },
      {
        "id": 76,
        "name": "植髮",
        "parent_id": 6
      },
      {
        "id": 53,
        "name": "紋繡",
        "parent_id": 6
      },
      {
        "id": 54,
        "name": "美甲",
        "parent_id": 6
      },
      {
        "id": 55,
        "name": "美睫",
        "parent_id": 6
      },
      {
        "id": 56,
        "name": "美齒",
        "parent_id": 6
      }
    ]
  },


];