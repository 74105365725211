import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { environment } from '../../environments/environment';
import { Meta, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class MetaService {

    home;

    constructor(
        private httpService: HttpService,
        private meta: Meta,
        private title: Title,
        private router: Router,
    ) { }

    init(route) {
        const data = route.data?.title;

        if (data?.code) {
            this.setByUrlCode(typeof data.code === 'function' ? data.code(route) : data.code);
        } else {
            this.title.setTitle('醫美圈圈 | 台灣最大整形醫美討論版');
        }
    }

    set(name: string, description: string, keywords: string, image: string) {
        image = image ? `${environment.FileUrl}${image}` : '';

        // HTML Meta Tags
        this.title.setTitle(name);
        this.meta.addTag({ name: 'description', content: description });
        this.meta.addTag({ name: 'keywords', content: keywords });

        // Google / Search Engine Tags
        this.meta.addTag({ itemprop: 'name', content: name });
        this.meta.addTag({ itemprop: 'description', content: description });
        this.meta.addTag({ itemprop: 'image', content: image });

        // Facebook Meta Tags
        this.meta.addTag({ property: 'og:type', content: 'website' });
        this.meta.addTag({ property: 'og:url', content: this.router.url });
        this.meta.addTag({ property: 'og:title', content: name });
        this.meta.addTag({ property: 'og:description', content: description });
        this.meta.addTag({ property: 'og:image', content: image });

        // Twitter Meta Tags
        this.meta.addTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.meta.addTag({ name: 'twitter:title', content: name });
        this.meta.addTag({ name: 'twitter:description', content: description });
        this.meta.addTag({ name: 'twitter:image', content: image });
    }

    setByPageData(page: any) {
        if (page) {
            this.set(page.meta_title, page.meta_description || page.meta_desc, page.meta_keyword, page.meta_image);
        }
    }

    setByUrlCode(code: String) {
        this.httpService.getHttp(`${environment.APIUrl}/api/page-data?url_code=${code}`).subscribe((res) => {
            if (code === 'home') {
                this.home = res.page;
            }

            this.setByPageData(res.page);
        });
    }

    setDefault() {
        if (this.home) {
            this.setByPageData(this.home);
        } else {
            this.setByUrlCode('home');
        }
    }

}
