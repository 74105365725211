import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { NbPopoverDirective } from '@nebular/theme';
import { LayoutService } from 'src/app/services/layout.service';

type MenuModel = { id: number, name: string }[]

@Component({
    selector: 'app-filter-menu',
    templateUrl: './filter-menu.component.html',
    styleUrls: ['./filter-menu.component.scss']
})
export class FilterMenuComponent implements OnInit {

    constructor(
        private layoutService: LayoutService
    ) { }

    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
    @ViewChild('items', { read: TemplateRef }) contentRef: TemplateRef<any>;

    @Input() menuModel: MenuModel = []

    @Input() curActiveId;

    @Input() showSortIconWhenNoId = true;

    @Output() change = new EventEmitter();

    getCurSelectLabel() {
        return this.menuModel.find(({ id }) => id == this.curActiveId)?.name || ''
    }

    curSelectLabel = ''

    onSelectChange(id) {
        this.curActiveId = id
        this.curSelectLabel = this.getCurSelectLabel()
        this.change.emit(this.curActiveId)
        this.popover?.hide()
    }

    ngOnInit(): void {
        this.curActiveId == this.curActiveId || this.menuModel[0].id
        this.curSelectLabel = this.getCurSelectLabel()
    }

    handleTriggerClick() {
        setTimeout(() => {
            if (this.popover.isShown && this.layoutService.smallThanMd) {
                document.querySelector('.cdk-overlay-pane')?.classList.add('filter-menu-mobile')
            }
        });
    }

}
