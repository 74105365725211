import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent {

    @Input() list;
    @Input() customClass;

    customOptions = {
        loop: true,
        lazyLoad: true,
        autoplay: true,
        items: 1
    }

}
