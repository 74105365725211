import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";

@Injectable({
    providedIn: "root"
})
export class SearchService {

    constructor(
        private httpService: HttpService
    ) { }

    isSearchFocus = false

    keyword = ''

    searchHistory = []

    /**
     * 取出本地搜索歷史
     */
    async getSearchHistory() {
        this.httpService.getApi('/api/content/search-word/list').subscribe(
            res => {
                this.searchHistory = res.map(item => item.name)
            }
        )
    }

    /**
     * 添加本地搜索歷史
     */
    setSearchHistory(keyword) {
        let searchHistory = this.searchHistory
        searchHistory = searchHistory.filter(item => item != keyword)
        searchHistory.unshift(keyword)
        const maxHisotryCount = 12
        if (searchHistory.length > maxHisotryCount) searchHistory = searchHistory.slice(0, maxHisotryCount)
        localStorage.setItem('searchHistory', JSON.stringify(searchHistory))
        this.getSearchHistory()
    }

    toggleSearchView(show = true) {
        if (!show) {
            setTimeout(() => {
                this.isSearchFocus = false
                document.body.style.overflow = 'auto';
            }, 200);
        } else {
            this.isSearchFocus = true
            document.body.style.overflow = 'hidden';
            this.getSearchHistory();
        }
    }

}
