import { TipstatusService } from './../../services/tipstatus.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-tip-btn',
    templateUrl: './tip-btn.component.html',
    styleUrls: ['./tip-btn.component.scss']
})
export class TipBtnComponent implements OnInit {

    @Input() cloumn;

    constructor(
        public tipstatusService: TipstatusService,
    ) { }

    ngOnInit(): void {
        this.tipstatusService.showStatus[this.cloumn] = false
    }

    toggleStatus() {
        this.tipstatusService.showStatus[this.cloumn] = !this.tipstatusService.showStatus[this.cloumn]
    }

}
