<span class="filter-trigger" [nbPopover]="tabs" nbButton nbPopoverPlacement="bottom" nbPopoverOffset="10" [nbPopoverAdjustment]="isMobile ? 'clockwise': 'clockwise'" nbPopoverTrigger="click" (click)="handleTriggerClick()">
    {{tabLabel}}
    <img src="/assets/images/icon/arrow_up.svg" alt="" srcset="" [ngClass]="{'arrow-down': !!(popover && popover.isShown)}">
</span>
<ng-template #tabs>
    <nb-card class="filter">
        <nb-tabset (changeTab)="handleChangeTab($event)">
            <nb-tab tabTitle="熱門療程" [tabId]="0">
                <ul *ngIf="homeDataService.hotTherapies" class="filter__list mt-3">
                    <a class="filter__item" *ngFor="let item of homeDataService.hotTherapies" [routerLink]="['/therapy/info', item.id]"> {{ item.name}}
                    </a>
                </ul>
            </nb-tab>
            <nb-tab tabTitle="從療程查找" [tabId]="1">
                <div>
                    <nb-accordion multi>
                        <nb-accordion-item *ngFor="let cate of allTherapipyCates">
                            <nb-accordion-item-header>
                                {{cate.name}}
                            </nb-accordion-item-header>
                            <nb-accordion-item-body>
                                <div>
                                    <ul class="filter__list">
                                        <a class="filter__item" *ngFor="let item of cate.children" [ngClass]="{'active': categoryId == item.id}" (click)="selectCategory(item.id)">
                                            {{ item.name}}
                                        </a>
                                    </ul>
                                </div>
                            </nb-accordion-item-body>
                        </nb-accordion-item>
                    </nb-accordion>
                </div>
            </nb-tab>
            <nb-tab tabTitle="從部位查找" [tabId]="2">
                <div>
                    <div>
                        <div class="filter__title">
                            <div>請選擇部位</div>
                            <div style="cursor: pointer;" (click)="selectAllOrgan()">全選</div>
                        </div>
                        <div class="organ-list">
                            <div *ngFor="let organ of allOrgans; index as i" class="organ-item" (click)="selectOrgan(organ.id)" [ngClass]="{'active': organId == organ.id}">
                                <span class="organ-icon">
                                    <ng-container *ngIf="!organ.noImage">
                                        <img src="/assets/images/organ_{{ organ.id }}.png" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="organ.noImage">
                                        {{ organ.name }}
                                    </ng-container>
                                </span>
                                <span class="organ-name"> {{ organ.name }} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </nb-tab>
        </nb-tabset>
    </nb-card>
</ng-template>
