<span class="filter-trigger" [nbPopover]="content" nbButton nbPopoverPlacement="bittom" nbPopoverOffset="10" [nbPopoverAdjustment]="isMobile ? 'clockwise': 'clockwise'" nbPopoverTrigger="click" (click)="handleTriggerClick()">
    {{tabLabel}}
    <img src="/assets/images/icon/arrow_up.svg" alt="" srcset="" [ngClass]="{'arrow-down': !!(popover && popover.isShown)}">
</span>
<ng-template #content>
    <div class="filter__header">
        <div class="header__item" (click)="clearFilter()">清除設定</div>
        <div class="header__item" (click)="submit()">送出</div>
    </div>
    <nb-card class="filter">
        <div class="filter-speciality">
            <div class="filter__title">
                <div>醫師專科類別</div>
                <div class="filter__select-all" [ngClass]="{'active': curSelectedSpeciality == 'all'}" (click)="selectSpeciality('all')">全選</div>
            </div>
            <ul class="filter__list">
                <a class="filter__item" *ngFor="let item of allSpecialities" [ngClass]="{'active': curSelectedSpeciality == item.id || curSelectedSpeciality == 'all'}" (click)="selectSpeciality(item.id)">
                    {{ item.name}}
                </a>
            </ul>
        </div>
        <div class="filter-gender">
            <div class="filter__title">
                <div>性別</div>
                <div class="filter__select-all" (click)="selectGender('all')" [ngClass]="{'active':  curSelectedGender == 'all'}">全選</div>
            </div>
            <ul class="filter__list">
                <a class="filter__item" *ngFor="let item of allGenders" [ngClass]="{'active': curSelectedGender == item.id || curSelectedGender == 'all'}" (click)="selectGender(item.id)">
                    {{ item.name}}
                </a>
            </ul>
        </div>
        <nb-tabset (changeTab)="handleChangeTab($event)">
            <nb-tab tabTitle="醫師擅長療程" [tabId]="0">
                <div>
                    <nb-accordion multi>
                        <nb-accordion-item *ngFor="let cate of allTherapipyCates">
                            <nb-accordion-item-header>
                                {{cate.name}}
                            </nb-accordion-item-header>
                            <nb-accordion-item-body>
                                <div>
                                    <ul class="filter__list">
                                        <a class="filter__item" *ngFor="let item of cate.children" [ngClass]="{'active': curSelctedTherapy == item.id}" (click)="selectTherapy(item.id)">
                                            {{ item.name}}
                                        </a>
                                    </ul>
                                </div>
                            </nb-accordion-item-body>
                        </nb-accordion-item>
                    </nb-accordion>
                </div>
            </nb-tab>
            <nb-tab tabTitle="醫師擅長解決問題" [tabId]="1">
                <div>
                    <div>
                        <div class="filter__title">
                            <div>請選擇部位</div>
                        </div>
                        <div class="organ-list">
                            <div *ngFor="let organ of allOrgans; index as i" class="organ-item" (click)="selectOrgan(organ.id)" [ngClass]="{'active': curSelectedOrgan == organ.id}">
                                <span class="organ-icon">
                                    <ng-container *ngIf="!organ.noImage">
                                        <img src="/assets/images/organ_{{organ.id}}.png" alt="">
                                    </ng-container>
                                    <ng-container *ngIf="organ.noImage">
                                        {{ organ.name }}
                                    </ng-container>
                                </span>
                                <span class="organ-name"> {{ organ.name }} </span>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="filter__title">請選擇該部位問題</div>
                        <div>
                            <ul *ngIf="organProblems" class="filter__list">
                                <li class="filter__item" *ngFor="let item of organProblems" (click)="selectOrganProblem(item.id)" [ngClass]="{'active': curSelectedProblem == item.id}">
                                    {{ item.name}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nb-tab>
        </nb-tabset>
    </nb-card>
</ng-template>
