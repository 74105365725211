<span class="filter-trigger" [nbPopover]="items" nbButton nbPopoverPlacement="bottom" nbPopoverTrigger="click" nbPopoverOffset="10" (click)="handleTriggerClick()">
    <ng-container *ngIf="!curActiveId && showSortIconWhenNoId">
        <img src="/assets/images/icon/filter.svg" alt="" srcset="">
    </ng-container>
    <ng-container *ngIf="curActiveId || !showSortIconWhenNoId">
        {{ curSelectLabel }}
        <img src="/assets/images/icon/arrow_up.svg" alt="" srcset="" [ngClass]="{'arrow-down': !!(popover && popover.isShown)}">
    </ng-container>
</span>
<ng-template #items>
    <ul class="filter">
        <li class="filter__item" *ngFor="let item of menuModel; let i = index">
            <a class="filter__link" (click)="onSelectChange(item.id)" [class.active]="curActiveId === item.id">
                <span> {{ item.name }} </span>
                <img src="/assets/images/icon/checked.svg" class="active-check" alt="">
            </a>
        </li>
    </ul>
</ng-template>
