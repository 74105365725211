import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpService } from '../../services/http.service';
import { FileService } from '../../services/file.service';
import { LayoutService } from '../../services/layout.service';
import { isPlatformBrowser } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HomeDataService } from 'src/app/services/home-data.service';

@Component({
    selector: 'app-home-content',
    templateUrl: './home-content.component.html',
    styleUrls: ['./home-content.component.scss']
})
export class HomeContentComponent implements OnInit {

    isBrowser;

    defaultBannerOptions = {
        loop: true,
        autoplay: true,
        nav: true,
        navText: ['<img src="/assets/images/icon/chevron-left.svg">', '<img src="/assets/images/icon/chevron-right.svg">'],
    };

    therapyBannerOptions = {
        loop: true,
        autoplay: true,
        responsive: {
            0: {
                items: 1
            }
        },
    }

    doctorsBannerOptions = {
        ...this.defaultBannerOptions,
        center: !this.layoutService.smallThanMd,
        responsive: {
            0: {
                items: 2,
            },
            768: {
                items: 3.5
            }
        },
    }

    data;

    articleBannerOptions = {}

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private httpService: HttpService,
        public homeDataService: HomeDataService,
        public sanitizer: DomSanitizer,
        public fileService: FileService,
        public layoutService: LayoutService,
    ) {
        this.articleBannerOptions = {
            ...this.defaultBannerOptions,
            responsive: {
                0: { items: 1, },
                748: { items: 2 },
                950: { items: 3 },
            },
        }
    }

    ngOnInit(): void {
        this.getData();
        this.getDoctorList();
        this.getArticleList();

        if (isPlatformBrowser(this.platformId)) {
            this.isBrowser = true;
        }
    }

    //取得首頁資訊
    getData() {
        this.homeDataService.getHomeData().then(
            res => {
                this.data = res;
                this.homeDataService.hotTherapies = res.therapy.items || []
            }
        )
    }

    doctors$: Observable<Array<Object>>

    doctors = []

    //列表清單
    getDoctorList() {
        const url = `${environment.APIUrl}/api/content/doctors/stationed/list`;
        this.doctors$ = this.httpService.getHttp(url).pipe(map(doc => {
            return doc.doctor.items;
        }))
        this.doctors$.subscribe(
            res => {
                this.doctors = res
            }
        )
    }

    articles$: Observable<Array<Object>>

    articles = []

    getArticleList() {
        const url = `${environment.APIUrl}/api/content/articles/hot/list`;
        this.articles$ = this.httpService.getHttp(url).pipe(map(doc => {
            return doc.article.items;
        }))
        this.articles$.subscribe(
            res => {
                this.articles = res
            }
        )
    }

}
