<div class="container" style="padding: 0px;">
    <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
            <ng-container *ngFor="let item of list; let recordIndex = index;let firstRecord = first;let lastRecord = last">
                <li class="breadcrumb-item">
                    <ng-container *ngIf="item.route; else last">
                        <ng-container *ngIf="item.title=='therapyCategory'">
                            <a (click)="layoutService.toggleSidemenu(0)">{{ breadcrumb[item.title] }}</a>
                        </ng-container>
                        <ng-container *ngIf="item.title=='problem'">
                            <a (click)="layoutService.toggleSidemenu(1)">{{ breadcrumb[item.title] }}</a>
                        </ng-container>
                        <ng-container *ngIf="item.title!='therapyCategory' && item.title!='problem'">
                            <a [routerLink]="[item.route]">
                                <span *ngIf="item.model">{{ breadcrumb[item.title] }}</span>
                                <span *ngIf="!item.model">{{ item.title }}</span>
                            </a>
                        </ng-container>
                    </ng-container>
                    <ng-template #last>
                        <ng-container *ngIf="item.title=='experienceShare'">
                            <a (click)="layoutService.toggleSidemenu(2)">{{ breadcrumb[item.title] }}</a>
                        </ng-container>
                        <ng-container *ngIf="item.title!='experienceShare'">
                            <span *ngIf="item.model">{{ breadcrumb[item.title] }}</span>
                            <span *ngIf="!item.model">{{ item.title }}</span>
                        </ng-container>
                    </ng-template>
                </li>
            </ng-container>
        </ol>
    </nav>
</div>
