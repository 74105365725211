import { Component, OnInit, ViewChild } from '@angular/core';
import { NbPopoverDirective } from '@nebular/theme'
import { Webconfig } from './../../models/webconfig.model';
import { AuthService } from 'src/app/services/auth.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Router, NavigationEnd } from '@angular/router'; //接收route para
import { filter } from 'rxjs/operators';
import { SearchService } from 'src/app/services/search.service';

@Component({
    selector: 'app-footer-bar',
    templateUrl: './footer-bar.component.html',
    styleUrls: ['./footer-bar.component.scss']
})
export class FooterBarComponent implements OnInit {

    haveUnreadCount$ = this.notificationsService.getHaveUnreadCount();
    t_webconfig = Webconfig;

    constructor(
        public authService: AuthService,
        private notificationsService: NotificationsService,
        public searchService: SearchService,
        private router: Router,
    ) { }

    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;

    isShow = true

    ngOnInit(): void {
        this.isShow = this.isShowHrefUrl(this.router.url)

        this.router.events.pipe(filter(event => event instanceof NavigationEnd))
            .subscribe((res: any) => {
                this.isShow = this.isShowHrefUrl(this.router.url)
            });
    }

    isShowHrefUrl = (url = '') => {
        if (url.includes('/x/') && window.innerWidth <= 1366) return false;
        return true
    }

    onPopoverItemClick() {
        this.popover?.hide()
    }

}
