import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { FormControl, FormBuilder } from '@angular/forms';
import { formatDate } from '@angular/common';
import { Router } from '@angular/router'; //接收route para
import { HttpService } from '../../services/http.service';
import { Specialty } from '../../models/specialty.model';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-filter',
    templateUrl: './filter.component.html',
    styleUrls: ['./filter.component.scss']
})
export class FilterComponent implements OnInit {

    @Input() filter_route; //送出form後 要傳送到的route [child class需定義]
    @Input() filter_columns; //動態產生formcontrol的陣列欄位 [child class需定義]
    @Input() filter_value; //formcontrol上的預設值 (paginator class會自動產生)
    @Input() filter_type; //用來篩選html要呈現哪個篩選器form [child class需定義]
    @Input() categories; //formcontrol類別欄位的列表 [child class需定義if有這個control]

    @Input() therapies = [];
    @Input() problems = [];

    @Input() doctors? = [];

    doctorsDivision = [];

    divisions = Specialty;

    Form = this.formBuilder.group({});

    constructor(
        private formBuilder: FormBuilder,
        private router: Router,
        private httpService: HttpService
    ) { }

    ngOnInit(): void {
        this.addFormControl();
        this.setFormValue();

        if (this.doctors.length > 0) {
            this.getDivisions();
        }

        if (this.filter_type == 'doctor') {
            this.getTherapies();
            this.getProblems();
        }
    }

    //醫師專科類別
    getDivisions() {
        this.doctors.map(doc => {
            if (doc?.division) {
                doc?.division.map(d => {
                    this.doctorsDivision.push(Number(d));
                })
            }
        });
        this.doctorsDivision = [...new Set(this.doctorsDivision)];
        this.divisions = this.divisions.filter(div => this.doctorsDivision.includes(div.id));
    }

    //療程清單
    getTherapies() {
        let url = `${environment.APIUrl}/api/content/therapies?per_page=999`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.therapies = res.therapy.items;
            }
        )
    }

    //問題清單
    getProblems() {
        let url = `${environment.APIUrl}/api/content/problems?per_page=999`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.problems = res.problem.items;
            }
        )
    }

    //新增formcontrol
    addFormControl() {
        if (this.filter_columns) {
            this.filter_columns.forEach(
                column => {
                    this.Form.addControl(column, new FormControl(''));
                }
            );
        }
    }

    //設定form value
    setFormValue() {
        if (this.filter_value && Object.keys(this.filter_value).length > 0) {
            Object.keys(this.filter_value).forEach(
                key => {
                    if (this.Form.get(key)) {
                        if (key == 'start_date' || key == 'end_date') {
                            this.Form.get(key).setValue(new Date(this.filter_value[key]))
                        } else {
                            this.Form.get(key).setValue(this.filter_value[key])
                        }
                    }
                }
            );
        } else {
            this.Form.reset();
        }
    }

    //如果input進來的參數改變, 重新取得
    ngOnChanges(changes: SimpleChanges) {
        if (changes.categories) {
            this.categories = changes.categories.currentValue;
        }
        if (changes.filter_value) {
            this.filter_value = changes.filter_value.currentValue;
            this.setFormValue();
        }
    }

    //送出篩選
    onSubmit() {
        Object.keys(this.Form.value).forEach((key) => (!this.Form.value[key]) && delete this.Form.value[key]);

        //時間格式轉為字串
        let date_string;
        if (this.Form.value.start_date) {
            date_string = formatDate(this.Form.get('start_date').value, 'yyyy-MM-dd', 'en-US');
            this.Form.get('start_date').setValue(date_string);
        }
        if (this.Form.value.end_date) {
            date_string = formatDate(this.Form.get('end_date').value, 'yyyy-MM-dd', 'en-US');
            this.Form.get('end_date').setValue(date_string);
        }

        Object.keys(this.Form.value).forEach((key) => (!this.Form.value[key]) && delete this.Form.value[key]);

        this.router.navigate([this.filter_route], { queryParams: { ...this.Form.value, submit: true } });
    }

}
