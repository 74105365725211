import { LabelTipComponent } from './label-tip/label-tip.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ShareRoutingModule } from './share-routing.module';

//form
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';

//sweetalert2
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';

//loading
import { NgxSpinnerModule } from "ngx-spinner";

// Import ngx-barcode module
import { NgxBarcodeModule } from 'ngx-barcode';

//textarea autosize
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';

//google recaptch
import { RecaptchaModule } from 'ng-recaptcha';

//angular material
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

//Nebular
import {
    NbThemeModule,
    NbLayoutModule,
    NbIconModule,
    NbCardModule,
    NbButtonModule,
    NbDatepickerModule,
    NbInputModule,
    NbRadioModule,
    NbSelectModule,
    NbTreeGridModule,
    NbDialogModule,
    NbTooltipModule,
    NbAccordionModule,
    NbListModule,
    NbTabsetModule,
    NbRouteTabsetModule,
    NbSidebarModule,
    NbCheckboxModule,
    NbPopoverModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { NbMomentDateModule } from '@nebular/moment';

import { FileLibModule } from '@fresh08man/file-lib';
import { environment } from '../../environments/environment';

//directive
import { FocusDirective } from '../directives/focus.directive';

//多語系
import { TranslateModule } from '@ngx-translate/core';

//slider
import { CarouselModule } from 'ngx-owl-carousel-o';

//component
import { FormErrorComponent } from './form-error/form-error.component';
import { FilterComponent } from './filter/filter.component';
import { LoadmoreComponent } from './loadmore/loadmore.component';
import { AddressComponent } from './address/address.component';
import { FavoriteComponent } from './favorite/favorite.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { BannerComponent } from './banner/banner.component';
import { PageComponent } from './page/page.component';
import { LayoutAdminListComponent } from './layout-admin-list/layout-admin-list.component';
import { TherapySelectComponent } from './therapy-select/therapy-select.component';
import { HashtagCreateComponent } from './hashtag-create/hashtag-create.component';
import { ListItemComponent } from './list-item/list-item.component';
import { HotTherapyComponent } from './hot-therapy/hot-therapy.component';
import { TipBtnComponent } from './tip-btn/tip-btn.component';
import { DiscussCardComponent } from './right-side-info/discuss-card/discuss-card.component';
import { HomeContentComponent } from './home-content/home-content.component';
import { FilterMenuComponent } from './filter-menu/filter-menu.component';
import { CaseListItemComponent } from './case-list-item/case-list-item.component';
import { FooterBarComponent } from './footer-bar/footer-bar.component';
import { IndexBannerComponent } from '../layout/index-banner/index-banner.component';
import { FilterMenuTherapyComponent } from './filter-menu-therapy/filter-menu-therapy.component';
import { FilterMenuExperienceComponent } from './filter-menu-experience/filter-menu-experience.component';
import { FilterMenuTabComponent } from './filter-menu-tab/filter-menu-tab.component';
import { FilterMenuDoctorComponent } from './filter-menu-doctor/filter-menu-doctor.component';
import { FavoriteLiteComponent } from './favorite-lite/favorite-lite.component';
import { RatingComponent } from './rating/rating.component';
import { FilterMenuClinicComponent } from './filter-menu-clinic/filter-menu-clinic.component';
import { ClinicListItemComponent } from './clinic-list-item/clinic-list-item.component';

//image lazy loading
import { LazyLoadImageModule } from 'ng-lazyload-image';

import { ChunkPipe } from './util/chunk-pipe';
import { SafeHtmlPipe } from './util/safe-html-pipe';
import { WebpPipe } from './util/webp-pipe';

@NgModule({
    declarations: [
        AddressComponent,
        FormErrorComponent,
        FocusDirective,
        FilterComponent,
        LoadmoreComponent,
        FavoriteComponent,
        BreadcrumbComponent,
        BannerComponent,
        IndexBannerComponent,
        PageComponent,
        LayoutAdminListComponent,
        TherapySelectComponent,
        HashtagCreateComponent,
        ListItemComponent,
        HotTherapyComponent,
        LabelTipComponent,
        TipBtnComponent,
        DiscussCardComponent,
        HomeContentComponent,
        FilterMenuComponent,
        CaseListItemComponent,
        FooterBarComponent,
        FilterMenuTherapyComponent,
        FilterMenuExperienceComponent,
        FilterMenuTabComponent,
        FilterMenuDoctorComponent,
        FavoriteLiteComponent,
        RatingComponent,
        FilterMenuClinicComponent,
        ClinicListItemComponent,
        ChunkPipe,
        SafeHtmlPipe,
        WebpPipe,
    ],
    imports: [
        RouterModule,
        ShareRoutingModule,
        CommonModule,
        NgxBarcodeModule,
        FormsModule,
        ReactiveFormsModule,
        SweetAlert2Module,
        NbPopoverModule,
        NbThemeModule,
        NbLayoutModule,
        NbEvaIconsModule,
        NbIconModule,
        NbCardModule,
        NbInputModule,
        NbButtonModule,
        NbDatepickerModule,
        NbInputModule,
        NbRadioModule,
        NbSelectModule,
        NbTreeGridModule,
        NbDialogModule.forChild(),
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatInputModule,
        MatFormFieldModule,
        NbTooltipModule,
        MatButtonModule,
        MatCheckboxModule,
        MatFormFieldModule,
        MatInputModule,
        MatDialogModule,
        MatAutocompleteModule,
        FileLibModule.forRoot(environment),
        NbMomentDateModule,
        NbAccordionModule,
        NbListModule,
        NbTabsetModule,
        NbRouteTabsetModule,
        MatRadioModule,
        NgxSpinnerModule,
        NbSidebarModule,
        NbCheckboxModule,
        RecaptchaModule,
        TranslateModule,
        CarouselModule,
        TextareaAutosizeModule,
        LazyLoadImageModule,
    ],
    exports: [
        RecaptchaModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SweetAlert2Module,
        NbThemeModule,
        NbLayoutModule,
        NbEvaIconsModule,
        NbIconModule,
        NbCardModule,
        NbInputModule,
        NbButtonModule,
        NbDatepickerModule,
        NbInputModule,
        NbRadioModule,
        NbSelectModule,
        NbTreeGridModule,
        NbDialogModule,
        FormErrorComponent,
        MatTableModule,
        MatSortModule,
        MatPaginatorModule,
        MatInputModule,
        MatFormFieldModule,
        NbTooltipModule,
        FileLibModule,
        FocusDirective,
        NbMomentDateModule,
        FilterComponent,
        NbAccordionModule,
        NbListModule,
        NbTabsetModule,
        NbRouteTabsetModule,
        MatRadioModule,
        LoadmoreComponent,
        AddressComponent,
        NgxSpinnerModule,
        NbSidebarModule,
        FavoriteComponent,
        BreadcrumbComponent,
        NbCheckboxModule,
        BannerComponent,
        IndexBannerComponent,
        CarouselModule,
        NgxBarcodeModule,
        TextareaAutosizeModule,
        LayoutAdminListComponent,
        TherapySelectComponent,
        HashtagCreateComponent,
        ListItemComponent,
        HotTherapyComponent,
        LabelTipComponent,
        TipBtnComponent,
        HomeContentComponent,
        FilterMenuComponent,
        CaseListItemComponent,
        FooterBarComponent,
        FilterMenuTherapyComponent,
        FilterMenuExperienceComponent,
        FilterMenuTabComponent,
        FilterMenuDoctorComponent,
        FavoriteLiteComponent,
        RatingComponent,
        FilterMenuClinicComponent,
        ClinicListItemComponent,
        DiscussCardComponent,
        LazyLoadImageModule,
        ChunkPipe,
        SafeHtmlPipe,
        WebpPipe,
    ],
    providers: [
        //mat radio color
        {
            provide: MAT_RADIO_DEFAULT_OPTIONS,
            useValue: { color: 'primary' },
        }
    ]
})
export class ShareModule { }
