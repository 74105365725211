import { Component, Input } from '@angular/core';
import { Problem } from '../../../models/problem.model';

@Component({
    selector: 'app-discuss-card',
    templateUrl: './discuss-card.component.html',
    styleUrls: ['./discuss-card.component.scss']
})
export class DiscussCardComponent {

    @Input() discuss: any;

    getProblemName(id) {
        return Problem.find(item => item.id == id)?.name;
    }

}
