import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap, finalize } from 'rxjs/operators';
import { environment } from '../../environments/environment';
//import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from "ngx-spinner";
import swal from 'sweetalert2';
import { Router } from '@angular/router'; //接收route para
import { HttpService } from '../services/http.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorNotifierInterceptor implements HttpInterceptor {

    constructor(
        private router: Router,
        //private cookieService:CookieService,
        //private translateService: TranslateService,
        private spinner: NgxSpinnerService,
        public httpService: HttpService,
    ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): any {
        return next.handle(request).pipe(
            tap(
                event => { },
                error => {
                    if (error instanceof HttpErrorResponse) {
                        //1. 非系統API回傳格式 不處理 log 錯誤訊息
                        let reg = RegExp(environment.APIUrlReg);
                        if (!reg.test(error.url)) {
                            this.spinner.hide();
                            this.spinner.hide('loadmore');
                            this.httpService.requestCount = 0;
                            return Observable.create(observer => observer.error(error));
                        }

                        //2. 系統API錯誤處理
                        let err_info = error.error;

                        switch (error.status) {
                            //權限問題 token (token過期不在此處理)
                            case 419:
                                if (err_info.err_code != 'TOKEN_EXPIRE') {
                                    //沒登入權限 需跳出警示窗
                                    if (err_info.err_code == 'NO_LOGIN_PERMISSION' || err_info.err_code == 'NOT_YET_BE_CONFIRMED') {
                                        //let msg = this.translateService.instant('error.code.'+err_info.err_code);
                                        let msg = err_info.err_code
                                        swal.fire({
                                            title: 'Error!',
                                            text: msg,
                                            icon: 'error',
                                            confirmButtonText: 'YES'
                                        }).then((success) => {
                                            localStorage.removeItem('token');
                                            //this.cookieService.deleteCookie('token');
                                            this.router.navigate(['/auth/admin/login']);
                                            //this.openDialog('/');
                                        });

                                        //token 失效 直接到登入頁
                                    } else {
                                        this.router.navigate(['/auth/admin/login']);
                                        //this.router.navigate(['/login']);
                                        //this.openDialog('/');
                                    }
                                }
                                break;

                            case 422: //欄位有誤
                            case 403: //操作錯誤
                            case 500: //其他問題
                                //let msg = this.translateService.instant('error.code.'+err_info.err_code);
                                //let msg = err_info.err_msg
                                swal.fire({
                                    title: `[ERROR ${error.status}] ${err_info.err_msg}`,
                                    text: err_info.err_code,
                                    icon: 'error',
                                    confirmButtonText: 'YES'
                                });
                                break;

                            case 404:
                                this.router.navigate(['/error404']);
                                break;

                            case 200:
                                break;

                        }//end of switch

                        // 以错误的形式结束本次请求
                        //this.spinner.hide();
                        return Observable.create(observer => observer.error(error));
                    }//end of if
                }//end of error
            ),
            finalize(() => {
                this.httpService.requestCount--;

                if (this.httpService.requestCount <= 0) {
                    this.spinner.hide();
                    this.httpService.requestCount = 0;
                }
                //this.spinner.hide();
            })
        );//end of tap & pipe
    }

}
