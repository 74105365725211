<ng-content select="[list-breadcrumb]">
</ng-content>
<nb-card>
    <nb-card-header>
        <div class="row justify-content-between">
            <div class="col-auto"><ng-content select="[list-title]"></ng-content></div>
            <div class="col-auto"><ng-content select="[list-action]"></ng-content></div>
        </div>
    </nb-card-header>
    <nb-card-body>
        <ng-content select="[list-multiFilter]"></ng-content>
        <div class="form-inline handle">
            <ng-content select="[list-handle]"></ng-content>
            <ng-content select="[list-filter]"></ng-content>
        </div>
        <nb-card class="card-table mb-0">
            <nb-card-body>
                <ng-content select="[list-content]"></ng-content>
            </nb-card-body>
        </nb-card>
        <ng-content select="[list-paginator]"></ng-content>
    </nb-card-body>
</nb-card>
