<div class="rating">
    <div class="rating__imgs">
        <ng-container *ngIf="starColor == 'brown'">
            <img src="/assets/images/icon/star_fill.svg" alt="" srcset="" *ngFor="let item of numberToArray(fullStarCount)">
            <img src="/assets/images/icon/star_half_fill.svg" alt="" srcset="" *ngFor="let item of numberToArray(halfStarCount)">
            <img src="/assets/images/icon/star_not_fill.svg" alt="" srcset="" *ngFor="let item of numberToArray(emptyStarCount)">
        </ng-container>
        <ng-container *ngIf="starColor == 'yellow'">
            <img src="/assets/images/icon/star_fill_yellow.svg" alt="" srcset="" *ngFor="let item of numberToArray(fullStarCount)">
            <img src="/assets/images/icon/star_fill_yellow.svg" alt="" srcset="" *ngFor="let item of numberToArray(halfStarCount)">
            <img src="/assets/images/icon/star_not_fill.svg" alt="" srcset="" *ngFor="let item of numberToArray(emptyStarCount)">
        </ng-container>
    </div>
    <span class="rating_socre" *ngIf="showScore">
        ({{score}})
    </span>
</div>
