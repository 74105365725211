<a class="case-item" [routerLink]="['/experience-share/member/info', data.id]">
    <div class="case-item__names">
        <div *ngFor="let item of data.therapys" class="case-item__name">{{ item.name }}</div>
    </div>
    <div class="case-item__img">
        <img alt="" [src]="data.before_photo | webp:260" />
    </div>
    <div class="case-item__img">
        <img alt="" [src]="data.main_after_photo | webp:260" />
    </div>
    <div class="case-item__title">{{ data.title }}</div>
    <app-rating starColor="yellow" [ratingRatio]="data.worth * 100"></app-rating>
    <div class="case-item__footer">
        <div class="case-item__profile">
            <div class="user_avatar">
                <img alt="" [lazyLoad]="data.user.avatar | webp:50" *ngIf="data.user.avatar" />
            </div>
            <div class="user_info">
                <div class="user_name">{{ data.user.name }}</div>
                <span class="user_role">
                    <ng-container *ngIf="data.user.sub_role == '認證醫師'">
                        <nb-icon style="color:#56A9DD;height:14px;width:14px;margin-bottom:-2px" icon="checkmark-circle-2" class="me-1"></nb-icon>
                    </ng-container>
                    {{ data.user.sub_role }}
                </span>
            </div>
        </div>
        <div class="case-item__stat">
            <div class="stat__item"><img class="icon" src="/assets/images/icon/eye.svg" alt="" />{{ data.viewed }}</div>
            <div class="stat__item"><img class="icon" src="/assets/images/icon/comment.svg" alt="" style="margin-bottom:-4px" />{{ data.reply_count }}</div>
        </div>
    </div>
</a>
