import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NbPopoverDirective } from '@nebular/theme';
import { Problem } from 'src/app/models/problem.model';
import { Therapy } from 'src/app/models/therapy.model';
import { HttpService } from 'src/app/services/http.service';
import { environment } from 'src/environments/environment';
import { HomeDataService } from '../../services/home-data.service';
import { LayoutService } from 'src/app/services/layout.service';

@Component({
    selector: 'app-filter-menu-therapy',
    templateUrl: './filter-menu-therapy.component.html',
    styleUrls: ['./filter-menu-therapy.component.scss']
})
export class FilterMenuTherapyComponent implements OnInit {

    @ViewChild(NbPopoverDirective) popover: NbPopoverDirective;
    @Output() change = new EventEmitter();
    @Output() lastMenusChange = new EventEmitter();

    constructor(
        private httpService: HttpService,
        private router: Router,
        private route: ActivatedRoute,
        public homeDataService: HomeDataService,
        private layoutService: LayoutService,
    ) { }

    categoryId = ''

    allTherapipyCates = Therapy

    selectCategory(id) {
        this.categoryId = id
        this.handleChange({ categoryId: id })
    }

    allOrgans = Problem

    organId = null

    selectOrgan(val) {
        this.problemId = null
        this.organId = val

        if (val == 'all') {
            this.handleChange({ organId: val })
        } else {
            this.handleChange({ organId: val })
            // this.getProblems(val)
        }
    }

    selectAllOrgan() {
        this.selectOrgan('all')
    }

    problems = []

    hotList = []

    //熱搜療程
    getHotList() {
        let url = `${environment.APIUrl}/api/content/therapies?per_page=12&sort_field=viewed`;
        this.httpService.loadingOpen = false;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.hotList = [
                    ...res.therapy.items
                ]
                this.httpService.loadingOpen = true;
            }
        );
    }

    isMobile = false

    getIsMobile() {
        if (window.screen?.width < 992) return true
        return false
    }

    ngOnInit(): void {
        this.getCategories()

        this.route.queryParams.subscribe(params => {
            this.curTab = params.curTab || 0
        })

        this.route.queryParams.subscribe(params => {
            this.categoryId = params.category_id || 'hot'
            this.tabLabel = this.getLabel()

            this.lastMenusChange.emit(this.curTab != 2 ? this.getTherapyMenu(this.categoryId) : this.allOrgans)
        })
        this.getHotList()
        this.organId = this.allOrgans[0]
        this.isMobile = this.getIsMobile()
    }

    getCategories() {
        let url = `${environment.APIUrl}/api/content/categories`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.allTherapipyCates = res.items;
            }
        )
    }

    //問題清單
    getProblems(organId) {
        let url = `${environment.APIUrl}/api/content/problems?per_page=999&body_part=${organId}`;
        this.httpService.getHttp(url).subscribe(
            res => {
                this.problems = res.problem.items;
            }
        )
    }

    curTab = 0

    tabLabel = this.getLabel()

    getTherapyMenu(therapyId) {
        // 通过子节点id从allTherapipyCates中查找到当前选中的id的父级节点的所有子节点
        let target
        for (let cates of this.allTherapipyCates) {
            if (cates.id == therapyId) {
                target = cates.children
                break
            }

            cates.children.forEach(child => {
                if (child.id == therapyId) {
                    target = cates.children
                }
            })

            if (target) break
        }

        return target
    }

    handleChange({
        tabIndex = undefined,
        categoryId = undefined,
        organId = undefined,
        problemId = undefined,
        closePopover = true
    }) {
        if (tabIndex === undefined) tabIndex = this.curTab
        this.curTab = tabIndex

        let lastMenus = []

        if (categoryId) {
            this.categoryId = categoryId
            lastMenus = this.getTherapyMenu(categoryId)
        } else if (organId) {
            lastMenus = this.allOrgans
        } else if (problemId) {
            this.problemId = problemId
            lastMenus = this.problems
        }

        this.change.emit({
            tabIndex,
            categoryId,
            organId,
            problemId,
            // 最后一级菜单，给外层用
            lastMenus
        })

        const hasValue = categoryId || organId || problemId

        if (hasValue) this.tabLabel = this.getLabel()
        if (hasValue && closePopover) this.popover?.hide()

        const queryParams = { curTab: this.curTab } as any

        if (this.curTab == 0) return
        else if (categoryId) queryParams.category_id = categoryId
        else if (organId) queryParams.body_part = organId
        else if (problemId) queryParams.problem_id = problemId

        this.router.navigate(['/therapy'], { queryParams })
    }

    problemId = null

    selectProblem(id) {
        this.problemId = id
        this.handleChange({ problemId: id })
    }

    handleChangeTab(event) {
        this.curTab = event.tabId
    }

    getLabel() {
        if (this.curTab == 0) return '熱門療程'
        if (this.curTab == 1) return '從療程查找'
        if (this.curTab == 2) return '從部位查找'
    }

    handleTriggerClick() {
        setTimeout(() => {
            if (this.popover.isShown && this.layoutService.smallThanMd) {
                document.querySelector('.cdk-overlay-pane')?.classList.add('filter-menu-mobile')
            }
        });
    }

}
