import { Component, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { HttpService } from './services/http.service';
import { LayoutService } from './services/layout.service';
import { MetaService } from './services/meta.service';
import { isPlatformServer } from '@angular/common';
import { Router, NavigationEnd } from '@angular/router'; //接收route para
import { filter } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    isServer: boolean;

    constructor(
        @Inject(PLATFORM_ID) private platformId: Object,
        private renderer: Renderer2,
        private httpService: HttpService,
        private layoutService: LayoutService,
        private metaService: MetaService,
        private router: Router,
    ) {
        this.isServer = isPlatformServer(this.platformId);
    }

    ngOnInit() {
        if (!this.isServer) {
            //強制轉到到外部瀏覽器
            this.openExternalBrowser();
        }

        this.router.events.pipe(
            filter((event) => event instanceof NavigationEnd)
        ).subscribe(() => {
            let currentRoute = this.router.routerState.root.firstChild;

            while (currentRoute.firstChild) {
                currentRoute = currentRoute.firstChild;
            }

            this.layoutService.init(currentRoute.snapshot);
            this.metaService.init(currentRoute.snapshot);
        });
    }

    //關閉網頁初始loading動畫
    ngAfterViewInit(): void {
        if (!this.isServer) {
            let loader = this.renderer.selectRootElement('.app-loading');
            if (loader.style.display != "none") loader.style.display = "none"; //hide loader

            //全站瞄點function
            this.layoutService.scrollAnchorPosition();

            this.httpService.postHttp(`${environment.APIUrl}/api/init`, {}).subscribe(); //任意的 post 可以關閉 cache
        }
    }

    //強制轉到到外部瀏覽器
    openExternalBrowser() {
        var u = navigator.userAgent,
            ua = navigator.userAgent.toLowerCase(),
            isLineApp = u.indexOf("Line") > -1, // Line 內建瀏覽器
            isFbApp = u.indexOf("FBAN") > -1 // FB App 內建瀏覽器

        // 如果是 Line 內建瀏覽器
        if (isLineApp) {
            this.lineRedirect();
        }

        // 如果是 FB App 內建瀏覽器
        /*
        if (isFbApp) {
            this.fbRedirect(u);
        }*/
    }

    //line 瀏覽器轉導
    lineRedirect() {
        // 沒有包含參數就重新導向
        // 網址沒有參數 ? 時, 直接加參數
        if (location.href.indexOf("?") < 0) {
            location.href += "?openExternalBrowser=1";
        } else {
            // 網址有參數 ? 時, 用 & 加參數
            location.href += "&openExternalBrowser=1";
        }
    }

    //fb 瀏覽器轉導
    fbRedirect(u) {
        // 重新導向用chrome開啟網頁
        if (u.indexOf('iPhone')) {
            location.href = 'googlechrome://' + location.hostname + location.pathname + location.search;
        } else if (u.indexOf('Android')) {
            location.href = 'googlechrome://navigate?' + location.hostname + location.pathname + location.search;
        }
    }

}
