export const Webconfig: any = {
	loginHtml: `歡迎登入Worth it - 醫美圈圈<br>
	我們的使命是透過廣大網友的真實療程經驗分享，協助消費者少走冤枉路並選擇正確的療程<br>
	<div class="text-center mt-3">
		<a onclick="window.location='/auth/login/member'" href="javascript:;" class="btn btn-primary mb-1">點我登入</a><br>
		<a onclick="window.location='/auth/register?type=member'" href="javascript:;"  class="btn">我還不是會員，立即註冊</a>
	</div>`,
	loginHtmlWithRedirect() {
		let redirect = window.location.pathname + window.location.search;
		redirect = encodeURIComponent(redirect);

		return `歡迎登入Worth it - 醫美圈圈<br>
	我們的使命是透過廣大網友的真實療程經驗分享，協助消費者少走冤枉路並選擇正確的療程<br>
	<div class="text-center mt-3">
		<a onclick="window.location='/auth/login/member?url=${redirect}'" href="javascript:;" class="btn btn-primary mb-1">點我登入</a><br>
		<a onclick="window.location='/auth/register?type=member&url=${redirect}'" href="javascript:;"  class="btn">我還不是會員，立即註冊</a>
	</div>`
	},
};