import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-search-home-mobile',
    templateUrl: './search-home-mobile.component.html',
    styleUrls: ['./search-home-mobile.component.scss']
})
export class SearchHomeMobileComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}
