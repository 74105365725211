<form [formGroup]='Form' (ngSubmit)="onSubmit()" *ngIf="filter_columns">
    <ng-container *ngIf="filter_type=='doctor'">
        <div class="row g-3 g-md-4">
            <div class="col-md">
                <div class="row g-3 g-md-4 flex-nowrap overflow-auto">
                    <div class="col-12 col-md-6 col-lg-4 search-text-input">
                        <input type="text" nbInput fullWidth formControlName="keyword" placeholder="請輸入醫師姓名、療程名稱、或您想改善的問題">
                    </div>
                    <div class="col-6 col-md">
                        <nb-select fullWidth formControlName="division" placeholder="醫師專科類別">
                            <nb-option value="">醫師專科類別</nb-option>
                            <nb-option *ngFor="let division of divisions" value="{{ division.id }}">{{ division.name }}</nb-option>
                        </nb-select>
                    </div>
                    <div class="col-5 col-md">
                        <nb-select fullWidth formControlName="gender" placeholder="醫師性別">
                            <nb-option value="">醫師性別</nb-option>
                            <nb-option value="M">男</nb-option>
                            <nb-option value="F">女</nb-option>
                            <nb-option value="O">其他</nb-option>
                        </nb-select>
                    </div>
                    <div class="col-6 col-md">
                        <nb-select fullWidth formControlName="therapy_id" placeholder="醫師擅長療程">
                            <nb-option value="">醫師擅長療程</nb-option>
                            <nb-option *ngFor="let therapy of therapies" value="{{ therapy.id }}">{{ therapy.name }}</nb-option>
                        </nb-select>
                    </div>
                    <div class="col-7 col-md">
                        <nb-select fullWidth formControlName="problem_id" placeholder="醫師擅長解決問題">
                            <nb-option value="">醫師擅長解決問題</nb-option>
                            <nb-option *ngFor="let problem of problems" value="{{ problem.id }}">{{ problem.name }}</nb-option>
                        </nb-select>
                    </div>
                </div>
            </div>
            <div class="col-md-auto">
                <div class="d-grid">
                    <button type="submit" class="btn btn-outline-primary">查詢</button>
                </div>
            </div>
        </div>
    </ng-container>
</form>
